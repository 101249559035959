import { forwardRef, useContext, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import cn from 'classnames';
import { IntersectionObserverContext } from '../IntersectionObsereverProvider';

interface Props {
    className?: string;
    children?: React.ReactNode;
    onClick?: () => void;
    onMouseLeave?: () => void;
    onHover?: () => void;
}

type Ref = {
    scrollIntoView: () => void;
};

export const SecurityListRow = forwardRef<Ref, Props>(
    ({ children, className, onClick, onHover, onMouseLeave }, ref) => {
        const elementRef = useRef<HTMLDivElement>(null);
        const ioContext = useContext(IntersectionObserverContext);
        const id = useRef(ioContext ? uuid() : undefined);
        const [visible, setVisible] = useState(!ioContext);

        useLayoutEffect(() => {
            const element = elementRef.current;
            if (ioContext && element) {
                ioContext.observe(element, setVisible);
            }

            return () => {
                if (ioContext && element) {
                    ioContext.unobserve(element);
                }
            };
            // eslint-disable-next-line
        }, [elementRef.current]);

        useImperativeHandle(
            ref,
            () => ({
                scrollIntoView() {
                    elementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                },
            }),
            [],
        );

        return (
            <div
                id={id.current}
                ref={elementRef}
                className={cn('data-list-row flex-row', className)}
                onClick={onClick}
                onMouseLeave={onMouseLeave}
                onMouseEnter={onHover}
            >
                {!!visible && children}
            </div>
        );
    },
);
