import React, { useEffect, useState } from 'react';
import { DateTimeInput } from './DateTimeInput';
import { DateTimeValue } from '../../../../types/filters/DateTimeValue';
import { CustomDateRangePicker } from '../../date-range-custon-view/CustomDateRangePicker';
import { TimeOption } from '../../../../types/filters/TimeOption';
import { DateRange } from 'react-day-picker';
import { alignDayTime } from './utils';
import { errorMessages } from '../../../../constants';

interface DateTimeRangeSelectorProps {
    valueFrom?: DateTimeValue;
    valueTo?: DateTimeValue;
    minSelectYear?: number;
    onChange: (from?: DateTimeValue, to?: DateTimeValue) => void;
    onError: () => void;
}

export function DateTimeRangeSelector({
    valueFrom,
    valueTo,
    minSelectYear = 2010,
    onChange,
    onError,
}: DateTimeRangeSelectorProps) {
    const [internalFromValue, setInternalFromValue] = useState(valueFrom);
    const [internalToValue, setInternalToValue] = useState(valueTo);
    const [errorFrom, setErrorFrom] = useState('');

    useEffect(() => {
        setInternalFromValue(valueFrom);
        setInternalToValue(valueTo);
    }, [valueFrom, valueTo]);

    const handleTimeFromChange = (value: DateTimeValue) => {
        setInternalFromValue(value);
        if (internalToValue) {
            if (value.date > internalToValue.date) {
                setErrorFrom(errorMessages.fromDateBiggerThenToDate)
                onChange(alignDayTime(value), alignDayTime(internalToValue, true));
                return;
            }
            onChange(alignDayTime(value), alignDayTime(internalToValue, true));
            setErrorFrom('');
        }
    };

    const handleTimeToChange = (value: DateTimeValue) => {
        setInternalToValue(value);

        if (internalFromValue) {
            if (value.date < internalFromValue.date) {
                setErrorFrom(errorMessages.fromDateLaterThenToDate);
                onChange(alignDayTime(internalFromValue), alignDayTime(value, true));
                return;
            }
            onChange(alignDayTime(internalFromValue), alignDayTime(value, true));
            setErrorFrom('' );
        }
    };

    const handleDateChange = (range: DateRange) => {
        let newFromState = internalFromValue;
        let newToState = internalToValue;

        if (range.from) {
            newFromState = {
                date: range.from,
                timeOption: internalFromValue?.timeOption || TimeOption.AnyTime,
            };

            setInternalFromValue(newFromState);
        } else {
            newFromState = undefined;
        }

        if (range.to) {
            newToState = {
                date: range.to,
                timeOption: internalToValue?.timeOption || TimeOption.AnyTime,
            };

            setInternalToValue(newToState);
        } else {
            newToState = undefined;
        }

        onChange(alignDayTime(newFromState), alignDayTime(newToState, true));
        setErrorFrom('');
    };

    return (
        <div className="custom-view-item">
            <div className="custom-view-item-body">
                <div className="custom-view-wrap flex-row">
                    <DateTimeInput
                        label="From"
                        value={internalFromValue}
                        errorMessage={errorFrom}
                        minYear={minSelectYear}
                        onChange={handleTimeFromChange}
                        onError={onError}
                    />
                    <span className="separator-line">—</span>
                    <DateTimeInput
                        label="To"
                        value={internalToValue}
                        minYear={minSelectYear}
                        onChange={handleTimeToChange}
                        onError={onError}
                    />
                </div>
                <CustomDateRangePicker
                    from={internalFromValue?.date}
                    to={internalToValue?.date}
                    onDayClick={handleDateChange}
                />
            </div>
        </div>
    );
}
