import React from 'react';
import { nameUtils } from '../../utils/name.utils';
import IconSVG from '../../styles/svg-icons';
import { OnHoverTooltip } from '../common/OnHoverTooltip';
import { UserSlimWithRoles } from '../../types/account/UserSlimWithRoles';
import { roles } from '../../constants/roles';
import { ListBuilder } from '../../utils/ListBuilder';

interface Props {
    contacts: UserSlimWithRoles[];
    primaryId?: number;
}

export const ContactList = ({ contacts = [], primaryId }: Props) => {
    const sortedContacts = React.useMemo(() => {
        // hide arrangers client users
        const exceptArrangersClients = contacts.filter(c => c.roles?.length !== 1 || c.roles[0] !== roles.ArrangersClient)
        const primary = exceptArrangersClients.find(c => c.id === primaryId);
        const other = exceptArrangersClients
            .filter(c => c.id !== primaryId)
            .sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName));

        return new ListBuilder<UserSlimWithRoles>()
            .addWhen(() => primary != null, primary!)
            .add(...other)
            .result();
    }, [contacts, primaryId]);

    if (sortedContacts.length) {
        return (
            <ul className="contacts-cards">
                {sortedContacts.map(c => <MemberItem key={c.id} contact={c} isPrimary={c.id === primaryId} />)}
            </ul>
        );
    }
    return <p className="placeholder">No active users in this company</p>
}

interface MemberItemProps {
    contact: UserSlimWithRoles;
    isPrimary: boolean;
}

export function MemberItem({ contact, isPrimary }: MemberItemProps) {
    return (
        <li>
            <span className="name">{nameUtils.getFullName(contact.firstName, contact.lastName)}</span>
            <div className="flex-row">
                <span><a href={`mailto:${contact.email}`}>{contact.email}</a></span>
                {
                    isPrimary &&
                    <OnHoverTooltip wrapperClass="flex-item-right" overlay="Primary contact">
                        <IconSVG name="primary-contact" width={16} height={16} />
                    </OnHoverTooltip>
                }
            </div>
            <span>{contact.phone}</span>
        </li>
    );
}
