import React, { useMemo, useState } from 'react';

interface HoverInfoContent {
    name: string;
    value: string | number;
}

interface HoverInfoProps {
    children: React.ReactElement
}

export function HoverInfo({ children }: HoverInfoProps) {
    const [pointInfo, setPointInfo] = useState<HoverInfoContent[] | null>(null);
    const renderContent = () => {
        if (!Array.isArray(pointInfo)) {
            return null
        }
        return pointInfo.map(
            (item, index) => (
                <span className="graph-details-item" key={index}>
                    {item.name}: <span><strong>{item.value || '-'}</strong></span>
                </span>
            )
        )
    };
    const childrenElement = useMemo(() =>
            React.cloneElement(children, { onHover: setPointInfo }),
        [children]);

    return (
        <>
            <p className="graph-details">{renderContent()}</p>
            {childrenElement}
        </>
    )
}
