import { kebabCase, sumBy } from 'lodash';
import moment, { Moment } from 'moment';
import { PlotMouseEvent } from 'plotly.js';
import { Plot, mainConfig } from '../charts';
import { chartUtils } from '../../../utils';
import { arrayUtils } from '../../../utils/array.utils';
import { ChartView } from '../../../types/dashboard/AmrChart';
import { clientsActivityChart } from '../../../constants/amr-pipeline/clientsActivityChart';
import { constants } from '../../../constants';
import { DashboardNoSelectedFilters } from '../../dashboard/DashboardNoSelectedFilters';
import { amrChartUtils, GroupedBy, Range } from '../../../utils/amr-chart.utils';
import { AnalyticsFilterBase } from '../../../types/analytics/AnalyticsFilter';
import { SessionAccess } from '../../../types/amr-pipeline/models/AnalyticsSession';
import classNames from 'classnames';
import { AccessTypeMeta } from '../../../types/analytics/AccessTypeMeta';

type BarClickEventHandler<T extends string> = (accessType: T, date: Date) => void;

interface ClientsActivityChartProps<T extends string, K extends AnalyticsFilterBase<T>> {
    divId?: string;
    filter: K;
    data: SessionAccess<T>[];
    accessTypes: AccessTypeMeta<T>[],
    onBarClick: BarClickEventHandler<T>;
}

const ChartRangeFormat = 'YYYY-MM-DD';
const DefaultRangeDays = 10;

export function ClientsActivityChart<T extends string, K extends AnalyticsFilterBase<T>>({ divId, data, filter, accessTypes, onBarClick }: ClientsActivityChartProps<T, K>) {
    const chartEndMoment = moment();

    const {
        chartLayout,
        tickColor,
        tickFont,
        barColors,
        zeroLineColor,
        hoverlabel,
    } = clientsActivityChart;

    const handleBarClick = (e: PlotMouseEvent) => {
        const point = e && e.points && e.points[0];

        if (point && point.data) {
            const date = moment(point.x).toDate();
            const [accessType] = point.data.customdata;

            onBarClick(accessType as T, date);
        }
    };

    const getEdgeDimensionValues = (clientsActivity: SessionAccess<T>[]): Range<Moment> => {
        if (filter.date.dateFrom && filter.date.dateTo) {
            const min = moment(filter.date.dateFrom);
            const max = moment(filter.date.dateTo);

            // If filter is selected for one day, chart will render shitty
            // thin bars. Extend to two days to help it render correctly.
            if (moment.duration(max.diff(min)).days() < 2) {
                return {
                    min: min.subtract(1, 'day'),
                    max,
                }
            }

            return { min, max };
        }

        const minDateRow = arrayUtils.min(clientsActivity, x => x.accessDateTime)!;
        const tenDaysAgo = chartEndMoment.clone()
            .subtract(DefaultRangeDays, 'days')
            .startOf('day');

        const min = tenDaysAgo.isBefore(minDateRow?.accessDateTime) ? tenDaysAgo : moment.utc(minDateRow?.accessDateTime);

        return {
            min,
            max: chartEndMoment,
        };
    };

    const getChartRange = () => {
        // Pick either dates from filter, or current date as
        // reference. Manipulations with start/end of days and 12 hours
        // are to align ticks of chart and now show an edge of previous/next
        // days
        const fromMoment = filter.date.dateFrom
            ? moment(filter.date.dateFrom)
                .startOf('day')
                .subtract(12, 'hours')
            : chartEndMoment
                .clone()
                .subtract(DefaultRangeDays, 'days')
                .startOf('day')
                .add(12, 'hours');

        const toMoment = filter.date.dateTo
            ? moment(filter.date.dateTo)
                .endOf('day')
                .subtract(12, 'hours')
            : chartEndMoment
                .clone()
                .endOf('day')
                .subtract(12, 'hours');

        return [
            fromMoment.format(constants.dateTimeFormatUtc),
            toMoment.format(constants.dateTimeFormatUtc)
        ];
    };

    const getSliderRange = ({ min, max }: Range<Moment>) => {
        if (!min || !max) {
            return [];
        }

        // Set same range for slider as for chart,
        // only if date range is less or equal than 10 days
        return moment.duration(max.diff(min)).asDays() > DefaultRangeDays
            ? []
            : getChartRange();
    };

    const getHoverInfoText = (cloManagerClientsActivity: SessionAccess<T>[]) => {
        const [firstEntry] = cloManagerClientsActivity;

        if (!firstEntry) {
            return '';
        }

        const accessTypeTitle = accessTypes.find(t => t.value === firstEntry.accessType)?.text;

        return `${accessTypeTitle}: ${sumBy(cloManagerClientsActivity, x => x.numberOfAccess)} view(s)`;
    };

    const getChartData = ({ min, max } : Range<Moment>) => {
        const map = arrayUtils.groupBy(data, (row: SessionAccess<T>) => row.accessType);

        return accessTypes.map((accessType, index) => {
            const data: SessionAccess<T>[] = map.get(accessType.value) || [];

            const isSameDay = (row: SessionAccess<T>) => (iteratee: GroupedBy<Moment, SessionAccess<T>>) => iteratee.date.isSame(row.accessDateTime, 'day');

            const groupedByDate = data.reduce((acc: GroupedBy<Moment, SessionAccess<T>>[], row: SessionAccess<T>) => {
                const existingEntry = acc.find(isSameDay(row));

                if (existingEntry) {
                    return [
                        ...acc.filter(x => !isSameDay(row)(x)),
                        {
                            ...existingEntry,
                            entries: [
                                ...existingEntry.entries,
                                row,
                            ]
                        }
                    ];
                }

                return [
                    ...acc,
                    { date: moment(row.accessDateTime), entries: [row] }
                ];
            }, []);

            // Fill in gaps in dates with blank items, to preserve integrity of chart data
            const fillGaps = amrChartUtils.gapsFiller(groupedByDate, row => row.date, date => ({ date, entries: [] }));

            const dataWithFilledGaps = (min && max) ? fillGaps({ min, max }, 'day') : groupedByDate;

            return dataWithFilledGaps.reduce((acc: any, { date, entries }: { date: Moment, entries: SessionAccess<T>[] }) => {
                const { x, y, hovertext } = acc;

                return {
                    ...acc,
                    hovertext: [...hovertext, getHoverInfoText(entries)],
                    x: [...x, date.format(ChartRangeFormat)],
                    y: [...y, sumBy(entries, r => r.numberOfAccess)],
                };
            }, {
                x: [],
                y: [],
                hovertext: [],
                type: 'bar',
                hoverinfo: 'text',
                customdata: [accessType.value],
                marker: {
                    color: barColors[index],
                },
            });
        })
    };

    const getLayout = (dateRange: Range<Moment>) => {
        const xAxisRange = getChartRange();
        const rangeSliderRange = getSliderRange(dateRange);

        return {
            ...chartLayout,
            showlegend: false,
            autosize: true,
            hovermode: 'closest',
            hoverlabel,
            barmode: ChartView.Grouped,
            xaxis: {
                tickcolor: tickColor,
                type: 'date',
                tickfont: tickFont,
                tickformat: '%m/%d/%y',
                fixedrange: true,
                dtick: constants.dayMs,
                range: xAxisRange,
                rangeslider: {
                    range: rangeSliderRange,
                    bgcolor: 'rgba(79, 123, 156, 0.2)',
                },
            },
            yaxis: {
                showgrid: true,
                showtickprefix: 'all',
                fixedrange: true,
                gridcolor: tickColor,
                ticks: 'inside',
                tickcolor: tickColor,
                tickfont: tickFont,
                zerolinecolor: zeroLineColor,
            },
        }
    };

    const renderChart = () => {
        const edgeDimensions = getEdgeDimensionValues(data);
        const chartData = getChartData(edgeDimensions);
        const layout = getLayout(edgeDimensions);

        return (
            <>
                <Plot
                    onHover={chartUtils.setCursor('pointer')}
                    onUnhover={chartUtils.setCursor('crosshair')}
                    divId={divId}
                    data={chartData}
                    layout={layout}
                    config={mainConfig}
                    onClick={handleBarClick}
                />
                <div className="agenda">
                    {accessTypes.map(accessType => (
                        <div className={classNames('agenda-item', kebabCase(accessType.value))}>{accessType.text}</div>
                    ))}
                </div>
            </>
        );
    };

    return data.length ? renderChart() : <DashboardNoSelectedFilters />;
}

ClientsActivityChart.defaultProps = {
    divId: 'client-activity-div-id'
};
