import React, { useRef, PropsWithChildren } from 'react';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';
import { BlockedFeatureContext } from '../access/BlockedFeatureContext';

interface Props {
    className?: string;
    checked?: boolean;
    partially?: boolean;
    label?: React.ReactNode;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    name?: string;
}

export const Checkbox: React.FC<PropsWithChildren<Props>> = (
    {
        className,
        checked,
        partially,
        label,
        onChange,
        disabled,
        children,
        name
    }
) => {
    const id = useRef(uuid());

    const isFeatureBlocked = React.useContext(BlockedFeatureContext);

    return (
        <div
            className={cn(
                'checkbox', className, {
                'checkbox-indeterminate': partially && !isFeatureBlocked,
                'disabled': disabled || isFeatureBlocked
            })
            }
            onClick={(e) => e.stopPropagation()}
        >
            <input
                id={id.current}
                type="checkbox"
                checked={checked && !isFeatureBlocked}
                onChange={onChange}
                disabled={disabled || isFeatureBlocked}
                name={name}
            />
            <label htmlFor={id.current} className={cn('checkbox-lbl', { 'checkbox-lbl-text': label || children })}>
                {label || children}
            </label>
        </div>
    );
};

Checkbox.defaultProps = {
    onChange: () => { },
    checked: false,
    partially: false,
    disabled: false,
};
