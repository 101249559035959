import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import IconSVG from "../../../styles/svg-icons";

interface Props {
    onClick: () => void;
    exclude: boolean;
    editMode: boolean;
    disabled?: boolean;
}


export const ExcludeListItem: React.FC<PropsWithChildren<Props>> = ({ onClick, children, exclude, editMode, disabled}) => {
    const btnClassNames = {
        'btn btn-ghost btn-sm btn-arrow flex-item-right': true,
        'btn-arrow-mirror': exclude,
    }

    return (
        <li className="text-sm">
            <span>{children}</span>
            {
                editMode &&
                <button
                    className={classNames(btnClassNames)}
                    onClick={onClick}
                    disabled={disabled}
                >
                    <IconSVG name="icon-expand" width={16} height={16} />
                </button>
            }
        </li>
    )
};
