import Plotly from 'plotly.js/lib/core';
import Bar from 'plotly.js/lib/bar';
import Box from 'plotly.js/lib/box';
import Heatmap from 'plotly.js/lib/heatmap';
import Pie from 'plotly.js/lib/pie';
import createPlotlyComponent from 'react-plotly.js/factory';
import 'plotly.js/src/css/style.scss'

Plotly.register([
    Bar,
    Heatmap,
    Box,
    Pie,
]);

export const Plot = createPlotlyComponent(Plotly);
export const mainConfig = {
    responsive: true,
    displaylogo: false,
    doubleClickDelay: 1000,
    displayModeBar: false,
    showTips: false,
};
