import React, { useEffect, useState } from 'react'
import classNames from 'classnames';
import {
    CollapsibleListItemContent,
    CollapsibleList,
    CollapsibleListItem,
} from ".";
import { SecurityListRow } from "../../bidding/common";
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import IconSVG from '../../../styles/svg-icons';

interface Props<T> {
    expandAll: boolean;
    dataItem: T;
    columns: ColumnBuilder<T>[];
    context: { [t: string]: any };
    children?: React.ReactNode;
    className?: string;
    onExpandChange: () => void;
}

export function CollapsibleListRow<T>({ expandAll, dataItem, columns, context, className, children, onExpandChange }: Props<T>) {
    const [expanded, setExpanded] = useState(expandAll);
    useEffect(() => {
        setExpanded(expandAll);
    }, [expandAll]);

    const handleExpandedChange = () => {
        setExpanded(!expanded);

        if (onExpandChange) {
            onExpandChange();
        }
    }

    return (
            <CollapsibleList className={expanded ? "expand" : ""}>
                <CollapsibleListItem expanded={expanded}>
                    <SecurityListRow
                        className={classNames("component-collapsible-list-item-header", className)}
                        onClick={handleExpandedChange}
                    >
                        <div className="data-list-cell-collapse-action">
                            <span className="btn-link">
                                <IconSVG name="icon-expand" className={classNames({ 'collapse': expanded })} width={16} height={16} />
                            </span>
                        </div>
                        {columns.map((c) => c.renderBody(dataItem, context))}
                    </SecurityListRow>
                    <CollapsibleListItemContent expanded={expanded}>
                        {children}
                    </CollapsibleListItemContent>
                </CollapsibleListItem>
            </CollapsibleList>
    );
}
