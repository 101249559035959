import React from 'react';
import { Plot, mainConfig } from '../plotlyConfig';
import arraystat from 'arraystat';
import { chartUtils, numericUtils } from '../../../../utils';

const TraceType = {
    PxTalk: 'pxtalk',
    KTalk: 'ktalk',
};

export function Chart({
    pxTalks = [],
    evalPriceTalk = undefined,
    constants = {},
    divId = 'plotly-px-horizontal-box-chart',
    onHover,
    evalPriceTalkVisible = false,
}) {
    const {
        fillBoxColor,
        boxMarkerColor,
        chartHeight,
        margin,
        tickColor,
        tickFont,
        boxgap,
        hoverlabel,
        kTalkMarkerColor,
    } = constants;

    const getXRange = () => {
        const pxTalkNormilized = pxTalks.map(x => x.normalizedLevel);
        const values = evalPriceTalk ? [...pxTalkNormilized, evalPriceTalk] : pxTalkNormilized;

        let min = values[0] ? values[0] : 0;
        let max = 0.1;
        for (let i = 0; i < values.length; i++) {
            const normalizedLevel = values[i];
            if (normalizedLevel < min) {
                min = normalizedLevel
            }
            if (normalizedLevel > max) {
                max = normalizedLevel
            }
        }
        let difference = max - min;
        let dTick = 10;
        if (difference < 1) {
            dTick = 0.1
        } else if (difference >= 1 && difference < 10) {
            dTick = 1
        } else if (difference >= 10 && difference < 100) {
            dTick = 10
        } else if (difference > 100) {
            dTick = 20
        }
        const indent = (dTick / 100) * 20;
        min = Math.floor(min / dTick) * dTick;
        max = Math.ceil(max / dTick) * dTick;
        return { range: [min - indent, max + indent], dTick }
    };

    const chartData = [{
        x: pxTalks.map((px) => px.normalizedLevel),
        type: 'box',
        boxpoints: 'all',
        fillcolor: fillBoxColor,
        marker: {
            color: boxMarkerColor
        },
        hoverinfo: 'none',
        customdata: pxTalks.map((_, index) => ({
            type: TraceType.PxTalk,
            index,
        })),
        name: 'horizontal-box-chart',
        jitter: 1,
        quartilemethod: 'inclusive',
    }];

    if (evalPriceTalkVisible) {
        chartData.push({
            x: [evalPriceTalk],
            type: 'scatter',
            mode: 'markers',
            customdata: [{
                type: TraceType.KTalk,
                value: evalPriceTalk,
            }],
            marker: {
                color: kTalkMarkerColor
            },
            hoverinfo: 'none',
            name: 'eval-k-takl-value',
        });
    }

    const { range, dTick } = getXRange();

    const getPxTalkPointInfo = (pxTalk) => {
        if (!pxTalk) {
            return null;
        }

        const pxTalkStat = arraystat(pxTalks.map((px) => px.normalizedLevel));

        if (!pxTalkStat) {
            return null;
        }

        return [
            { name: 'Company', value: pxTalk.company && pxTalk.company.name },
            { name: 'Px Talk', value: pxTalk.normalizedLevel },
            { name: 'Q1', value: numericUtils.round(pxTalkStat.q1) || '' },
            { name: 'Med', value: numericUtils.round(pxTalkStat.median) || '' },
            { name: 'Q3', value: numericUtils.round(pxTalkStat.q3) || '' }
        ];
    };

    const getKTalkPointInfo = (value) => {
        return [{ name: 'K-Talk', value }];
    };

    const handleOnHover = (data = {}) => {
        chartUtils.setCursor('pointer')(data);

        const { points } = data;

        let onHoverData = null;

        const [point] = points.length > 1 ? [] : points || [];

        if (point?.customdata) {
            const { customdata } = point;

            if (customdata.type === TraceType.PxTalk) {
                const pxTalk = pxTalks[customdata.index];
                onHoverData = getPxTalkPointInfo(pxTalk);
            } else if (customdata.type === TraceType.KTalk) {
                onHoverData = getKTalkPointInfo(customdata.value);
            }
        } else {
            onHoverData = null;
        }

        if (onHover && typeof onHover === 'function') {
            onHover(onHoverData);
        }
    };

    const handleUnHover = (e) => {
        chartUtils.setCursor('crosshair')(e);
        if (onHover && typeof onHover === 'function') {
            onHover(null);
        }
    };

    const chartLayout = {
        autosize: true,
        xaxis: {
            showgrid: true,
            range,
            zeroline: false,
            tickcolor: tickColor,
            tickfont: tickFont,
            dtick: dTick,
        },
        yaxis: {
            visible: false,
        },
        height: chartHeight,
        boxmode: 'group',
        showlegend: false,
        hovermode: 'closest',
        hoverlabel,
        margin,
        boxgap
    };

    return (
        <>
            <Plot
                onHover={handleOnHover}
                onUnhover={handleUnHover}
                divId={divId}
                data={chartData}
                layout={chartLayout}
                config={mainConfig}
            />
            <div className="chart-legend flex-row">
                <div className="chart-box">
                    <span className="chart-item chart-item-px"></span>
                    <span>Px Talk</span>
                </div>
                {evalPriceTalkVisible &&
                    <div className="chart-box">
                        <span className="chart-item chart-item-ktalk"></span>
                        <span>K-Talk</span>
                    </div>
                }
            </div>
        </>
    );
}
