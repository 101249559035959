import React, { Component, Fragment } from 'react'

export default class ProgressCircle extends Component {
    render = () => {
        const { progress } = this.props;

        return (
            <Fragment>
                <div className="progress-circle">
                    <svg viewBox='0 0 16 16' preserveAspectRatio='none'>
                        <circle cx="8" cy="8" r="7"></circle>
                        <circle cx="8" cy="8" r="7" style={{ "--percent": `${progress}` }}></circle>
                    </svg>
                </div>
            </Fragment>
        );
    }
}
