import React, { Component } from 'react';
import { numericUtils } from '../../utils';
import IconSVG from "../../styles/svg-icons";
import { OnHoverTooltip } from "../common";

class Counter extends Component {
    render = () => {
        const { name, required = false, min = 0, max = 255, value = min, className = '' } = this.props;

        return (
            <div className={className + ' control-stepper'}>
                <button className="btn-stepper btn-left" disabled={value === min} onClick={this.handleDecrement}>
                    <IconSVG name="minus" width={16} height={16} />
                </button>
                <input
                    className="form-control-stepper"
                    type="text"
                    name={name}
                    value={value === 0 ? value : value || ''}
                    pattern="^[0-9]+$"
                    required={required}
                    min={min}
                    max={max}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                />
                <OnHoverTooltip disabled={value !== max} overlay="The maximum value entered.">
                    <button className='btn-stepper btn-right' disabled={value === max} onClick={this.handleIncrement}>
                        <IconSVG name="plus" width={16} height={16} />
                    </button>
                </OnHoverTooltip>
            </div>
        );
    }

    handleDecrement = e => {
        const { onValueChanged, min = 0, value = min } = this.props;

        if (value === '') {
            onValueChanged(min);
        } else if (value > min) {
            onValueChanged(value - 1);
        }
    }

    handleIncrement = e => {
        const { onValueChanged, min = 0, max = 255, value = min } = this.props;

        if (value === '') {
            onValueChanged(min);
        } else if (value < max) {
            onValueChanged(value + 1);
        }
    }

    handleChange = e => {
        const { min = 0, max = 255, onValueChanged } = this.props;
        const value = e.target.value;
        if (value === '') {
            onValueChanged(value);
        } else if (numericUtils.isNumber(value)) {
            const number = Number(value);
            if (number >= min && number <= max) {
                onValueChanged(number);
            }
        }
    }

    handleBlur = e => {
        const { onValueChanged, min = 0, emptyEnabled = false } = this.props;
        const value = e.target.value;
        if (!emptyEnabled && value === '') {
            onValueChanged(min);
        }
    }
}

export default Counter;

