import React, { useState, useEffect } from 'react';
import { compareStrings } from '../../../utils/compare.utils';
import { TransferColumn } from './TransferColumn';
import { TransferOption } from './types';
import { arrayUtils } from '../../../utils/array.utils';

interface TransferProps {
    options: TransferOption[];
    selected?: TransferOption[];
    leftColumnTitle: string;
    rightColumnTitle: string;
    disabled?: boolean;
    rightActionDisabled?: boolean;
    withFixed?: boolean;
    destinationDraggable?: boolean;
    onChange?: (options: TransferOption[]) => void;
    onReset?: () => void;
}

function insertBefore<T>(list: T[], items: T | T[], predicate: (item: T) => boolean) {
    const [, itemIndex] = arrayUtils.findLastRange(list, predicate);

    if (itemIndex < 0) {
        return list;
    }

    const topPart = list.slice(0, itemIndex);
    const bottomPart = list.slice(itemIndex);

    return [...topPart, ...(Array.isArray(items) ? items : [items]), ...bottomPart];
}

export const Transfer: React.FC<TransferProps> = ({
    options,
    selected = [],
    leftColumnTitle,
    rightColumnTitle,
    disabled = false,
    rightActionDisabled = false,
    withFixed = true,
    destinationDraggable = true,
    onChange = () => {},
    onReset = () => {},
}) => {
    const byNameSorter = (a: TransferOption, b: TransferOption) => compareStrings(a.title, b.title);

    const [selectedOptions, setSelectedOptions] = useState<TransferOption[]>(selected);
    const [unselectedOptions, setUnselectedOptions] = useState<TransferOption[]>([]);

    useEffect(() => {
        const getUnselectedOptions = (selected: TransferOption[]) => {
            const selectedKeys = selected.map(({ key }) => key);
            return options.filter(option => !selectedKeys.includes(option.key)).sort(byNameSorter);
        };

        setSelectedOptions(selected);
        setUnselectedOptions(getUnselectedOptions(selected));
    }, [options, selected]);

    const handleSelectAll = () => {
        const newSelected = withFixed
            ? insertBefore(selectedOptions, unselectedOptions, x => x.fixed)
            : selectedOptions.concat(unselectedOptions);

        setSelectedOptions(newSelected);
        setUnselectedOptions([]);

        onChange(newSelected);
    };

    const handleSelectItem = (key: string) => {
        const item = unselectedOptions.find(option => option.key === key);

        if (!item) {
            return;
        }

        const newSelected = withFixed
            ? insertBefore(selectedOptions, item, x => x.fixed)
            : selectedOptions.concat(item);

        setSelectedOptions(newSelected);

        onChange(newSelected);

        setUnselectedOptions(prevOptions => prevOptions.filter(option => option.key !== key).sort(byNameSorter));
    };

    const handleUnselectItem = (key: string) => {
        setUnselectedOptions(prevOptions =>
            prevOptions.concat(selectedOptions.filter(option => option.key === key)).sort(byNameSorter),
        );

        const newSelected = selectedOptions.filter(option => option.key !== key);

        setSelectedOptions(newSelected);

        onChange(newSelected);
    };

    const handleSortItems = (options: TransferOption[]) => {
        setSelectedOptions(options);
        onChange(options);
    };

    return (
        <div className="customize-columns-wrap">
            <TransferColumn
                title={leftColumnTitle}
                actionTitle="Select All"
                mode={'source'}
                sortable={false}
                disabled={disabled}
                actionDisabled={!unselectedOptions.length || disabled}
                options={unselectedOptions}
                onActionClick={handleSelectAll}
                onItemActionClick={handleSelectItem}
            />

            <TransferColumn
                title={rightColumnTitle}
                actionTitle="Reset to Default"
                mode={'destination'}
                disabled={disabled}
                actionDisabled={rightActionDisabled || disabled}
                sortable
                draggable={destinationDraggable}
                options={selectedOptions}
                onActionClick={onReset}
                onSort={handleSortItems}
                onItemActionClick={handleUnselectItem}
            />
        </div>
    );
};
