import * as React from 'react';
import cn from 'classnames';
import { user } from '../../user/user';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../access/ActionBlocker';
import { RoleActionBlocker } from '../access/RoleActionBlocker';

interface Props {
    disabled?: boolean;
    children: React.ReactNode;
    sectionBlock?: boolean;
    classNames?: string;
    requiredFeatures?: SubscriptionFeature[];
    requiredRoles?: string[];
    onClick?: (e: React.MouseEvent) => void;
}

export function ContextMenuItem({ disabled = false, sectionBlock = false, classNames = '', requiredFeatures, requiredRoles, children, onClick }: Props) {
    const featureBlocked = requiredFeatures?.length ? !user.hasFeatures(...requiredFeatures) : false;
    const roleBlocked = requiredRoles?.length ? !user.hasRoles(...requiredRoles) : false;

    const handleClick = (e: React.MouseEvent) => {
        if (disabled || featureBlocked || roleBlocked) {
            e.stopPropagation();
            e.preventDefault();
        } else if (onClick) {
            onClick(e);
        }
    }

    return (
        <li
            className={cn({ disabled: disabled || featureBlocked || roleBlocked, section: sectionBlock }, classNames)}
            onClick={handleClick}
        >
            {
                featureBlocked &&
                <ActionBlocker features={requiredFeatures}>
                    {children}
                </ActionBlocker>
            }
            {
                roleBlocked &&
                requiredRoles?.length &&
                <RoleActionBlocker roles={requiredRoles}>
                    {children}
                </RoleActionBlocker>
            }
            {!featureBlocked && !roleBlocked && children}
        </li>
    );
}
