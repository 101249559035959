import keys from 'lodash/keys';
import { DailyTradingLimit } from "../../../types/bid-as-dealer/SettlementAgentClientAgreement";
import { Rating, Ratings } from "../../../types/enums/Rating";
import { formatUtils, moneyUtils, numericUtils } from "../../../utils";
import { Table } from "../../bidding/common/table";
import { ColumnBuilder } from "../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { IColumnDefinition } from "../../bidding/common/table/types/ColumnDefinition";
import { useTotalDailyTradingLimit } from './useTotalDailyTradingLimit';
import { SettlementAgentCommissionByRating } from '../../../types/bid-as-dealer/SettlementAgentCommissionByRating';
import { constants } from '../../../constants';
import { ListBuilder } from '../../../utils/ListBuilder';

interface SettlementAgentDailyTraidingLimitProps {
    dailyTradingLimit: DailyTradingLimit;
    commissions?: SettlementAgentCommissionByRating;
}

interface TradingLimitsTableItem {
    rating: Rating;
    tradingLimit?: number;
    commission?: number;
}

export const SettlementAgentDailyTraidingLimit = ({ dailyTradingLimit, commissions }: SettlementAgentDailyTraidingLimitProps) => {
    const total = useTotalDailyTradingLimit(dailyTradingLimit);

    const isByRating = !keys(dailyTradingLimit.byRating).every(key => dailyTradingLimit.byRating[key as Rating] === null);

    const getColumns = () => {
        const rating: IColumnDefinition<TradingLimitsTableItem> = {
            columnKey: 'rating',
            renderColumnHeaderContent: () => 'Rtg',
            renderColumnContent: item => item.rating
        }
        const tradingLimit: IColumnDefinition<TradingLimitsTableItem> = {
            columnKey: 'tradingLimit',
            renderColumnHeaderContent: () => 'Daily Trading Limit, USD',
            renderColumnContent: item => moneyUtils.money(item.tradingLimit || 0),
            className: 'data-list-cell-lg text-right'
        }
        const commission: IColumnDefinition<TradingLimitsTableItem> = {
            columnKey: "commission",
            renderColumnHeaderContent: () => "Commission, %",
            renderColumnContent: item => item.commission
                ? formatUtils.formatDecimal(Number(item.commission), constants.commissionDecimalPlaces)
                : formatUtils.formatDecimal(constants.settlementAgentDefaultCommission, constants.commissionDecimalPlaces),
            className: 'data-list-cell-lg data-list-commission overflow-visible text-right'
        }

        const columns = new ListBuilder<IColumnDefinition<TradingLimitsTableItem>>()
            .add(rating)
            .addWhen(() => isByRating, tradingLimit)
            .addWhen(() => commissions != null, commission)
            .result();

        return columns.map(c => new ColumnBuilder(c));
    }

    const renderTotalDailyLimit = (value: string) => {
        return <div className="total-limits-row flex-row">
            <span className="label text-warm-grey ">Total daily limit</span>
            <span>{value} USD</span>
        </div>
    }

    return (
        <>
            {
                isByRating || commissions != null ?
                    <>
                        <Table
                            className="data-list-striped"
                            columns={getColumns()}
                            dataItems={Ratings.map((r) => ({
                                rating: r,
                                tradingLimit: dailyTradingLimit.byRating[r],
                                commission: commissions?.[r]
                            }))}
                        />
                        {numericUtils.isNumber(dailyTradingLimit.common) &&
                            renderTotalDailyLimit(moneyUtils.money(dailyTradingLimit.common))}
                    </>
                    : renderTotalDailyLimit(moneyUtils.money(total))
            }
        </>
    )
}
