import React, { PropsWithChildren } from 'react';

interface Props {
    value: boolean;
    label?: string;
    disabled?: boolean;
    onClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Toggle: React.FC<PropsWithChildren<Props>> = (
    {
        value,
        label = '',
        onClick,
        disabled = false,
        children
    }
) => {
    return (
        <label className="toggle">
            <input
                type="checkbox"
                checked={value}
                onChange={(e) => onClick && onClick(e)}
                disabled={disabled}
            />
            <span className="toggle-icon" />
            {(label || children) &&
                <span className="toggle-text">{label || children}</span>
            }
        </label>
    );
};

Toggle.defaultProps = {
    disabled: false
};
