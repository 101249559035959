import cn from 'classnames';
import { OnHoverTooltip } from '../OnHoverTooltip';
import { constants } from '../../../constants/constants';

interface Props {
    label: string;
    value?: string | number;
    values?: (string | number | null)[];
    tooltip?: string;
}

const EmptyPlaceholder = () => (
    <OnHoverTooltip overlay="There are no available data">
        <span className="summary-value disabled">
            {constants.emptyPlaceholder}
        </span>
    </OnHoverTooltip>
)

export function SummaryRowItem({ label, value, values, tooltip }: Props) {
    const valueResult =
        value ?? values
            ?.map((x, i) => [!!i && "/", x || <EmptyPlaceholder key={i} />])

    const isEmpty = !value && (!values?.length || !values.some(x => x))

    return (
        <span key={label} className="summary-element text-sm">
            <OnHoverTooltip overlay={tooltip ?? <>{label} {valueResult}</>}>
                <span className={cn("summary-label", { disabled: isEmpty })}>{label}</span>
            </OnHoverTooltip>
            <span className={cn("summary-value", { disabled: isEmpty })}>
                {valueResult || <EmptyPlaceholder />}
            </span>
        </span>
    );
}