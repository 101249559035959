import React, { useEffect, useState } from "react";
import MaskedInput from "react-text-mask";
import classNames from 'classnames';
import { FilterRangeOption } from "../../../types/filters/FilterRangeOption";
import { formatUtils, numericUtils } from "../../../utils";
import { ClickOutside } from "../../common";
import { constants, errorMessages } from "../../../constants";
import { FilterDropDown } from "./FilterDropDown";
import { ExpandClearToggle } from './ExpandClearToggle';
import { DecimalMaskedInput, NumberMaskOptions } from "../../controls/DecimalMaskedInput";
import { FormError } from "../../controls";
import { FormFieldLabel } from "../../forms";
import { createNumberMask } from "text-mask-addons";

interface Props {
    defaultExpanded?: boolean;
    disabled?: boolean;
    options: FilterRangeOption;
    title: string | React.ReactNode;
    onChangeRange: (i: FilterRangeOption) => void;
    onClearAll?: () => void;
    isApplied?: boolean;
    mask: Partial<NumberMaskOptions>;
}

const initialValues = { from: "", to: "" };

export function FilterRange({
    defaultExpanded = false,
    options,
    title,
    onChangeRange,
    onClearAll,
    isApplied = false,
    disabled = false,
    mask
}: Props) {
    const { from, to, minValue, maxValue } = options;
    const [expanded, setExpanded] = useState(defaultExpanded);
    const [errors, setErrors] = useState(initialValues);

    const minRangeValue = Number(minValue ?? 0);
    const maxRangeValue = Number(maxValue);
    const selected = numericUtils.isNumber(from) || numericUtils.isNumber(to);
    const isResetDisabled = !(!!from || !!to);

    useEffect(() => {
        if (from || to) {
            if (!String(from)) {
                const newInputValues = { ...options, from: 0 };
                setErrors(initialValues);
                onChangeRange(newInputValues);
            } else if (!String(to) && maxRangeValue) {
                const newInputValues = { ...options, to: maxRangeValue };
                setErrors(initialValues);
                onChangeRange(newInputValues);
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    useEffect(() => {
        if (
            (from == null || from === initialValues.from) &&
            (to == null || to === initialValues.to)) {
            setErrors(initialValues);
        }
    }, [from, to])

    function renderTitle() {
        return numericUtils.isNumber(from) || numericUtils.isNumber(to)
            ? `${title}:
            ${formatUtils.formatDecimal(Number(from || minRangeValue) || 0, mask.decimalLimit || 0)} -
            ${formatUtils.formatDecimal(Number(to || maxRangeValue) || 0, mask.decimalLimit || 0)}`
            : `${title}: All`;
    }

    function getPlaceholder(value?: number | string) {
        if (value != null && numericUtils.isNumber(value)) {
            if (typeof value === 'string') return value;

            return mask.allowDecimal && mask.decimalLimit
                ? formatUtils.formatDecimal(Number(value), mask.decimalLimit)
                : String(value);
        }

        return '';
    }

    function renderInput(key: string, value?: number | string, placeholder?: string, hasError: boolean = false) {
        const className = classNames('form-control', { 'is-invalid': hasError });

        if (mask.allowDecimal) {
            return <DecimalMaskedInput
                id={key}
                name={key}
                value={value}
                integerLimit={mask.integerLimit}
                decimalLimit={mask.decimalLimit ?? 2}
                placeholder={placeholder}
                className={className}
                onValueChange={value => onChangeRange({ ...options, [key]: value })}
                onChange={handleInputChange}
            />
        }

        return <MaskedInput
            id={key}
            name={key}
            value={value}
            placeholder={placeholder}
            className={className}
            mask={createNumberMask(mask)}
            onChange={handleInputChange}
        />
    }

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        let newInputValues = {
            ...options,
            [e.target.name]: e.target.value
        };
        const { from, to } = newInputValues;

        setErrors(initialValues);

        if (from && to && Number(from) > Number(to)) {
            setErrors({ from: errorMessages.fromRangeBiggerThenToRange, to: '' });
        }

        if (Number(from) < minRangeValue)
            newInputValues.from = minRangeValue;
        else if (Number(from) > maxRangeValue)
            newInputValues.from = maxRangeValue;

        if (Number(to) < minRangeValue)
            newInputValues.to = minRangeValue;
        else if (Number(to) > maxRangeValue)
            newInputValues.to = maxRangeValue;

        onChangeRange(newInputValues);
    }

    function handleClearAll() {
        setErrors(initialValues);
        onClearAll && onClearAll();
    }

    return (
        <ClickOutside className="control-filter-select" onClick={() => setExpanded(false)}>
            <div
                className={classNames("control-filter-select-btn", {
                    expanded,
                    error: !!errors.from || !!errors.to,
                    applied: isApplied && selected,
                    changed: !isApplied && selected,
                    disabled
                })}
                onClick={() => setExpanded(!expanded)}
            >
                {renderTitle()}
                <ExpandClearToggle
                    filterSelected={selected}
                    onExpand={() => setExpanded(!expanded)}
                    onClear={onClearAll}
                />
            </div>
            {!disabled && expanded && (
                <FilterDropDown
                    className="control-filter-content-range"
                    expanded={expanded}
                    value={options}
                >
                    <button
                        className="btn-link"
                        disabled={isResetDisabled}
                        onClick={handleClearAll}
                    >
                        Reset to default
                    </button>
                    <div className="control-filter-range-row">
                        <div className="form-control-wrapper">
                            <FormFieldLabel text="From" />
                            {renderInput("from", from, getPlaceholder(minValue ?? 0), Boolean(errors.from))}
                            <FormError message={errors.from} />
                        </div>
                        <span className="dash">{constants.emptyPlaceholder}</span>
                        <div className="form-control-wrapper">
                            <FormFieldLabel text="To" />
                            {renderInput("to", to, getPlaceholder(maxValue ?? 0), Boolean(errors.to))}
                            <FormError message={errors.to} />
                        </div>
                    </div>
                </FilterDropDown>
            )}
        </ClickOutside>
    );
}
