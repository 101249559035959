import React from 'react';
import cn from 'classnames';
import { ContactList } from './ContactList';
import { ShowFor } from '../access/ShowFor';
import { constants } from '../../constants/constants';
import { roles } from '../../constants/roles';
import { stringUtils } from '../../utils/string.utils';
import { FlexRow } from '../common/FlexRow';
import { OnHoverTooltip } from '../common/OnHoverTooltip';
import { Company } from '../../types/company/Company';
import { BankLink } from '../amrPipeline/common/BankLink';

interface Props {
    company: Company
}

export const CompanyDetailsPanelContent: React.FC<Props> = ({ company }) => {
    const renderCodeBlock = (code: string | undefined, label: string) =>
        <Section title={label}>
            <div>{stringUtils.isNullOrWhiteSpace(code) ? constants.emptyPlaceholder : code}</div>
        </Section>

    const renderShortCodeBlock = () =>
        <Section title='Shortcode'>
            {stringUtils.isNullOrWhiteSpace(company.code)
                ? constants.emptyPlaceholder
                : (
                    <BankLink
                        legalName={company.code}
                        referenceName={company.referenceName}
                        overlayText={company.name}
                    />
                )
            }
        </Section> 

    return (
        <div className="company-members">
            <h4>Information</h4>
            <div className="company-info">
                <ShowFor roles={roles.bd()}>
                    {renderShortCodeBlock()}
                </ShowFor>
                <ShowFor roles={[...roles.seller(), roles.Administrator]}>
                    {renderShortCodeBlock()}
                    {renderCodeBlock(company.mpid, 'MPID')}
                </ShowFor>
                <Section title="Address">
                    <div>{company.address}</div>
                    <div>{company.city}, {company.state}</div>
                    <div>{company.zipCode ? `${company.zipCode},` : ''} {company.country ? company.country.name : ''}</div>
                </Section>
                <Section title="Website" className="website-address">
                    <OnHoverTooltip overlayClassName="break-word" overlay={company.website}>
                        <div className="text-ellipsis">{company.website || constants.emptyPlaceholder}</div>
                    </OnHoverTooltip>
                </Section>
            </div>
            <h4>Contacts</h4>
            <div className={cn('company-members-content', { 'empty': !company.users.length })}>
                <ContactList contacts={company.users} primaryId={company.primaryContactId} />
            </div>
        </div>
    )
}

interface SectionProps {
    title: string;
    className?: string;
    children: React.ReactNode;
}

function Section({ title, className, children }: SectionProps) {
    return (
        <FlexRow>
            <div className="flex-item-auto">{title}</div>
            <div className={cn("flex-item-auto", className)}>
                {children}
            </div>
        </FlexRow>
    );
}