import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { constants } from '../../../constants';
import { DayModifiers, DayPicker, Matcher } from 'react-day-picker';
import cn from 'classnames';
import { CustomDatePickerYearView } from './CustomDatePickerYearView';
import IconSVG from "../../../styles/svg-icons";

enum ViewType {
    Picker,
    Month,
    Year
}

interface CustomDatePickerProps {
    onDayClick: (currentDate: Date) => void
    date: Date
    from?: Date | null
    to?: Date | null
    disabledDays?: Matcher;
    hoverDate?: Date | null;
    onDayHover?: (day?: Date, modifiers?: DayModifiers) => void;
}

export function CustomDatePicker({ onDayClick, from = null, to = null, date, disabledDays = [], onDayHover, hoverDate }: CustomDatePickerProps) {
    const lastYear = constants.datePickerMaxYear;
    const [currentViewType, setCurrentViewType] = useState(ViewType.Picker);
    const [currentDate, setCurrentDate] = useState(date);

    useEffect(() => {
        setCurrentDate(date)
    }, [date]);

    useEffect(() => {
        setCurrentViewType(ViewType.Picker);
    }, [from, to]);

    const handleYearClick = (year: number) => {
        currentDate.setFullYear(year);
        const date = moment(currentDate).toDate();
        setCurrentDate(new Date(date));
        setCurrentViewType(ViewType.Picker)
    };

    const handleOnMonthClick = (month: number, hide = false) => {
        const date = moment(currentDate).set('month', month).toDate();
        setCurrentDate(new Date(date));
        hide && setCurrentViewType(ViewType.Picker)
    };

    const handleOnDayClick = (currentDate: Date, modifiers: DayModifiers) => {
        if (modifiers.disabled) {
            return;
        }
        setCurrentDate(currentDate);
        onDayClick(currentDate);
    };

    const handleOnYearChange = (year: number) => {
        currentDate.setFullYear(year);
        const date = moment(currentDate).toDate();
        setCurrentDate(new Date(date));
    };

    const handleDayMouseEnter = (day: Date) => {
        onDayHover && onDayHover(day);
    }

    const handleDayMouseLeave = () => {
        onDayHover && onDayHover(undefined);
    }

    const handleNextMonth = () => {
        const date = moment(currentDate).date(1).add(1, 'month').toDate();
        setCurrentDate(new Date(date));
    };

    const handlePrevMonth = () => {
        const date = moment(currentDate).date(1).subtract(1, 'month').toDate();
        setCurrentDate(new Date(date));
    };

    const renderCaptionElement = () => {
        const monthList = moment.months();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        return (
            <div className="rdp-caption">
                <div className="rdp-caption_label">
                    <button className="btn btn-link" onClick={() => setCurrentViewType(ViewType.Month)}>
                        {monthList[currentMonth]}
                    </button>
                    <button className="btn btn-link" onClick={() => setCurrentViewType(ViewType.Year)}>
                        {currentYear}
                    </button>
                </div>
                <div className="rdp-nav">
                    <button
                        className="rdp-nav_button rdp-nav_button_previous"
                        onClick={() => handlePrevMonth()}
                    >
                        <IconSVG name="icon-expand" width={16} height={16} />
                    </button>
                    <button
                        className="rdp-nav_button rdp-nav_button_next"
                        onClick={() => handleNextMonth()}
                    >
                        <IconSVG name="icon-expand" width={16} height={16} />
                    </button>
                </div>
            </div>
        )
    };

    const renderPickerView = () => {
        return (
            <DayPicker
                onDayMouseLeave={handleDayMouseLeave}
                className="rdp-custom"
                selected={from ? [from, { from, to: to || from }] : []}
                modifiers={
                    from ?
                        {
                            desirableRange: { from: from ?? undefined, to: hoverDate ?? undefined },
                            ...(hoverDate && hoverDate > from ? { desirableLater: hoverDate } : {}),
                            ...(hoverDate && hoverDate < from ? { desirableEarlier: hoverDate } : {}),
                            start: from,
                            end: to || from
                        } : {}
                }
                modifiersClassNames={{
                    start: "rdp-day_range_start",
                    end: "rdp-day_range_end",
                    desirableRange: 'rdp-day_desirable_range'
                }}
                onDayClick={handleOnDayClick}
                showOutsideDays
                month={currentDate}
                onDayMouseEnter={handleDayMouseEnter}
                fromMonth={new Date(constants.datePickerMinYear, 0)}
                toMonth={new Date(lastYear, 11)}
                captionLayout="buttons"
                disabled={disabledDays}
                components={{
                    Caption: renderCaptionElement,
                }}
            />
        );
    };

    const renderMothView = () => {
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const months = moment
            .monthsShort()
            .map((month, index) =>
                <div
                    className={cn({ 'rdp-list_item': true, 'rdp-list_item_current': currentMonth === index })}
                    key={month}
                >
                    <button className="rdp-button rdp-list_button" onClick={() => handleOnMonthClick(index, true)}>
                        {month}
                    </button>
                </div>
            );
        return (
            <div className="rdp">
                <div className="rdp-caption">
                    <div className="rdp-caption_label">
                        <button className="btn btn-link" onClick={() => setCurrentViewType(ViewType.Year)}>
                            {currentYear}
                        </button>
                    </div>
                    <div className="rdp-nav">
                        <button
                            className={
                                cn(
                                    "rdp-nav_button rdp-nav_button_previous",
                                    { 'rdp-nav_button_disabled': currentYear <= constants.datePickerMinYear }
                                )
                            }
                            onClick={() => handleOnYearChange(currentYear - 1)}
                        >
                            <IconSVG name="icon-expand" width={16} height={16} />
                        </button>
                        <button
                            className={
                                cn(
                                    "rdp-nav_button rdp-nav_button_next",
                                    { 'rdp-nav_button_disabled': currentYear >= lastYear }
                                )
                            }
                            onClick={() => handleOnYearChange(currentYear + 1)}
                        >
                            <IconSVG name="icon-expand" width={16} height={16} />
                        </button>
                    </div>
                </div>
                <div className="rdp-list rdp-list_months">
                    {months}
                </div>
            </div>
        )
    };

    const renderContent = () => {
        if (currentViewType === ViewType.Year) {
            return (
                <CustomDatePickerYearView
                    minYear={constants.datePickerMinYear}
                    currentDate={currentDate}
                    onYearClick={handleYearClick}
                />
            );
        }
        if (currentViewType === ViewType.Month) {
            return renderMothView();
        }
        return renderPickerView();
    };

    return renderContent()
}
