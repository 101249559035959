import * as React from 'react';

interface Props {
    children: React.ReactNode,
}

export const Filter = React.forwardRef(({ children }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    return (
        <div ref={ref} className="filter-item">{children}</div>
    );
});
