import { roles } from "../../../constants";
import { user } from "../../../user";
import { ShowFor } from "../../access";
import { FeatureButton } from "../../access/FeatureButton";
import { RoleActionBlocker } from "../../access/RoleActionBlocker";
import { AllToAllArgeementRequiredActionBlocker } from "../../agreements/AllToAllArgeementRequiredActionBlocker";
import { DirectBiddingDisclaimerActionBlocker } from "../../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerActionBlocker";

interface NewBwicButtonProps {
    selectedSecurityCount?: number;
    disabled?: boolean;
    handleClick: () => void;
}

export const NewBwicButton = ({ selectedSecurityCount, disabled, handleClick }: NewBwicButtonProps) => {
    if (user.current()?.isClearingBank) {
        return null;
    }

    return (
        <ShowFor roles={[...roles.seller(), roles.BrokerDealerTrader]}>
            <RoleActionBlocker roles={[roles.SellerTrader, roles.BrokerDealerTrader]}>
                {
                    blocked =>
                        <DirectBiddingDisclaimerActionBlocker onConfirm={handleClick}>
                            <AllToAllArgeementRequiredActionBlocker disabled={blocked}>
                                {
                                    argeementBlocked =>
                                        <FeatureButton
                                            disabled={disabled || argeementBlocked}
                                            className="btn btn-main"
                                            onClick={handleClick}
                                        >
                                            new bwic{Boolean(selectedSecurityCount) && ` (${selectedSecurityCount})`}
                                        </FeatureButton>
                                }

                            </AllToAllArgeementRequiredActionBlocker>
                        </DirectBiddingDisclaimerActionBlocker>
                }
            </RoleActionBlocker>
        </ShowFor>
    );
}