import { ReactNode } from "react";

interface Props {
    groupName: string;
    children: ReactNode;
}

export function SummaryRowGroup({ groupName, children }: Props) {
    return (
        <div className="summary-box">
            <b className="summary-group-name text-sm">{groupName}:</b>
            {children}
        </div>
    );
}