import React from 'react';
import classNames from 'classnames';

interface AccordionItemProps {
    children: React.ReactNode;
    isSelected: boolean;
    className?: string;
}

export function AccordionItem({ children, isSelected, className }: AccordionItemProps) {
    const collapsedItem = classNames('accordion-row', 'collapsable-item', className, { 'item-open': isSelected });

    return (
        <div className={collapsedItem}>
            {children}
        </div>
    )
}

AccordionItem.defaultProps = {
    isSelected: false,
};
