import React, { Component } from 'react';
import { connect } from 'react-redux';
import { contactsSaveViews, routes } from '../../constants';
import { contactsSaveActions as actions } from '../../actions';
import { ContactsGrid, Conflicts } from '.';
import { Preloader } from '../common';

class ContactsSave extends Component {
    componentDidMount = () => {
        const { location, dispatch } = this.props;
        const isEdit = location.pathname === routes.editContacts;

        dispatch(actions.init(isEdit));
    }

    componentWillUnmount = () => this.props.dispatch(actions.reset());

    render = () => {
        const { isLoading, view } = this.props;

        return (
            <Preloader inProgress={isLoading}>
                {
                    !isLoading &&
                    <>
                        {
                            view === contactsSaveViews.grid
                                ? <ContactsGrid />
                                : <Conflicts />
                        }
                    </>
                }
            </Preloader>
        );
    }
}

const mapStateToProps = ({ contactsSave }) => ({
    isLoading: contactsSave.isLoading,
    view: contactsSave.view
});

const connectedContactsSave = connect(mapStateToProps)(ContactsSave);
export { connectedContactsSave as ContactsSave }; 
