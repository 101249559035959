export const carriageReturn = "\n";
export const spaceChar = " ";
export const dotWithSpace = ". ";
export const editorChars = {
    bold: "**",
    italic: "_",
    strikethrough: "~~",
    bulletList: "*",
    numberedList: ".",
    headings: ["#", "##", "###", "####", "#####", "######"],
};
