import React from 'react';
import { noop } from 'lodash';
import { TransferHeader, TransferHeaderProps } from './TransferHeader';
import { TransferOption } from './types';
import { Sortable } from '../';
import { TransferItem } from './TransferItem';

interface TransferColumnProps extends TransferHeaderProps {
    options: TransferOption[];
    mode: "source" | "destination";
    disabled?: boolean;
    sortable?: boolean;
    onSort?: (options: TransferOption[]) => void;
    onItemActionClick?: (key: string) => void;
    draggable?: boolean;
}

export const TransferColumn: React.FC<TransferColumnProps> = ({
    options,
    mode,
    disabled = false,
    sortable = false,
    draggable = true,
    onSort = noop,
    onItemActionClick = noop,
    ...headerProps
}) => {
    const handleSort = (options: TransferOption[]) => {
        onSort([...options]);
    };

    const renderItem = (option: TransferOption) => (
        <TransferItem
            key={option.key}
            id={option.key}
            permanent={mode === "source" ? false : option.permanent}
            draggable={mode === "source" || !draggable ? false : !option.fixed}
            disabled={disabled}
            fixed={option.fixed}
            title={option.title}
            mode={mode}
            onClick={onItemActionClick}
        />
    );

    const renderOptions = (options: TransferOption[]) => {
        if (sortable) {
            const sortableOptions = options.map(option => ({
                ...option,
                draggable: !option.fixed,
            }));

            return (
                <Sortable
                    list={sortableOptions}
                    onChange={handleSort}
                >
                    {sortableOptions.map(renderItem)}
                </Sortable>
            );
        }

        return options.map(renderItem);
    };

    return (
        <div className="customize-columns-col">
            <TransferHeader {...headerProps} />
            <div className="customize-columns-body">
                {renderOptions(options)}
            </div>
        </div>
    );
};
