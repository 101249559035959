import React from 'react';

interface AccordionProps {
    children: React.ReactNode;
}

export function Accordion({ children }: AccordionProps) {
    return (
        <div className="accordion">
            {children}
        </div>
    )
}
