import classNames from 'classnames';
import * as React from 'react';

interface Props {
    className?: string;
    children: React.ReactNode;
}

export function Breadcrumbs ({ className, children }: Props) {
    return <ul className={classNames("breadcrumbs-list", className)}>{children}</ul>
}

