import * as React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    text?: string;
    route?: string;
    children?: React.ReactNode;
}

export function BreadcrumbsItem({ text, route = '', children }: Props) {
    const renderLink = () => route ? <Link to={route}>{text}</Link> : <>{text}</>

    return (
        <li>{children ? children : renderLink()}</li>
    );
}
