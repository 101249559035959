import React, { useEffect, useState } from 'react';
import { CustomDatePicker } from '../../date-range-custon-view/CustomDatePicker';
import { DateTimeInput } from './DateTimeInput';
import { DateTimeValue } from '../../../../types/filters/DateTimeValue';
import { TimeOption } from '../../../../types/filters/TimeOption';
import { alignDayTime, replicateTimeToDate } from './utils';

interface DateTimeSelectorProps {
    value?: DateTimeValue;
    minSelectYear?: number;
    onChange: (from?: DateTimeValue, to?: DateTimeValue) => void;
    onError: () => void;
}

export function DateTimeSelector({
    value,
    minSelectYear = 2010,
    onChange,
    onError,
}: DateTimeSelectorProps) {
    const [internalValue, setInternalValue] = useState(value);

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    const handleTimeChange = (value?: DateTimeValue) => {
        setInternalValue(value);
        onChange(alignDayTime(value), alignDayTime(value, true));
    };

    const handleDateChange = (date: Date) => {
        const newState = {
            date: replicateTimeToDate(date, internalValue?.date),
            timeOption: internalValue?.timeOption || TimeOption.AnyTime,
        };

        setInternalValue(newState);
        onChange(alignDayTime(newState), alignDayTime(newState, true));
    };

    return (
        <div className="custom-view-item">
            <div className="custom-view-item-body">
                <div className="custom-view-wrap flex-row">
                    <DateTimeInput
                        label="Date"
                        value={internalValue}
                        minYear={minSelectYear}
                        onChange={handleTimeChange}
                        onError={onError}
                    />
                </div>
                <CustomDatePicker
                    from={internalValue?.date}
                    to={internalValue?.date}
                    date={internalValue?.date || new Date()}
                    onDayClick={handleDateChange}
                />
            </div>
        </div>
    );
}
