import React, { Component } from 'react';
import { connect } from 'react-redux';
import { contactsSaveActions as actions } from '../../actions';
import { contactConflictTypes } from '../../constants';
import { nameUtils } from '../../utils';

class Conflict extends Component {
    renderDuplicates = unresolved => {
        return unresolved.map((c, i) =>
            <tr key={i}>
                <td className="text-center">
                    <span className="state-new">{c.id ? '' : 'NEW'}</span>
                </td>
                <td className="padding-left-0">{nameUtils.getFullName(c.firstName, c.lastName)}</td>
                <td><a href="mailto:{c.email}">{c.email}</a></td>
                <td>{c.company || this.props.companies.find(company => company.id === c.companyId).name}</td>
                <td><span className="text-ellipsis">{c.role}</span></td>
                <td><span className="text-ellipsis">{c.phone}</span></td>
                <td className="text-right">
                    {
                        unresolved.length > 1 &&
                        <button
                            className="btn-link btn-danger"
                            onClick={() => this.props.dispatch(actions.deleteDuplicate(c))}>
                            <i className="icon icon-delete"></i> Delete
                         </button>
                    }
                </td>
            </tr>
        );
    }

    render = () => {
        const { conflict } = this.props;
        const unresolved = conflict.duplicates.filter(d => !d.isDeleted);

        return (
            <>
                {
                    conflict.conflictType === contactConflictTypes.duplicateName &&
                    unresolved.length > 1 &&
                    <p className="conflicts-area-info">
                        <i className="icon icon-info"></i> These contacts have the same first and last name.
                        You may leave all of them, but one of them is probably outdated
                    </p>
                }
                {
                    conflict.conflictType === contactConflictTypes.duplicateEmail &&
                    unresolved.length > 1 &&
                    <p className="conflicts-area-info">
                        <i className="icon icon-warning yellow" /> These contacts have the same email. Please delete one of them.
                    </p>
                }
                {
                    unresolved.length === 1 &&
                    <p className="conflicts-area-info">
                        <i className="icon icon-sm icon-mark-big"></i> This conflict was resolved
                    </p>
                }
                <table className="main-table">
                    <colgroup>
                        <col width="65px"></col>
                        <col width="15%"></col>
                        <col width="30%"></col>
                        <col width="20%"></col>
                        <col width="10%"></col>
                        <col width="15%"></col>
                        <col width="15%"></col>
                    </colgroup>
                    <tbody>
                        {this.renderDuplicates(unresolved)}
                    </tbody>
                </table>
            </>
        );
    }
}

const mapStateToProps = ({ contactsSave }) => ({
    companies: contactsSave.companies
});

const connectedConflict = connect(mapStateToProps)(Conflict);
export { connectedConflict as Conflict }; 
