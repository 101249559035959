import React from 'react';
import classNames from 'classnames';

export function SecurityListColumn({ children, className = '', onClick, dataCellId = '' }) {
    return (
        <div data-cell-id={dataCellId} className={classNames('data-list-cell', className)} onClick={onClick}>
            {children}
        </div>
    )
}

SecurityListColumn.defaultProps = {
    onClick: null
}
