import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { routes } from '../../constants';
import { user } from '../../user';
import { yup, yupSchemaValidate } from '../../validation/yup';
import { EditContactSalesRequest } from './EditContactSalesRequest';
import { EditContactSalesRequestError } from './EditContactSalesRequestError';
import { ContactSalesRequestType, contactSalesRequestTypes } from '../../types/contact-sales/ContactSalesRequestType';
import { FormError, Popup, PopupBody, PopupFooter } from '../controls';
import { AppState } from '../../types/state/AppState';
import { contactSalesActions } from '../../actions';
import { FormFieldLabel } from '../forms';
import { constants } from '../../constants';

export function ContactSalesPopup() {
    const dispatch = useDispatch();
    const currentUser = user.current();
    const { isRequestSending } = useSelector((state: AppState) => state.contactSales);
    const [request, setRequest] = React.useState<EditContactSalesRequest>({
        firstName: currentUser!.firstName,
        lastName: currentUser!.lastName,
        email: currentUser!.email,
        description: ''
    });
    const [errors, setErrors] = React.useState<EditContactSalesRequestError>({});
    const hasErrors = (errors: any) => errors && Object.keys(errors).some((key: string) => errors[key]);
    const sendDisabled =
        isRequestSending ||
        !request.firstName ||
        !request.lastName ||
        !request.email ||
        !request.requestType ||
        hasErrors(errors);

    const validationSchema = React.useRef(yup.object().shape({
        firstName: yup.string().matches(constants.name).required(),
        lastName: yup.string().matches(constants.name).required(),
        email: yup.string().email().required(),
        requestType: yup.string().required(),
        description: yup.string().nullable()
    }));

    const setPropertValue = async (propertyName: string, value: string | number | ContactSalesRequestType) => {
        const changed = { ...request, [propertyName]: value };
        setRequest(changed);

        if (errors && errors[propertyName as keyof EditContactSalesRequestError]) {
            const errors = await validate(changed, propertyName);
            setErrors(errors);
        }
    }

    const validate = (billingContact: EditContactSalesRequest, propertyName?: string | undefined) =>
        yupSchemaValidate(validationSchema.current, billingContact, {}, errors, propertyName)

    const handleClose = () => {
        if (!isRequestSending) {
            dispatch(contactSalesActions.hide());
        }
    }

    const handleSend = async () => {
        const errors = await validate(request);
        setErrors(errors);

        if (!hasErrors(errors) && request.requestType != null) {
            const requestType = request.requestType;
            dispatch(contactSalesActions.send({ ...request, requestType }));
        }
    }

    return (
        <Popup
            modalClass="modal-contact-sales"
            title="Contact Sales"
            onClose={handleClose}
        >
            <PopupBody>
                <div className="form-row form-row-inline">
                    <div className="form-item">
                        <FormFieldLabel required={true} text="First Name" />
                        <div className="form-control-wrapper">
                            <input
                                className="form-control"
                                type="text"
                                value={request.firstName}
                                maxLength={64}
                                disabled={isRequestSending}
                                onChange={e => setPropertValue('firstName', e.target.value)}
                            />
                            <FormError message={errors.firstName} />
                        </div>
                    </div>
                    <div className="form-item">
                        <FormFieldLabel required={true} text="Last Name" />
                        <div className="form-control-wrapper">
                            <input
                                className="form-control"
                                type="text"
                                value={request.lastName}
                                maxLength={64}
                                disabled={isRequestSending}
                                onChange={e => setPropertValue('lastName', e.target.value)}
                            />
                            <FormError message={errors.lastName} />
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-item">
                        <FormFieldLabel required={true} text="Email" />
                        <div className="form-control-wrapper">
                            <input
                                className="form-control"
                                type="text"
                                value={request.email}
                                maxLength={64}
                                disabled={isRequestSending}
                                onChange={e => setPropertValue('email', e.target.value)}
                            />
                            <FormError message={errors.email} />
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-item">
                        <FormFieldLabel required={true} text="How can our sales team help you?" />
                        <div className="form-control-wrapper">
                            <select
                                className="form-control form-select"
                                value={request.requestType || ''}
                                disabled={isRequestSending}
                                onChange={e =>
                                    setPropertValue(
                                        'requestType',
                                        ContactSalesRequestType[e.target.value as keyof typeof ContactSalesRequestType]
                                    )
                                }
                            >
                                <option value="" disabled></option>
                                {contactSalesRequestTypes.map(x => <option key={x.value} value={x.value}>{x.text}</option>)}
                            </select>
                            <FormError message={errors.requestType} />
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-item">
                        <FormFieldLabel text="Anything else?" />
                        <div className="form-control-wrapper">
                            <textarea
                                className="form-control"
                                placeholder="What challenges are you looking to overcome with BWIC?"
                                value={request.description}
                                maxLength={256}
                                onChange={e => setPropertValue('description', e.target.value)}
                            />
                            <FormError message={errors.description} />
                        </div>
                    </div>
                </div>
                <p className="text-warm-grey">
                    By clicking “Submit”, I acknowledge receipt of the BWIC <Link to={routes.privacyPolicy} target="_blank">Privacy Policy</Link>.
                </p>
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" disabled={isRequestSending} onClick={handleClose}>Cancel</button>
                <button className="btn btn-main" disabled={sendDisabled} onClick={handleSend}>Submit</button>
            </PopupFooter>
        </Popup>
    );
}
