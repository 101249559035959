import React from 'react';
import { Chart } from './Chart';
import { dashboardChartBox } from '../../../../constants/dashboard';
import { HoverInfo } from '../HoverInfo';

export function ChartBox(props) {
    return (
        <HoverInfo>
            <Chart {...props} defaultXrange={true} constants={props.constants || dashboardChartBox} />
        </HoverInfo>
    )
}
