import React from 'react';
import cn from 'classnames';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: React.ReactNode;
    partially?: boolean;
    dataTestId?: string
}

export const RadioButton: React.FC<Props> = (
    {
        label,
        partially,
        dataTestId,
        ...rest
    }) => {
    return (
        <label className={cn('radio', rest.className, { 'radio-indeterminate': partially && rest.checked, 'disabled': rest.disabled })}>
            <input
                type="radio"
                {...rest}
                data-testid={dataTestId}
            />
            <span className="radio-icon" />
            {!!label && <span className="radio-text">{label}</span>}
        </label>
    );
};

RadioButton.defaultProps = {
    label: ''
};
