import React, { Component } from 'react';
import classNames from "classnames";

export default class ButtonSwitch extends Component {
    handleClick = (e, key) => {
        const { onSelectionChange } = this.props;

        e.stopPropagation();
        if (onSelectionChange) {
            onSelectionChange(key);
        }
    }

    render = () => {
        const { sourceItems, selectedValue, disabled = false, className = '' } = this.props;
        const items = sourceItems && sourceItems.map(i =>
            <div
                key={i.key}
                className={i.key === selectedValue ? 'control-group-item selected' : 'control-group-item'}
                onClick={disabled ? undefined : e => this.handleClick(e, i.key)}
            >
                {i.title || i.key}
            </div>);

        return (
            <div className={classNames('control-group ' + className, { disabled })}>
                {items}
            </div>
        );
    }
}
