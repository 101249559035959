import { ReactNode } from "react"
import { Preloader } from "../Preloader";

interface Props {
    isRequesting?: boolean
    children: ReactNode;
}
export function SummaryRow({ isRequesting = false, children }: Props) {
    return (
        <div className="sub-header sub-header-pipeline sub-header-arranger-pipeline sub-header-summary">
            <div className="sub-header-row sub-header-summary type02">
                <Preloader inProgress={isRequesting} fullScreen={false} small text="Loading summary...">
                    {children}
                </Preloader>
            </div>
        </div>
    );
}