import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
    to: string
    disabled?: boolean
    className?: string
    children?: React.ReactNode
    [extraProps: string]: any
}

export function Link({ to, disabled = false, className, children, ...rest }: Props) {
    if (disabled) {
        return <button
            className={className ?? "btn btn-ghost"}
            disabled={disabled}
        >
            {children}
        </button>
    }

    return <RouterLink className={className} to={to} {...rest}>{children}</RouterLink>
}
