import * as React from 'react';
import classNames from 'classnames';
import IconSVG from '../../styles/svg-icons';

interface RightSidePanelProps {
    title: React.ReactNode;
    className?: string;
    children: React.ReactNode;
    closeButton?: React.ReactNode;
    onClose?: (e: React.MouseEvent<HTMLElement>) => void;
}

interface CloseButtonProps {
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

function RightSidePanelCloseButton({ onClick }: CloseButtonProps) {
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        onClick(e);
        (e.target as HTMLElement).getBoundingClientRect();
    }

    return (
        <button className="btn-close" onClick={handleClick}>
            <IconSVG name="close" width={16} height={16} />
        </button>
    );
}

export function RightSidePanel({ title, className, closeButton, onClose, children }: RightSidePanelProps) {
    return (
        <div className={classNames('right-panel', className)}>
            <div className="right-panel-header">
                <div className="right-panel-title"><h3>{title}</h3></div>
                <div className="right-panel-close">
                    {closeButton ?? (onClose && <RightSidePanelCloseButton onClick={onClose} />)}
                </div>
            </div>
            <div className="right-panel-scroll">
                <div className="right-panel-content">
                    {children}
                </div>
            </div>
        </div>
    );
}
