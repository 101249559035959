import React from 'react';
import { useDispatch } from 'react-redux';
import { RightSidePanel } from '../controls';
import { companyDetailsActions } from '../../actions/company-details.actions';
import { Preloader } from '../common';
import { CompanyDetailsPanelContent } from './CompanyDetailsPanelContent';
import { useAppSelector } from '../../effects/useAppSelector';

export const CompanyDetailsPanel = () => {
    const dispatch = useDispatch();
    const visible: boolean = useAppSelector(s => s.companyDetails.isPanelVisible);
    const currentCompanyId = useAppSelector(s => s.companyDetails.currentCompanyId);
    const isRequestingCompany = useAppSelector(s => s.companyDetails.isRequestingCompany[currentCompanyId]);
    const company = useAppSelector(s => s.companyDetails.companies[currentCompanyId]);

    if (!visible) {
        return null
    }

    return (
        <RightSidePanel
            className="right-panel-company-members"
            title={company?.name || ''}
            onClose={() => dispatch(companyDetailsActions.hide())}
        >
            <Preloader inProgress={isRequestingCompany}>
                {!!company && <CompanyDetailsPanelContent company={company} />}
            </Preloader>
        </RightSidePanel>
    )
};
