import React from 'react';
import IconSVG from '../../../styles/svg-icons';

interface ExpandClearToggleProps {
    filterSelected: boolean;
    onExpand?: () => void;
    onClear?: () => void;
}

export const ExpandClearToggle = ({
    filterSelected,
    onExpand,
    onClear,
}: ExpandClearToggleProps) => {
    const handleExpandOrClear = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (filterSelected && onClear) {
            return onClear();
        }

        return onExpand && onExpand();
    };

    const renderButton = () => {
        if (onClear && filterSelected) {
            return <IconSVG name="close" width={16} height={16} />;
        }

        return <IconSVG name="icon-expand" width={16} height={16} />;
    };

    return (
        <div onClick={handleExpandOrClear} className="expand-clear-toggle">
            {renderButton()}
        </div>
    );
}
