import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../history';
import { contactsSaveActions as actions } from '../../actions';
import { RouteLeavingGuard } from '../routing';
import { routes, contactConflictTypes } from '../../constants';
import { Conflict } from '.';
import { Breadcrumbs } from '../controls/breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../controls/breadcrumbs/BreadcrumbsItem';

class Conflicts extends Component {
    render = () => {
        const { conflicts, isSaved, dispatch } = this.props;
        const canSave = !isSaved && conflicts.every(c => c.isResolved || c.conflictType === contactConflictTypes.duplicateName);

        return (
            <>
                <div className="container address-book">
                    <div className="sub-header">
                        <div className="sub-header-row type01 flex-row">
                            <Breadcrumbs>
                                <BreadcrumbsItem route={routes.dealers} text='Dealers' />
                            </Breadcrumbs>
                            <h1>Conflicts</h1>
                            <div className="flex-item-right controls">
                                <button className="btn btn-ghost" type="button" onClick={() => dispatch(actions.backToContactsEdit())}>back</button>
                                <button className="btn btn-main" disabled={!canSave} type="button" onClick={() => dispatch(actions.saveResolved())}>save</button>
                            </div>
                        </div>
                    </div>
                    <table className="main-table">
                        <colgroup>
                            <col width="65px"></col>
                            <col width="15%"></col>
                            <col width="30%"></col>
                            <col width="20%"></col>
                            <col width="10%"></col>
                            <col width="15%"></col>
                            <col width="15%"></col>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>&nbsp;</th>
                                <th className="padding-left-0">Name</th>
                                <th>Email</th>
                                <th>Broker-Dealer</th>
                                <th>Title</th>
                                <th>Phone</th>
                                <th>&nbsp;</th>
                            </tr>
                        </tbody>
                    </table>
                    <div className="conflicts-area">
                        {conflicts.map((c, i) => <Conflict key={i} conflict={c} />)}
                    </div>
                    <RouteLeavingGuard
                        navigate={pathname => history.push(pathname)}
                        shouldBlockNavigation={() => !isSaved}
                    />
                </div>
            </>
        );
    };
}

const mapStateToProps = ({ contactsSave }) => ({
    conflicts: contactsSave.conflicts,
    isSaved: contactsSave.isSaved
});

const connectedConflicts = connect(mapStateToProps)(Conflicts);
export { connectedConflicts as Conflicts };
