import React, { Component } from 'react';
import cn from 'classnames';
import { ClickOutside } from '../common/ClickOutside';
import IconSVG from '../../styles/svg-icons';
import { ContextMenuItem } from './ContextMenuItem';

export default class ContextMenu extends Component {
    state = {
        visible: false,
        showAbove: false,
        bottom: 0,
    };

    componentDidUpdate() {
        if (this.state.visible && this.adjustMenuDirection) {
            this.adjustMenuDirection = false;
            const iconHeight = (this._refIcon && this._refIcon.clientHeight) || 0;
            const distance = window.innerHeight - this.state.bottom - iconHeight;
            if (this._refList && this._refList.clientHeight > distance) {
                this.setState({ showAbove: true });
            }
        }
    }

    show = e => {
        e.preventDefault();
        e.stopPropagation();
        this.adjustMenuDirection = true;
        const boundingClientRect = e.target.getBoundingClientRect();
        this.setState({ visible: true, bottom: boundingClientRect.bottom + (this.props.bottomMargin || 0) });
        this.props.onShow && this.props.onShow();
    };

    hide = () => {
        this.setState({ visible: false, showAbove: false });
        this.props.onHide && this.props.onHide();
    }

    handleMenuClick = e => {
        e.preventDefault();
        e.stopPropagation();
        this.hide();
    }

    handleMenuItemClick = (item, e) => {
        if (typeof item.action === 'function') {
            item.action(e);
        }
    };

    renderMenu = items =>
        <ClickOutside onClick={this.hide}>
            <ul
                ref={node => this._refList = node}
                className={cn("context-menu-dropdown", this.state.showAbove ? 'show-above' : null)}
                onClick={this.handleMenuClick}
            >
                {
                    this.props.children || items?.map((item, index) =>
                        <ContextMenuItem
                            key={index}
                            disabled={item.disabled}
                            requiredFeatures={item.requiredFeature ? [item.requiredFeature] : undefined}
                            requiredRoles={item.requiredRoles}
                            sectionBlock={item.sectionBlock}
                            classNames={item.classNames}
                            onClick={(e) => this.handleMenuItemClick(item, e)}
                        >
                            {item.textComponent && <item.textComponent />}
                            {item.text}
                        </ContextMenuItem>
                    )
                }
            </ul>
        </ClickOutside>

    renderTargetComponent = () =>
        this.props.customTargetComponent ||
        <span className={cn("btn-link", {"btn-link-no-label": !this.props.text})}><IconSVG name="more" width={16} height={16} />{this.props.text}</span>;

    render = () => {
        const { className, menuItems, disabled } = this.props;

        if (!menuItems && !this.props.children) {
            return null;
        }

        return (
            <div className={cn("context-menu options-list", className)}>
                <div
                    onClick={disabled || !(menuItems?.length || this.props.children) ? undefined : this.show}
                    className={cn("context-menu-content", { show: this.state.visible, disabled })}
                    ref={node => this._refIcon = node}
                >
                    {this.renderTargetComponent()}
                </div>
                {this.state.visible && this.renderMenu(menuItems)}
            </div>
        );
    }
}
