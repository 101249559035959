import React, { useState, useRef } from 'react';
import MarkdownViewer from './MarkdownViewer';
import { editorChars, spaceChar, carriageReturn, dotWithSpace } from './constants';
import {
    getEditedWordPosition,
    insertTransformedWord,
    insertRangeTransformedList,
    insertNumberRangeTransformedList,
    getCurrentRow,
    splitBySpace,
} from './utils';
import IconSVG from '../../../styles/svg-icons';
import { DropDownList } from '../../controls/DropDownList';

interface Props {
    value?: string;
    maxLength?: number;
    onChange: (val: string) => void;
}

const MarkdownEditor = ({ value = '', onChange, maxLength = 1024 }: Props) => {
    const [previewMode, setPreviewMode] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState('#');
    const [editorValue, setEditorValue] = useState(value);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const handleChange = (text: string) => onChange(text.trim());

    const transformText = async (textToChange: string, selectionStart: number, selectionEnd: number) => {
        if (textToChange) {
            await setEditorValue(textToChange);
            handleChange(textToChange);
            textareaRef?.current?.setSelectionRange(selectionStart, selectionEnd);
        }
        return;
    };

    const changeInlineValue = (char: string) => {
        if (textareaRef?.current) {
            textareaRef.current.focus();
            const { selectionStart, selectionEnd } = textareaRef.current;
            let transformedText = '';
            let currentSignLength = char.length;

            if (selectionStart !== editorValue.length) {
                const withSelectionRange = selectionStart !== selectionEnd;

                if (withSelectionRange) {
                    const prevSignFirstIndex = selectionStart - currentSignLength;

                    const prevSign = editorValue.slice(prevSignFirstIndex, selectionStart);
                    if (prevSign === char) {
                        transformedText =
                            editorValue.slice(0, prevSignFirstIndex) +
                            editorValue.slice(selectionStart, selectionEnd) +
                            editorValue.slice(selectionEnd + currentSignLength, editorValue.length);
                        currentSignLength *= -1;
                    } else {
                        transformedText =
                            editorValue.slice(0, selectionStart) +
                            char +
                            editorValue.slice(selectionStart, selectionEnd) +
                            char +
                            editorValue.slice(selectionEnd, editorValue.length);
                    }
                } else {
                    const [startWordIndex, endWordIndex] = getEditedWordPosition(editorValue, selectionStart);
                    const selectedWord = editorValue.slice(startWordIndex, endWordIndex);
                    let editedWord = '';

                    if (!selectedWord.indexOf(char)) {
                        editedWord = selectedWord.slice(char.length, selectedWord.length - char.length);
                        currentSignLength *= -1;
                    } else {
                        editedWord = char + selectedWord + char;
                    }

                    transformedText = insertTransformedWord(editorValue, editedWord, startWordIndex, endWordIndex);
                }
            } else {
                transformedText = editorValue + char + char;
            }

            transformText(transformedText, selectionStart + currentSignLength, selectionEnd + currentSignLength);
        }
    };

    const changeHeadValue = (char: string) => {
        if (textareaRef?.current) {
            textareaRef.current.focus();
            const { selectionStart, selectionEnd } = textareaRef.current;
            const headSight = char + spaceChar;
            let currentSignLength = headSight.length;
            let transformedText = '';

            const withSelectionRange = selectionStart !== selectionEnd;

            if (withSelectionRange) {
                const prevSignIndex = selectionStart - currentSignLength;

                const prevSign = editorValue.slice(prevSignIndex, selectionStart);
                if (prevSign === headSight) {
                    transformedText = insertTransformedWord(editorValue, '', prevSignIndex, selectionStart);
                    currentSignLength *= -1;
                } else {
                    transformedText = insertTransformedWord(editorValue, headSight, selectionStart, selectionStart);
                }
            } else {
                const [startWordIndex, endWordIndex] = getEditedWordPosition(editorValue, selectionStart);
                const selectedWord = editorValue.slice(startWordIndex, endWordIndex);
                const isEndOfText = endWordIndex === editorValue.length;
                const editedWord = isEndOfText ? headSight : headSight + selectedWord;

                transformedText = insertTransformedWord(
                    editorValue,
                    editedWord,
                    isEndOfText ? endWordIndex : startWordIndex,
                    endWordIndex,
                );
            }

            transformText(transformedText, selectionStart + currentSignLength, selectionEnd + currentSignLength);
        }
    };

    const changeListValue = (listSign: string) => {
        if (textareaRef?.current) {
            textareaRef.current.focus();
            let { selectionStart, selectionEnd } = textareaRef.current;
            let transformedText = editorValue;
            let updatedContent = '';
            const isBulletList = listSign === editorChars.bulletList;
            const selectedContent = editorValue.slice(selectionStart, selectionEnd);

            if (selectedContent) {
                updatedContent = isBulletList
                    ? insertRangeTransformedList(selectedContent)
                    : insertNumberRangeTransformedList(selectedContent);
            } else {
                updatedContent = getCurrentRow(editorValue, listSign, selectionStart, isBulletList);
            }

            transformedText = insertTransformedWord(editorValue, updatedContent, selectionStart, selectionEnd);

            if (!selectedContent) {
                selectionStart += updatedContent.length;
            }
            selectionEnd = selectionStart + updatedContent.length;
            transformText(transformedText, selectionStart, selectedContent ? selectionEnd : selectionStart);
        }
    };

    const getListLineSign = (row: string, increment: number = 0) => {
        const splittedRowBySpace = splitBySpace(row);
        const isBulletList = splittedRowBySpace.length > 1 && splittedRowBySpace[0] === editorChars.bulletList;
        if (isBulletList) {
            return editorChars.bulletList + spaceChar;
        }
        const splittedRowByDotWithSpace = row.split(dotWithSpace);
        const firstNumberedChar = Number(splittedRowByDotWithSpace[0]);
        if (!!firstNumberedChar && splittedRowByDotWithSpace.length > 1) {
            return firstNumberedChar + increment + dotWithSpace;
        }
        return '';
    };

    const onKeyDownListHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (textareaRef?.current) {
            const { selectionStart, selectionEnd } = textareaRef.current;
            let transformedText = '';
            let activePosition = selectionStart;
            switch (e.key) {
                case 'Enter':
                    const textLines = editorValue.slice(0, selectionStart).split(carriageReturn);
                    const currentLine = textLines[textLines.length - 1];
                    const prevLineSign = getListLineSign(currentLine);
                    if (!prevLineSign) {
                        break;
                    }
                    e.preventDefault();

                    const firstWordPart = editorValue.slice(0, selectionStart);
                    const lastWordPart = editorValue.slice(selectionEnd, editorValue.length);

                    if (currentLine === prevLineSign) {
                        transformedText =
                            firstWordPart.slice(0, firstWordPart.length - prevLineSign.length) + lastWordPart;
                        activePosition = selectionStart - prevLineSign.length;
                        break;
                    }
                    const newLineSign = getListLineSign(currentLine, 1);

                    const transformedFirstWordPart = firstWordPart + carriageReturn + newLineSign;

                    transformedText = transformedFirstWordPart + lastWordPart;
                    activePosition = transformedFirstWordPart.length;

                    break;
                case 'Backspace':
                    break;
                case 'Tab':
                    e.preventDefault();
                    transformedText = editorValue + '  ';
                    break;
            }

            transformText(transformedText, activePosition, activePosition);
        }
    };

    const renderPanel = () => (
        <div className="markdown-editor-controls">
            <DropDownList
                items={editorChars.headings.map(x => ({ value: x, text: `Heading ${x.length}`, selected: x === selectedTitle }))}
                onChange={({ value }) => {
                    setSelectedTitle(value.toString());
                    changeHeadValue(value.toString());
                }}
                className="custom-drop-down-ghost"
            />
            <div className="divider" />
            <button className="btn-link" onClick={() => changeInlineValue(editorChars.bold)}>
                <IconSVG name="editor-bold" width="16" height="16" />
            </button>
            <button className="btn-link" onClick={() => changeInlineValue(editorChars.italic)}>
                <IconSVG name="editor-italic" width="16" height="16" />
            </button>
            <button className="btn-link" onClick={() => changeInlineValue(editorChars.strikethrough)}>
                <IconSVG name="editor-strikethrough" width="16" height="16" />
            </button>
            <div className="divider" />
            <button className="btn-link" onClick={() => changeListValue(editorChars.numberedList)}>
                <IconSVG name="editor-numbered-list" width="16" height="16" />
            </button>
            <button className="btn-link" onClick={() => changeListValue(editorChars.bulletList)}>
                <IconSVG name="editor-ordered-list" width="16" height="16" />
            </button>
            <div className="divider" />
        </div>
    );

    const onChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        setEditorValue(value);
        handleChange(value);
    };

    return (
        <div className="markdown-editor">
            <div className="markdown-editor-head">
                {renderPanel()}
                <button className="btn-link" onClick={() => setPreviewMode(!previewMode)}>
                    {previewMode ? (
                        <IconSVG name="edit-pencil" width="16" height="16" />
                    ) : (
                        <IconSVG name="view" width="16" height="16" />
                    )}
                </button>
            </div>
            {previewMode ? (
                <div className="markdown-editor-view">
                    <MarkdownViewer value={editorValue} />
                </div>
            ) : (
                <textarea
                    className="form-control"
                    ref={textareaRef}
                    placeholder=""
                    value={editorValue}
                    onChange={onChangeValue}
                    onKeyDown={onKeyDownListHandler}
                    maxLength={maxLength}
                />
            )}
        </div>
    );
};

export default MarkdownEditor;
