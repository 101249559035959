import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { dateRangeFilterOptions } from '../../../constants/date-range.filter';
import { useEffect } from 'react';
import { TreeSelectOption } from '../../controls/TreeSelect';
import { Action } from 'typesafe-actions';
import { AnalyticWidgetState } from '../../../types/state/Analytics';
import { AnalyticsFilterBase } from '../../../types/analytics/AnalyticsFilter';
import { ManagerAnalyticsStateBase } from '../../../types/state/ManagerAnalyticsStateBase';
import { AccessTypeMeta } from '../../../types/analytics/AccessTypeMeta';
import { SessionMeta } from '../../../types/amr-pipeline/models/AnalyticsSession';

interface AnalyticsComponentProps<T extends string> {
    companyReferenceName: string;
    accessTypes: AccessTypeMeta<T>[];
}

export interface BaseAnalyticsComponentProps<T extends string> extends AnalyticsComponentProps<T> {
    isLoaded: boolean;
    users: TreeSelectOption<string>[];
}

export type GetAction = (companyReferenceName: string, filter: AnalyticsFilterBase<unknown>) => Action;
type AnalyticsStateSelector<T extends string> = (state: AppState) => ManagerAnalyticsStateBase<T>;

export const withAnalyticsRequest = <T extends string>(
    getAction: GetAction,
    selector: AnalyticsStateSelector<T>,
    dataSelector: (state: ManagerAnalyticsStateBase<T>) => AnalyticWidgetState<SessionMeta, AnalyticsFilterBase<T>>,
) =>
    (Component: React.ComponentType<BaseAnalyticsComponentProps<T>>) =>
    ({ companyReferenceName, ...rest }: AnalyticsComponentProps<T>) => {
        const dispatch = useDispatch();
        const state = useSelector(selector);
        const { filter, data, isLoading } = dataSelector(state);
        const { isLoading: initialLoading, users } = state;

        const isDataLoaded = !!data.length && !isLoading;

        useEffect(() => {
            // Do not reload data, if Custom date option is set, and any of both
            // dates is not selected
            if (
                filter.dateOption?.key === dateRangeFilterOptions.Custom.key &&
                (!filter.date.dateFrom || !filter.date.dateTo)
            ) {
                return;
            }

            // Do not make request on initial load of Analytics tab
            if (initialLoading) {
                return;
            }

            dispatch(getAction(companyReferenceName, filter));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            dispatch,
            initialLoading,
            filter.dateOption?.key,
            companyReferenceName,
            filter.date.dateFrom,
            filter.date.dateTo,
        ]);

        return <Component companyReferenceName={companyReferenceName} isLoaded={isDataLoaded} users={users} {...rest} />;
    };
