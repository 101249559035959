import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { isNil } from 'lodash';
import { ClickOutside } from '../../ClickOutside';
import classnames from 'classnames';
import { dateTimeRangeFilterOptions as options } from '../../../../constants/date-range.filter';
import { FilterDropDown } from '../FilterDropDown';
import { ExpandClearToggle } from '../ExpandClearToggle';
import { getRangeFromDateTimeOption } from '../../../../utils/amr-pipeline.utils';
import { DateTimeFilterOption } from '../../../../types/filters/DateTimeFilterOption';
import { DateTimeRange } from '../../../../types/filters/DateTimeRange';
import classNames from 'classnames';
import { DateTimeValue } from '../../../../types/filters/DateTimeValue';
import { TimeOption } from '../../../../types/filters/TimeOption';
import { DateTimeRangeSelector } from './DateTimeRangeSelector';
import { DateTimeSelector } from './DateTimeSelector';
import { dateTimeFilterConstants } from './constants';

interface FilterDateTimeRangeProps {
    acceptableOptions?: DateTimeFilterOption[];
    defaultExpanded?: boolean;
    selectedFilterOption?: DateTimeFilterOption;
    value?: DateTimeRange;
    minSelectYear?: number;
    formatTitle?: boolean;
    onSelectedDateChange: (option: DateTimeFilterOption) => {};
    onCustomDateChange: (option: DateTimeRange) => {};
    title: string;
    onClearAll: () => void;
    isApplied?: boolean;
    disabled?: boolean;
}

const DateFormat = 'MM/DD/YYYY';
const DateTimeFormat = 'MM/DD/YYYY, hh:mm A';
const EmptyValue = { from: undefined, to: undefined } as DateTimeFilterOption;

export function FilterDateTimeRange({
    acceptableOptions = options.rollerDeadline(),
    defaultExpanded = false,
    selectedFilterOption,
    value,
    onSelectedDateChange,
    onCustomDateChange,
    formatTitle = true,
    title,
    onClearAll,
    minSelectYear = 2010,
    isApplied = false,
    disabled = false,
}: FilterDateTimeRangeProps) {
    function getFilterOptions() {
        return acceptableOptions
            .map((option) => {
                const dateRange = getRangeFromDateTimeOption(option);

                if (!dateRange) {
                    return option;
                }

                const { from, to } = dateRange;
                const fromTitle = from.date.format(DateFormat);
                const toTitle = to.date.format(DateFormat);

                return {
                    ...option,
                    selectedTitle: `${fromTitle} - ${toTitle}`,
                    from: {
                        ...from,
                        date: from.date.toDate(),
                    },
                    to: {
                        ...to,
                        date: to.date.toDate(),
                    },
                };
            });
    }

    const dateOptions: DateTimeFilterOption[] = getFilterOptions();
    const [expanded, setExpanded] = useState(defaultExpanded);

    const [internalValue, setInternalValue] = useState(value || EmptyValue);

    const [hasError, setHasError] = useState(false);

    const [selectedOption, setSelectedOption] = useState(selectedFilterOption?.key);

    useEffect(() => {
        setSelectedOption(selectedFilterOption?.key);
    }, [selectedFilterOption]);

    useEffect(() => {
        setInternalValue(value || EmptyValue);
    }, [value]);

    useEffect(() => {
        if (!internalValue.from && !internalValue.to && selectedFilterOption) {
            return handleClearAll();
        }

        if (!expanded) {
            setHasError(false);
            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    const isFilterSelected = () => {
        if (!selectedFilterOption) {
            return false;
        }

        return !isNil(internalValue?.from) && !isNil(internalValue?.to);
    };

    const handleOptionChange = (option: DateTimeFilterOption) => {
        setSelectedOption(option.key);
        setInternalValue(option);
        onSelectedDateChange(option);
        setHasError(false);
    };

    const handleClearAll = () => {
        onClearAll();
        setSelectedOption(undefined);
        setInternalValue(EmptyValue);
        onCustomDateChange(EmptyValue);

        setHasError(false);
    };

    const formatDateTimeValue = (value: DateTimeValue) => {
        return value.timeOption === TimeOption.AnyTime
            ? moment(value.date).format(`${DateFormat} [${dateTimeFilterConstants.anyTimeTitle}]`)
            : moment(value.date).format(DateTimeFormat);
    }

    const handleDateRangeChange = (from?: DateTimeValue, to?: DateTimeValue) => {
        setInternalValue({ from, to });
        onCustomDateChange({ from, to });
        setHasError(false);
    };

    function renderOptions() {
        return dateOptions.map((option) => (
            <li
                key={option.key}
                onClick={() => handleOptionChange(option)}
                className={classNames({
                    selected: option.key === selectedFilterOption?.key
                })}
            >
                {option.title}
            </li>
        ));
    }

    function renderTitle() {
        const { from, to } = internalValue;

        if (!from || !to) {
            return formatTitle ? `${title}: All` : title;
        }

        const formattedFrom = formatDateTimeValue(from);
        const formattedTo = formatDateTimeValue(to);

        if (selectedFilterOption?.formatTitle) {
            return (
                <>
                    {`${title}: `}
                    {selectedFilterOption!.formatTitle(formattedFrom, formattedTo)}
                </>
            );
        }

        return selectedOption === options.DateWithTimePeriod.key
            ? `${title}: ${formattedFrom} - ${formattedTo}`
            : `${title}: ${formattedFrom}`;
    }

    const renderSelectors = () => {
        switch (selectedOption) {
            case options.DateWithTime.key:
                return (
                    <DateTimeSelector
                        value={internalValue.from}
                        onChange={handleDateRangeChange}
                        onError={() => setHasError(true)}
                    />
                );
            case options.DateWithTimePeriod.key:
                return (
                    <DateTimeRangeSelector
                        valueFrom={internalValue.from}
                        valueTo={internalValue.to}
                        minSelectYear={minSelectYear}
                        onChange={handleDateRangeChange}
                        onError={() => setHasError(true)}
                    />
                );
            default:
                return null;
        }
    }

    const isSelected = isFilterSelected();

    const toggleExpand = disabled ? undefined : () => setExpanded(!expanded);

    return (
        <ClickOutside
            className="control-filter-select"
            onClick={() => setExpanded(false)}
        >
            <div
                className={classnames("control-filter-select-btn", {
                    expanded,
                    error: hasError,
                    applied: isApplied && isSelected,
                    changed: isSelected && !isApplied,
                    disabled,
                })}
            >
                <div onClick={toggleExpand}>
                    {renderTitle()}
                </div>
                <ExpandClearToggle
                    filterSelected={isSelected}
                    onExpand={toggleExpand}
                    onClear={handleClearAll}
                />
            </div>
            {expanded && (
                <FilterDropDown
                    className={classnames(
                        {
                            "control-filter-content-date":
                                dateOptions.length > 1,
                        },
                        {
                            "control-filter-content-datepicker":
                                selectedOption === options.DateWithTimePeriod.key ||
                                selectedOption === options.DateWithTime.key
                        },
                        {
                            "control-filter-content-single-date":
                                selectedOption === options.DateWithTime.key,
                        }
                    )}
                    expanded={expanded}
                    value={value}
                >
                    <div className="control-filter-range-list">
                        <button
                            className="btn-link"
                            disabled={!isSelected}
                            onClick={handleClearAll}
                        >
                            Reset to default
                        </button>

                        {dateOptions.length > 1 && (
                            <ul className="control-filter-date-list">
                                {renderOptions()}
                            </ul>
                        )}
                    </div>
                    {renderSelectors()}
                </FilterDropDown>
            )}
        </ClickOutside>
    );
}
