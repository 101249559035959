import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { CustomDatePicker } from "./CustomDatePicker";
import { DateRange, Matcher } from "react-day-picker";
import dateTimeUtils from "../../../utils/dateTime.utils";

interface CustomDateRangePickerProps {
    onDayClick: (range: DateRange) => void;
    from?: Date;
    to?: Date;
    disabledDays?: Matcher;
}

export function CustomDateRangePicker({
    from,
    to,
    onDayClick,
    disabledDays,
}: CustomDateRangePickerProps) {
    const [currentDateFrom, setCurrentDateFrom] = useState<Date | null>(null);
    const [currentDateTo, setCurrentDateTo] = useState<Date | null>(null);
    const [hoverDate, setHoverDate] = useState<Date>();
    const [clickCount, setClickCount] = useState(0);
    const prevToDate = useRef(to);
    const prevFromDate = useRef(from);

    useEffect(() => {
        setCurrentDateFrom(moment(from || undefined).toDate());
        const isAddNextMonth =
            to && from && to.getMonth() === from.getMonth() &&
            to.getFullYear() === from.getFullYear();
        const momentToDate = moment(to || from || undefined);

        if (isAddNextMonth || !to) {
            momentToDate.add(1, "month");
        }
        if (
            prevToDate.current !== to ||
            prevFromDate.current !== from ||
            !currentDateTo
        ) {
            setCurrentDateTo(momentToDate.toDate());
        }

        prevToDate.current = to;
        prevFromDate.current = from;
    }, [from, to, currentDateTo]);

    const handleDayClick = (day: Date) => {
        const range: Matcher = { from, to };
        const newClickCount = clickCount + 1;
        const isDayEarlier =
            range.from && dateTimeUtils.isDayBefore(day, range.from);

        setClickCount(newClickCount);

        if (from && to) {
            // if click is odd - set begin of range
            // if click is even - set end of range
            if (newClickCount % 2 !== 0) {
                range.from = day;
                range.to =
                    range.to && dateTimeUtils.isDayBefore(day, range.to)
                        ? range.to
                        : undefined;

                // if new day is later than current end of range - reset it;
                if (range.to && !dateTimeUtils.isDayBefore(day, range.to)) {
                    setClickCount(1);
                }
            } else {
                range.from = !isDayEarlier ? range.from : day;
                range.to = !isDayEarlier ? day : undefined;

                // if new day is earlier than current start of range - reset it;
                if (isDayEarlier) {
                    setClickCount(1);
                }
            }
        } else {
            if (range.from) {
                range.from = isDayEarlier ? day : range.from;
                range.to = !isDayEarlier ? day : undefined;

                if (isDayEarlier) {
                    setClickCount(1);
                }
            } else {
                range.from = day;
                range.to = undefined;

                setClickCount(1);
            }
        }

        onDayClick(range);
    };

    const handleDayMouseEnter = (day?: Date) => {
        if (clickCount % 2 !== 0) {
            day && from && dateTimeUtils.isDayAfter(day, from)
                ? setHoverDate(day)
                : setHoverDate(undefined);
        } else {
            day && from && dateTimeUtils.isDayBefore(day, from)
                ? setHoverDate(day)
                : setHoverDate(undefined);
        }
    };

    return (
        <div className="flex-row align-items-flex-start custom-view-pickers">
            {currentDateFrom && currentDateTo && (
                <>
                    <CustomDatePicker
                        hoverDate={hoverDate}
                        onDayHover={handleDayMouseEnter}
                        from={from}
                        to={to}
                        date={currentDateFrom}
                        onDayClick={handleDayClick}
                        disabledDays={disabledDays}
                    />
                    <CustomDatePicker
                        hoverDate={hoverDate}
                        onDayHover={handleDayMouseEnter}
                        from={from}
                        to={to}
                        date={currentDateTo}
                        onDayClick={handleDayClick}
                        disabledDays={disabledDays}
                    />
                </>
            )}
        </div>
    );
}
