import React, { Component } from 'react';
import { connect } from 'react-redux';
import { contactsSaveActions as actions } from '../../actions';
import { Grid } from '../grid';
import { routes, uploadStatus } from '../../constants';
import { history } from '../../history';
import { RouteLeavingGuard } from '../routing';
import { contactsComparer } from '../../utils';
import { Breadcrumbs } from '../controls/breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../controls/breadcrumbs/BreadcrumbsItem';
import { DownloadTemplateButton } from '../common/download-template/DownloadTemplateButton';
import { DeleteRowsWithErrorButton } from '../grid/DeleteRowsWithErrorButton';

class ContactsGrid extends Component {
    render = () => {
        const { dataItems, isSaved, isEdit, isGridUploading, dispatch } = this.props;
        const contacts = dataItems.filter(i => !i.draft);
        const canSave = !isSaved && (isEdit || contacts.length);

        return (
            <div className="container address-book">
                <div className="sub-header">
                    <div className="sub-header-row type01 flex-row">
                        <Breadcrumbs>
                            <BreadcrumbsItem route={routes.dealers} text='Dealers' />
                        </Breadcrumbs>
                        <h1>{isEdit ? 'Edit All' : 'Import Contacts'}</h1>
                        <div className="flex-item-right controls">
                            <DeleteRowsWithErrorButton />
                            <DownloadTemplateButton templateType="ContactsXlsxTemplate" />
                            <button
                                className="btn btn-ghost"
                                disabled={isGridUploading}
                                type="button"
                                onClick={() => history.push(routes.dealers)}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-main"
                                disabled={!canSave || isGridUploading}
                                type="button"
                                onClick={() => dispatch(actions.save())}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                <Grid
                    onDataItemsUpload={file => dispatch(actions.uploadContacts(file))}
                    onDataItemsPaste={clipboardText => dispatch(actions.handleClipboardData(clipboardText))}
                    onRowDeleted={contact => dispatch(actions.deleteContact(contact.id))}
                    onFileSelected={file => dispatch(actions.uploadContacts(file))}
                    helpPopoverTitle="You can add your contacts in the following ways"
                />
                <RouteLeavingGuard
                    navigate={pathname => history.push(pathname)}
                    shouldBlockNavigation={this.handleBlockNavigation}
                />
            </div>
        );
    };

    handleBlockNavigation = () => {
        const { isSaved, isEdit, initialContacts, dataItems } = this.props;
        const contacts = dataItems.filter(i => !i.draft);

        if(isSaved || (!contacts && initialContacts.length === 0)) {
            return false;
        }

        if (!isEdit) {
            return contacts.length > 0;
        }

        return !contactsComparer.compareMultiple(initialContacts, contacts);
    }
}

const mapStateToProps = ({ contactsSave, grid }) => ({
    dataItems: grid.dataItems,
    initialContacts: contactsSave.initialContacts,
    isSaved: contactsSave.isSaved,
    isEdit: contactsSave.isEdit,
    isGridUploading: grid.upload.status === uploadStatus.uploading
});

const connectedContactsGrid = connect(mapStateToProps)(ContactsGrid);
export { connectedContactsGrid as ContactsGrid };
