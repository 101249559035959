import React, { useState } from 'react';
import { Tooltip, TooltipTriggerType } from '../common/Tooltip';
import cn from 'classnames';
import IconSVG from '../../styles/svg-icons';
import classNames from 'classnames';

interface Props {
    title: string;
    className?: string;
    wrapperClassName?: string;
    onOpen?: () => void;
    onClose?: () => void;
    label?: React.ReactElement;
    actionButton: React.ReactElement;
    children: React.ReactNode;
    disabledActionButtonOnOpen?: boolean;
    showCloseButton?: boolean;
    defaultVisibility?: boolean;
    placement?: string;
    hideOnAnyAction?: boolean;
}

export const Popover: React.FC<Props> = ({ title, className, wrapperClassName, children, hideOnAnyAction = true, onOpen, onClose, actionButton, label, disabledActionButtonOnOpen, showCloseButton, defaultVisibility, placement = 'bottomLeft' }) => {
    const [visible, setVisible] = useState<boolean>(!!defaultVisibility);

    const handleClose = () => {
        setVisible(false);
        onClose && onClose();
    };

    const handleVisibilityChange = (visible: boolean) => {
        setVisible(visible);

        if (visible) {
            onOpen && onOpen();
        } else {
            onClose && onClose();
        }
    }

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Tooltip
                hideOnAnyAction={hideOnAnyAction}
                className={cn(wrapperClassName)}
                overlayClassName={cn('kp-popover', className)}
                placement={placement}
                onVisibleChange={handleVisibilityChange}
                trigger={TooltipTriggerType.Click}
                visible={visible}
                overlay={() =>
                    <div className="popover">
                        <div className="popover-header">
                            <h1>{title} {label && label}</h1>
                            <button className="btn-close" onClick={handleClose}>
                                <IconSVG name="close" width={16} height={16} />
                            </button>
                        </div>
                        <div className="popover-body">
                            <div className="popover-content">
                                <>{children}</>
                            </div>
                        </div>
                        {showCloseButton && (
                            <button className="btn btn-ghost" onClick={handleClose}>Close</button>
                        )}
                    </div>
                }
            >
                <span className={classNames({ 'disabled-action-button': visible && disabledActionButtonOnOpen })}>
                    {actionButton}
                </span>
            </Tooltip>
        </div>
    );
};

Popover.defaultProps = {
    onOpen: () => {},
    onClose: () => {},
    className: '',
    title: '',
};



