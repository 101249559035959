import { Table } from '../../../bidding/common/table';
import { createTransactionViewHistoryColumns } from './transactionViewHistory.columns';
import { TransactionViewHistoryClientsActivity } from '../../../../types/amr-pipeline/models/TransactionViewHistoryClientsActivity';
import { SORT } from '../../../../constants';
import { EmptyPlaceholder } from '../..';
import { ActionBlocker } from '../../../access/ActionBlocker';
import { BlockedFeatureContent, SubscribeLink } from '../../../access/BlockedFeatureText';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { WithHiddenItems } from '../../../../types/amr-pipeline/models/WithHiddenItems';

interface TransactionViewHistoryTableProps {
    data: WithHiddenItems<TransactionViewHistoryClientsActivity>;
    requiredFeature?: SubscriptionFeature;
}

export const TransactionViewHistoryTable = ({ data, requiredFeature }: TransactionViewHistoryTableProps) => {
    if (!data.items.length) {
        return <EmptyPlaceholder text="There are no transactions yet" />;
    }

    return (
        <>
            <Table
                className="data-list-striped data-list-overflow-auto transaction-view-history-table"
                dataItems={data.items}
                columns={createTransactionViewHistoryColumns(requiredFeature)}
                defaultSortBy="lastUpdated"
                defaultSortByDirection={SORT.DESC}
            />
            <ActionBlocker feature={requiredFeature} noTooltip>
                {blocked => blocked && !!data.numberOfHiddenItems && (
                    <BlockedFeatureContent
                        inline
                        text={<><SubscribeLink /> to see {data.numberOfHiddenItems} more deal(s).</>}
                    />
                )}
            </ActionBlocker>
        </>
    );
};
