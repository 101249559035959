import React from 'react';
import { ExcludeListItemType } from './ExcludeListItemType';
import { ExcludeListSection } from './ExcludeListSection';

interface Props {
    editMode: boolean;
    excludeTitle: string;
    includeTitle: string;
    includeColumnTitle: string;
    excludeColumnTitle: string;
    includeList: ExcludeListItemType;
    excludeList: ExcludeListItemType;
    onInclude: (key: number) => void;
    onExclude: (key: number) => void;
    onIncludeAll: () => void;
    onExcludeAll: () => void;
    disabled?: boolean;
}

export const ExcludeList: React.FC<Props> = (
    {
        includeList,
        excludeList,
        editMode,
        includeTitle,
        excludeTitle,
        onIncludeAll,
        onExcludeAll,
        includeColumnTitle,
        excludeColumnTitle,
        onInclude,
        onExclude,
        disabled
    }
) => {
    return (
        <div className="flex-row align-items-flex-start manage-dealers-list">
            <ExcludeListSection
                title={includeTitle}
                exclude={false}
                items={includeList}
                editMode={editMode}
                onToggleAll={onExcludeAll}
                columnTitle={includeColumnTitle}
                onItemClick={onExclude}
                disabled={disabled}
            />
            <ExcludeListSection
                title={excludeTitle}
                exclude={true}
                items={excludeList}
                editMode={editMode}
                onToggleAll={onIncludeAll}
                columnTitle={excludeColumnTitle}
                onItemClick={onInclude}
                disabled={disabled}
            />
        </div>
    )
};
