import { BaseTextFieldProps, InputField } from './InputField';
import { MarkdownEditor } from '../../common/markdown-editor';

export const MarkdownField = ({ maxLength, ...props }: BaseTextFieldProps) => {
    return (
        <InputField {...props} renderElement={(formik, field, meta) => (
            <div className="data-item-col">
                <MarkdownEditor maxLength={maxLength} value={field.value || ''} onChange={value => field.onChange(props.name)(value)} />
                {meta.touched && meta.error && <div className="form-error">{meta.error}</div>}
            </div>
        )} />
    );
}
