import moment from 'moment';
import { DateTimeValue } from '../../../../types/filters/DateTimeValue';
import { TimeOption } from '../../../../types/filters/TimeOption';

export const replicateTimeToDate = (date: Date, previousValue?: Date) => {
    if (!previousValue) {
        return date;
    }

    const previousParsedTime = moment(previousValue)

    return moment(date).set({
        hour: previousParsedTime.get('hour'),
        minute: previousParsedTime.get('minute'),
        second: previousParsedTime.get('second'),
    }).toDate();
}

export const alignDayTime = (value?: DateTimeValue, endOfDay = false) => {
    if (!value) {
        return value;
    }

    const { timeOption } = value;
    let date: Date | undefined = undefined;

    if (value.date) {
        const exactTime = moment(value.date).startOf('minute').toDate();
        const edgeTime = endOfDay
            ? moment(value.date).endOf('day').toDate()
            : moment(value.date).startOf('day').toDate();

        date = timeOption === TimeOption.AnyTime ? edgeTime : exactTime
    }

    return {
        timeOption,
        date
    } as DateTimeValue;
}
