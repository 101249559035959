import { values } from "lodash";
import { useMemo } from "react";
import { DailyTradingLimit } from "../../../types/bid-as-dealer/SettlementAgentClientAgreement";

export const useTotalDailyTradingLimit = (dailyTradingLimit: DailyTradingLimit) => {
    return useMemo(() => {
        if (dailyTradingLimit.common) {
            return dailyTradingLimit.common
        }
        const total = values(dailyTradingLimit.byRating).reduce<number>((result, current) => {
            result += Number(current)
            return Number(result)
        }, 0)
        return Number(total)
    }, [dailyTradingLimit])
}