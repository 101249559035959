import { isEqual } from "lodash";
import { DateFilterOption } from "../../../../types/filters/DateFilterOption";
import { DateRange } from "../../../../types/filters/DateRange";
import { DateRangeOption } from "../../../../types/user-config/UserConfigFilter";
import DateRangeSelector from "./DateRangeSelector";

interface BwicDateFilterOption {
    key: number;
    title: string;
}

interface DateSelectorProps {
    title: string;
    customClassName?: string;
    disabled: boolean;
    onReset?: () => void;
    acceptedOptions?: BwicDateFilterOption[];
    selectedDateOption: BwicDateFilterOption;
    selectedDefault?: BwicDateFilterOption;
    customDateRange?: DateRange;
    onCustomDateChange: (dateRange: DateRange) => void
    onSelectedDateChange: (selectedOption: DateFilterOption) => void
    disabledDays?: {
        after: Date;
    }
}

interface DateRangeFilterState {
    selectedDateOption: DateFilterOption,
    customDateRange?: DateRange | DateRangeOption
}

interface DateRangeFilterProps extends DateSelectorProps {
    lastAppliedFilter: DateRangeFilterState;
    initialFilter: DateRangeFilterState;
    filter: DateRangeFilterState;
}

const isDateFilterApplied = (lastAppliedFilter: DateRangeFilterState, filter: DateRangeFilterState) => {
    return lastAppliedFilter.selectedDateOption.key === filter.selectedDateOption.key
        && isEqual(lastAppliedFilter.customDateRange, filter.customDateRange);
}

const isDateFilterChanged = (initialFilter: DateRangeFilterState, filter: DateRangeFilterState) => {
    return initialFilter.selectedDateOption.key !== filter.selectedDateOption.key
        || !isEqual(initialFilter.customDateRange, filter.customDateRange);
}

export const DateRangeFilter = ({ lastAppliedFilter, initialFilter, filter, ...props }: DateRangeFilterProps) => (
    <DateRangeSelector
        isApplied={
            isDateFilterApplied(lastAppliedFilter, filter)
            && isDateFilterChanged(initialFilter, filter)
        }
        restyled={true}
        {...props} />
)