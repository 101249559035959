import React, { useMemo, useState } from "react";
import { flatten, kebabCase, sum, uniq } from 'lodash';
import { EmptyPlaceholder, ClickOutside, SearchInput } from "../common";
import { Checkbox } from "./Checkbox";
import classNames from 'classnames';
import { FilterDropDown } from '../common/filters/FilterDropDown';
import { DropDownButton } from './DropDownButton';

export interface TreeSelectOption<T> {
    text: string;
    value: T;
    meta?: any;
    children?: TreeSelectOption<T>[];
}

interface TreeSelectProps<T> {
    defaultExpanded?: boolean;
    options: TreeSelectOption<T>[];
    value: T[];
    title: string | React.ReactNode;
    withSearch?: boolean;
    className?: string;
    disabled?: boolean;
    renderMeta?: (option: TreeSelectOption<T>) => React.ReactNode;
    titleFormatter?: (items: TreeSelectOption<T>[]) => React.ReactNode;
    onChange: (items: T[]) => void;
}

function getLowestLevelChildren<T>(item: TreeSelectOption<T>): T[] {
    if (!item.children || !item.children.length) {
        return [item.value];
    }

    const children = item.children.map(x => getLowestLevelChildren(x));

    return flatten(children);
}

function filterOptions<T>(searchTerm: string, options: TreeSelectOption<T>[]) {
    if (!searchTerm.length) {
        return options;
    }

    const searchTermLowerCase = searchTerm.trim().toLowerCase();
    return options.filter((option) => {
        const topLevelItemMatches = option.text.toLowerCase().includes(searchTermLowerCase);
        const lowLevelItemsMatch = (option.children || []).some(child =>
            child.text.toLocaleLowerCase().includes(searchTermLowerCase)
        );

        return topLevelItemMatches || lowLevelItemsMatch;
    });
}

export function TreeSelect<T extends string | number>({
    options,
    value,
    title,
    withSearch,
    className,
    disabled = false,
    renderMeta,
    titleFormatter,
    onChange,
}: TreeSelectProps<T>) {
    const [expanded, setExpanded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const selectedOptions = flatten(options.map(option => (option.children || []).filter(childOption => value.includes(childOption.value))));

    const childrenCount = useMemo(() => sum(options.map(x => getLowestLevelChildren(x).length)), [options]);
    const filteredOptions = useMemo(() => filterOptions(searchTerm, options), [searchTerm, options]);

    function handleSearchTermChange(value: string) {
        setSearchTerm(value);
    }

    function handleSelectAll() {
        onChange(flatten(options.map(getLowestLevelChildren)));
    }

    function handleResetToDefault() {
        onChange([]);
    }

    function handleClearSearch() {
        setSearchTerm('');
    }

    function handleItemClick(item: TreeSelectOption<T>) {
        // Top-level item clicked
        if (item.children && item.children.length) {
            const lowestLevelValues = getLowestLevelChildren(item);

            // All were checked — uncheck all
            if (lowestLevelValues.every(item => value.includes(item))) {
                return onChange(value.filter(item => !lowestLevelValues.includes(item)));
            }

            // All were unchecked, or some checked, some unchecked - check all
            onChange(uniq([...value, ...lowestLevelValues]));
        } else { // Low-level item clicked
            // Item is unchecked
            if (value.includes(item.value)) {
                return onChange(value.filter(x => x !== item.value));
            }

            onChange([...value, item.value]);
        }
    }

    function renderTitle() {
        if (titleFormatter) {
            return titleFormatter(selectedOptions);
        }

        const optionsCount = selectedOptions.length;

        if (optionsCount === 1) {
            const [firstOption] = selectedOptions;
            return (<>{title}:&nbsp;<span className="selected-data">{firstOption.text}</span></>);
        }

        if (!optionsCount || optionsCount === childrenCount) {
            return `${title}: All`;
        }

        return (<>{title}: {optionsCount} selected</>);
    }

    function renderLookup() {
        if (!filteredOptions.length) {
            return <EmptyPlaceholder textView={true} />;
        }

        return (
            <ul className="control-filter-select-list">
                {filteredOptions.map((option) => {
                    const allChildrenSelected = (option.children || []).every(x => value.includes(x.value));
                    const someChildrenSelected = (option.children || []).some(x => value.includes(x.value));

                    return (
                        <li key={option.value} className="control-filter-item-tree">
                            <Checkbox
                                label={option.text}
                                checked={allChildrenSelected}
                                onChange={() => handleItemClick(option)}
                                partially={!allChildrenSelected && someChildrenSelected}
                            />
                            <ul className="control-filter-list-tree">
                                {option.children && option.children.map((childOption) => (
                                    <li key={childOption.value} className="control-filter-sub-item-tree">
                                        <Checkbox
                                            label={childOption.text}
                                            checked={value.includes(childOption.value)}
                                            onChange={() => handleItemClick(childOption)}
                                        />
                                        {renderMeta && renderMeta(childOption)}
                                    </li>
                                ))}
                            </ul>
                        </li>
                    );
                })}
            </ul>
        );
    }

    function renderSelectAllClear() {
        if (!filteredOptions.length) {
            return null;
        }

        if (!selectedOptions.length) {
            return (
                <button
                    className="btn-link"
                    onClick={handleSelectAll}
                >
                    Select All
                </button>
            );
        }

        return (
            <button
                className="btn-link"
                onClick={handleResetToDefault}
            >
                Reset to default
            </button>
        );
    }

    function renderSelectedItems() {
        if (!selectedOptions.length) {
            return null;
        }

        return (
            <React.Fragment>
                <ul className="control-filter-select-list-selected">
                    {options.map(option => {
                        const selectedOptions = (option.children || []).filter(x => value.includes(x.value));

                        if (!selectedOptions.length) {
                            return null;
                        }

                        return (
                            <li key={option.value} className="control-filter-item-tree">
                                <Checkbox
                                    label={option.text}
                                    checked={selectedOptions.length === option.children?.length}
                                    onChange={() => handleItemClick(option)}
                                    partially={selectedOptions.length !== option.children?.length}
                                />
                                <ul className="control-filter-list-tree">
                                    {selectedOptions.map((childOption) => (
                                        <li key={childOption.value} className="control-filter-sub-item-tree">
                                            <Checkbox
                                                label={childOption.text}
                                                checked={value.includes(childOption.value)}
                                                onChange={() => handleItemClick(childOption)}
                                            />
                                            {renderMeta && renderMeta(childOption)}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        );
                    })}
                </ul>
                <hr />
            </React.Fragment>
        );
    }

    return (
        <ClickOutside
            className={classNames("control-filter-select", `control-filter-${kebabCase(className)}`)}
            onClick={() => setExpanded(false)}
        >
            <DropDownButton
                expanded={expanded}
                title={renderTitle()}
                focused={false}
                disabled={disabled}
                onClick={(expanded) => setExpanded(expanded)}
                className="custom-drop-down-ghost"
            >
                <FilterDropDown
                    className={classNames(
                        "control-filter-content-select",
                        { "filter-select-and-search": withSearch }
                    )}
                    expanded={expanded}
                    value={options}
                >
                    {withSearch ? (
                        <>
                            <SearchInput
                                placeholder="Start typing..."
                                value={searchTerm}
                                onChange={handleSearchTermChange}
                                onClear={handleClearSearch}
                            />
                            {renderSelectAllClear()}
                            {renderSelectedItems()}
                        </>
                    ) : (
                        renderSelectAllClear()
                    )}

                    {renderLookup()}
                </FilterDropDown>
            </DropDownButton>
        </ClickOutside>
    );
}
