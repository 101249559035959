import {
    editorChars,
    spaceChar,
    carriageReturn,
    dotWithSpace,
} from "./constants";

const splitByLines = (text: string) => text.split(/\r?\n/);
const isCharSpace = (text: string) => text.match(/\s+/);
const isNumberedRow = (row: string) => {
    const firstElement = splitBySpace(row)[0];
    const withNumberSign =
        firstElement[firstElement.length - 1] === editorChars.numberedList;
    const numberString = Number(splitBySpace(row)[0].slice(0, -1));
    return withNumberSign && numberString;
};

export const splitBySpace = (text: string) => text.split(/\s+/);

export const getEditedWordPosition = (text: string, selectedIndex: number) => {
    let wordStart = 0;
    let wordEnd = 0;

    for (
        let i = selectedIndex > 0 ? selectedIndex - 1 : selectedIndex;
        !wordStart || i;
        i--
    ) {
        if (!i || i === text.length || isCharSpace(text[i])) {
            wordStart = i ? i + 1 : i;
            break;
        }
    }

    for (let i = selectedIndex; !wordEnd || i === text.length; i++) {
        if (i === text.length || isCharSpace(text[i])) {
            wordEnd = i;
            break;
        }
    }

    return [wordStart, wordEnd];
};

export const insertTransformedWord = (
    content: string,
    wordToInsert: string,
    startIndexToInsert: number,
    endIndexToInsert: number
) =>
    content.slice(0, startIndexToInsert) +
    wordToInsert +
    content.slice(endIndexToInsert, content.length);

export const getCurrentRow = (
    content: string,
    listSign: string,
    pointerPosition: number,
    isBullet: boolean
) => {
    const [, endPosition] = getEditedWordPosition(content, pointerPosition);
    const rows = splitByLines(content.slice(0, endPosition));
    const currentRow = rows[rows.length - 1];
    const withSign = isNumberedRow(currentRow);
    if (withSign) {
        return "";
    }
    return (isBullet ? listSign + spaceChar : 1 + dotWithSpace) + currentRow;
};

export const insertRangeTransformedList = (content: string) => {
    const rows = splitByLines(content);
    const isSignPresent = rows.every(
        (el) => splitBySpace(el)[0] === editorChars.bulletList
    );

    if (isSignPresent) {
        return rows
            .map((r) => r.slice(r.indexOf(spaceChar) + 1, r.length))
            .join(carriageReturn);
    }
    const signToAdd = editorChars.bulletList + spaceChar;
    return rows.map((r) => signToAdd + r).join(carriageReturn);
};

export const insertNumberRangeTransformedList = (content: string) => {
    const rows = splitByLines(content);
    const isSignPresent = rows.every((r) => isNumberedRow(r));

    if (isSignPresent) {
        return rows
            .map((r) => r.slice(r.indexOf(spaceChar) + 1, r.length))
            .join(carriageReturn);
    }

    return rows.map((r, i) => i + 1 + dotWithSpace + r).join(carriageReturn);
};
