import { Fragment } from "react/jsx-runtime";
import { TimeSpan as TimeSpanType } from "../../types/primitives/TimeSpan";
import { dateTimeUtils } from "../../utils";
import { Pluralize } from "../common/Pluralize";

interface Props {
    value: TimeSpanType;
}
export function TimeSpan({ value }: Props) {
    const time = dateTimeUtils.parseTimeSpan(value);

    if (!time) return null;

    const hours = time.hours ? <Pluralize count={time.hours} singular="hour" /> : null
    const minutes = time.minutes ? <Pluralize count={time.minutes} singular="minute" /> : null
    const seconds = time.seconds ? <Pluralize count={time.seconds} singular="second" /> : null

    return (
        <>
            {[hours, minutes, seconds]
                .filter(x => x !== null)
                .map((e, i) => (<Fragment key={i}>{i > 0 && " "}{e}</Fragment>))}
        </>
    );
}