import React, { useState } from 'react';
import cn from 'classnames';
import { arrayUtils } from '../../../utils/array.utils';
import IconSVG from "../../../styles/svg-icons";

interface Props {
    currentDate: Date;
    onYearClick: (year: number) => void;
    minYear: number;
}

enum YearViewMode {
    Year,
    YearRange
}

export const CustomDatePickerYearView: React.FC<Props> = ({ currentDate, onYearClick, minYear }) => {
    const addYearsFromCurrentDate = 15;
    const nowYear = new Date().getFullYear();
    const currentYear = new Date(currentDate).getFullYear();
    const maxYear = nowYear + addYearsFromCurrentDate;

    const getDefaultRange = () => {
        const currentDateYear = new Date(currentDate).getFullYear();
        const itemsPerPage = addYearsFromCurrentDate + 1;

        const yearsDifference = currentDateYear - minYear;
        const page = Math.floor(yearsDifference / itemsPerPage);
        const from = itemsPerPage * page + minYear;
        const to = Math.min(from + addYearsFromCurrentDate, maxYear)

        return [from, to];
    };

    const [visibleYearRange, setLastVisibleYearRange] = useState(getDefaultRange());
    const [viewMode, setViewMode] = useState<YearViewMode>(YearViewMode.Year);

    const [visibleYearFrom, visibleYearTo] = visibleYearRange;

    const visibleYearList = arrayUtils.arrayFromRange(visibleYearRange[0], visibleYearRange[1]);

    const getYearRangeList = () => {
        const itemNumber = Math.ceil((maxYear - minYear) / addYearsFromCurrentDate);
        return arrayUtils
            .arrayFromRange(0, itemNumber - 1)
            .map(index => {
                const from = minYear + index * (addYearsFromCurrentDate + 1);
                const to = Math.min(from + addYearsFromCurrentDate, maxYear);
                return [from, to]
            });
    };

    const handleSetYearRange = (range: number[]) => {
        setLastVisibleYearRange(range);
        setViewMode(YearViewMode.Year);
    };

    const handleNextYearRange = () => {
        const from = visibleYearTo + 1;
        const to = Math.min(from + addYearsFromCurrentDate, maxYear);
        setLastVisibleYearRange([from, to]);
    };

    const handlePrevYearRange = () => {
        const from = Math.max(visibleYearFrom - 1 - addYearsFromCurrentDate, minYear);
        const to = from + addYearsFromCurrentDate;
        setLastVisibleYearRange([from, to]);
    };

    return (
        <div className="rdp">
            <div className="rdp-caption">
                <div className="rdp-caption_label">
                    <button className="btn btn-link" onClick={() => setViewMode(YearViewMode.YearRange)}>
                        {visibleYearList[0]} - {visibleYearList[visibleYearList.length - 1]}
                    </button>
                </div>
                <div className="rdp-nav">
                    <button
                        className={
                            cn(
                                "rdp-nav_button rdp-nav_button_previous",
                                { 'rdp-nav_button_disabled': visibleYearList.findIndex((year) => year === minYear) !== -1 }
                            )
                        }
                        onClick={handlePrevYearRange}
                    >
                        <IconSVG name="icon-expand" width={16} height={16} />
                    </button>
                    <button
                        className={
                            cn(
                                "rdp-nav_button rdp-nav_button_next",
                                { 'rdp-nav_button_disabled': visibleYearList.findIndex((year) => year === maxYear) !== -1 }
                            )
                        }
                        onClick={handleNextYearRange}
                    >
                        <IconSVG name="icon-expand" width={16} height={16} />
                    </button>
                </div>
            </div>
            <div className="rdp-list rdp-list_years">
                {
                    viewMode === YearViewMode.YearRange && getYearRangeList().map(range => {
                        const [visibleYearFrom, visibleYearTo] = visibleYearRange;
                        const [from, to] = range;
                        return (
                            <div
                                key={`${from}_${to}`}
                                className={cn('rdp-list_item rdp-list_item_range', { 'rdp-list_item_current': from === visibleYearFrom && to === visibleYearTo })}
                            >
                                <button className="rdp-button rdp-list_button" onClick={() => handleSetYearRange(range)}>
                                    {from} - {to}
                                </button>
                            </div>
                        )
                    })
                }
                {
                    viewMode === YearViewMode.Year && visibleYearList.map((year) => (
                        <div
                            key={year}
                            className={cn('rdp-list_item', { 'rdp-list_item_current': currentYear === year })}
                        >
                            <button className="rdp-button rdp-list_button" onClick={() => onYearClick(year)}>
                                {year}
                            </button>
                        </div>
                    ))
                }
            </div>
        </div>
    )
};
