import * as React from 'react';
import classNames from 'classnames';

interface Props {
    value?: string;
    className?: string;
    backgroundClassName?: string;
    msAnimationTime?: number;
    msAnimationStep?: number;
    disabled?: boolean;
    children?: React.ReactNode;
}

export function AnimatedValueUpdate({
    value,
    className,
    backgroundClassName,
    msAnimationTime = 7000,
    msAnimationStep = 200,
    disabled = false,
    children }: Props) {
    const refInterval = React.useRef<any>(0);
    const [timeLeft, setTimeLeft] = React.useState(0);
    const opacity = refInterval.current
        ? Math.max(timeLeft, 0) / msAnimationTime
        : 0;

    const stopAnimation = () => {
        if (refInterval.current) {
            clearInterval(refInterval.current);
            refInterval.current = 0;
        }
    }
    React.useEffect(() => {
        if (refInterval.current && timeLeft <= 0) {
            stopAnimation();
        }
        // eslint-disable-next-line
    }, [timeLeft])

    React.useEffect(() => {
        if (!disabled) {
            stopAnimation();
            setTimeLeft(msAnimationTime);
            refInterval.current = setInterval(() => {
                setTimeLeft(timeLeft => timeLeft - msAnimationStep);
            }, msAnimationStep);
        }
        // eslint-disable-next-line
    }, [value, disabled]);

    const animationClassName = classNames('animated-value-update-bg', backgroundClassName, {
        'animated-value-update-default-bg': !backgroundClassName,
    });

    return (
        <div data-testid="animated-update-value-test-id" className={classNames("animated-value-update", className)}>
            <div className={animationClassName} style={{ opacity }} />
            <div className="animated-value-update-content">{children || value}</div>
        </div>
    );
}
