import { BaseTextFieldProps, InputField } from './InputField';
import { JoditEditor } from '../../common/JoditEditor';

export const EditorField = ({ maxLength, ...props }: BaseTextFieldProps) => {
    return (
        <InputField {...props} renderElement={(formik, field, meta) => (
            <div className="data-item-col">
                <JoditEditor
                    maxLength={maxLength}
                    uploadBaseUrl={`${process.env.REACT_APP_AMR_ENDPOINT}`}
                    uploadPath={'/api/bwic/news/image'}
                    value={field.value || ''}
                    onChange={value => formik.setFieldValue(field.name, value)}
                    placeholder="Add Event Description"
                />
                {meta.touched && meta.error && <div className="form-error">{meta.error}</div>}
            </div>
        )} />
    );
}
