import classNames from 'classnames';
import { BaseTextFieldProps, InputField } from './InputField';

export const TextareaField = ({ maxLength, ...props }: BaseTextFieldProps) => {
    return (
        <InputField {...props} renderElement={(formik, field, meta) => (
            <div className="data-item-col">
                <textarea
                    className={classNames('form-control', { 'is-invalid': meta.touched && meta.error && formik.submitCount })}
                    {...field}
                    maxLength={maxLength}
                    value={field.value || ''}
                />
                {meta.touched && meta.error && <div className="form-error">{meta.error}</div>}
            </div>
        )} />
    );
}
