import React from 'react';
import classNames from 'classnames';

export function PopupFooter({ className = '', children }) {
    return (
        <div
            className={classNames('modal-footer', className)}
            onClick={e => e.stopPropagation()}
        >
            {children}
        </div>
    )
}

