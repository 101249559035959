import React from 'react';
import classNames from 'classnames';

export function SecurityListContainer({ children, className, onClick }) {
    return (
        <div className={classNames(className)} onClick={onClick}>
            {children}
        </div>
    )
}
