import { constants } from "../../../constants";
import { RequestState } from "../../../constants/request-state";
import { CompanyMember } from "../../../types/company/CompanyMember";
import { isRequestSuccess } from "../../../utils";
import { Table } from "../../bidding/common/table";
import { ColumnBuilder } from "../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { IColumnDefinition } from "../../bidding/common/table/types/ColumnDefinition";
import { OnHoverTooltip } from "../OnHoverTooltip";
import { Preloader } from "../Preloader";

interface SettlementAgentContactsListProps {
    users: CompanyMember[];
    requestStateGetUsers: RequestState;
}

export const SettlementAgentContactsList = ({ users, requestStateGetUsers }: SettlementAgentContactsListProps) => {
    const getTableColumns = () => {
        const columnDefinitions: IColumnDefinition<CompanyMember>[] = [
            {
                columnKey: 'name',
                renderColumnHeaderContent: () => 'Full Name',
                renderColumnContent: ({ fullName }) =>
                    fullName ? (
                        <OnHoverTooltip overlay={fullName}>
                            <span>{fullName}</span>
                        </OnHoverTooltip>
                    ) : constants.emptyPlaceholder,
                className: 'data-list-cell-lg',
            },
            {
                columnKey: 'email',
                renderColumnHeaderContent: () => 'Email',
                renderColumnContent: ({ email }) =>
                    email ? (
                        <OnHoverTooltip overlay={email}>
                            <a href={`mailto:${email}`}>{email}</a>
                        </OnHoverTooltip>
                    ) : constants.emptyPlaceholder,
                className: 'data-list-cell-xl',
            },
            {
                columnKey: 'phone',
                renderColumnHeaderContent: () => 'Phone',
                renderColumnContent: ({ phone }) =>
                    phone || constants.emptyPlaceholder,
                className: 'data-list-cell-sm',
            },
        ];
        return columnDefinitions.map((c) => new ColumnBuilder(c));
    }

    const getSortedUsers = () => [...users].sort((a, b) => 
        (a.fullName ?? '').localeCompare(b.fullName)
    )

    return (
        <Preloader inProgress={!isRequestSuccess(requestStateGetUsers)}>
            <Table dataItems={getSortedUsers()} columns={getTableColumns()} className="data-list-striped" />
        </Preloader>
    )
}
