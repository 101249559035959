import moment, { Moment } from 'moment';
import { Plot, mainConfig } from '../../charts';
import { chartUtils } from '../../../../utils';
import { arrayUtils } from '../../../../utils/array.utils';
import { constants } from '../../../../constants';
import { DashboardNoSelectedFilters } from '../../../dashboard/DashboardNoSelectedFilters';
import { amrChartUtils, Range } from '../../../../utils/amr-chart.utils';
import { DownloadedPresentations } from '../../../../types/amr-pipeline/models/DownloadedPresentations';
import { downloadedPresentationsChart } from '../../../../constants/amr-pipeline/downloadedPresentationsChart';

interface DownloadedPresentationsChartProps {
    divId?: string;
    data: DownloadedPresentations[];
}

const DefaultRangeWeeks = 10;
const AlignmentDays = 3;

export function DownloadedPresentationsChart({ divId, data }: DownloadedPresentationsChartProps) {
    // isoWeek — week that starts from Monday
    const chartEndMoment = moment().startOf('isoWeek');

    const {
        chartLayout,
        tickColor,
        tickFont,
        barColor,
        zeroLineColor,
        hoverlabel,
    } = downloadedPresentationsChart;

    const getEdgeDimensionValues = (downloadedPresentations: DownloadedPresentations[]): Range<Moment> => {
        const minDateRow = arrayUtils.min(downloadedPresentations, x => x.weekStartDate)!;
        const tenWeeksAgo = chartEndMoment.clone()
            .subtract(DefaultRangeWeeks, 'weeks')
            .startOf('isoWeek');

        const min = tenWeeksAgo.isBefore(minDateRow?.weekStartDate) ? tenWeeksAgo : moment(minDateRow?.weekStartDate);

        return {
            min,
            max: chartEndMoment,
        };
    };

    const getChartRange = () => {
        // Adding alignment days to 'center' chart
        const fromMoment = chartEndMoment
            .clone()
            .subtract(DefaultRangeWeeks, 'weeks')
            .startOf('isoWeek')
            .add(AlignmentDays, 'days');

        // Subtracting alignment days to 'center' chart
        const toMoment = chartEndMoment
            .clone()
            .endOf('isoWeek')
            .subtract(AlignmentDays, 'days');

        return [
            fromMoment.toDate(),
            toMoment.toDate()
        ];
    };

    const getSliderRange = ({ min, max }: Range<Moment>) => {
        if (!min || !max) {
            return [];
        }

        // Set same range for slider as for chart,
        // only if date range is less or equal than 10 weeks
        return moment.duration(max.diff(min)).asWeeks() > DefaultRangeWeeks
            ? []
            : getChartRange();
    };

    const getHoverInfoText = (downloadedPresentation: DownloadedPresentations) => {
        return `Downloaded Presentations: ${downloadedPresentation.numberOfAccess}`;
    };

    const getChartData = ({ min, max } : Range<Moment>) => {
        // Fill in gaps in dates with blank items, to preserve integrity of chart data
        const fillGaps = amrChartUtils.gapsFiller(
            data,
            row => moment(row.weekStartDate),
            weekStartDate => ({
                weekStartDate: weekStartDate.toDate(),
                numberOfAccess: 0
            })
        );

        const dataWithFilledGaps = (min && max) ? fillGaps({ min, max }, 'week') : data;

        return dataWithFilledGaps.reduce((acc: any, row: DownloadedPresentations) => {
            const { x, y, hovertext } = acc;

            return {
                ...acc,
                hovertext: [...hovertext, getHoverInfoText(row)],
                x: [...x, row.weekStartDate],
                y: [...y, row.numberOfAccess],
            };
        }, {
            x: [],
            y: [],
            hovertext: [],
            type: 'bar',
            hoverinfo: 'text',
            marker: {
                color: barColor,
            },
        });
    };

    const getLayout = (dateRange: Range<Moment>) => {
        const xAxisRange = getChartRange();
        const rangeSliderRange = getSliderRange(dateRange);
        const [chartStartDate] = xAxisRange;

        // Set tick0 with subtracted alignment period,
        // so ticks will match start of each week
        const tick0 = moment(chartStartDate)
            .subtract(AlignmentDays, 'days')
            .toDate();

        return {
            ...chartLayout,
            showlegend: false,
            autosize: true,
            hovermode: 'closest',
            hoverlabel,
            xaxis: {
                tick0,
                tickcolor: tickColor,
                type: 'date',
                tickfont: tickFont,
                tickformat: '%m/%d/%y',
                fixedrange: true,
                dtick: constants.dayMs * 7,
                range: xAxisRange,
                rangeslider: {
                    range: rangeSliderRange,
                    bgcolor: 'rgba(79, 123, 156, 0.2)',
                },
            },
            yaxis: {
                showgrid: true,
                showtickprefix: 'all',
                fixedrange: true,
                gridcolor: tickColor,
                ticks: 'inside',
                tickcolor: tickColor,
                tickfont: tickFont,
                zerolinecolor: zeroLineColor,
            },
        }
    };

    const renderChart = () => {
        const edgeDimensions = getEdgeDimensionValues(data);
        const chartData = getChartData(edgeDimensions);
        const layout = getLayout(edgeDimensions);

        return (
            <Plot
                onHover={chartUtils.setCursor('pointer')}
                onUnhover={chartUtils.setCursor('crosshair')}
                divId={divId}
                data={[chartData]}
                layout={layout}
                config={mainConfig}
            />
        );
    };

    return data.length ? renderChart() : <DashboardNoSelectedFilters />;
}

DownloadedPresentationsChart.defaultProps = {
    divId: 'downloaded-presentations-div-id'
};
