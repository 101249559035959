import { useMemo } from 'react';
import { Table } from '../../bidding/common/table';
import { DashboardNoSelectedFilters } from '../../dashboard/DashboardNoSelectedFilters';
import { ActionBlocker } from '../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { BlockedFeatureContent, SubscribeLink } from '../../access/BlockedFeatureText';
import { AnalyticsFilterBase } from '../../../types/analytics/AnalyticsFilter';
import { ProfileView } from '../../../types/amr-pipeline/models/AnalyticsSession';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';

interface ProfileViewHistoryTableProps<T extends string> {
    companyReferenceName: string;
    data: ProfileView<T>[];
    filter: AnalyticsFilterBase<T>;
    numberOfHiddenItems: number;
    columns: ColumnBuilder<ProfileView<T>>[];
    requiredFeature?: SubscriptionFeature;
}

export const ProfileViewHistoryTable = <T extends string>({
    companyReferenceName,
    data,
    filter,
    numberOfHiddenItems,
    columns,
    requiredFeature,
}: ProfileViewHistoryTableProps<T>) => {
    const filteredClientsActivity = useMemo(
        () =>
        data.filter(row => {
                if (filter.users.length && !filter.users.includes(row.userEmail)) {
                    return false;
                }

                if (filter.tabs.length && !filter.tabs.some(accessType => row.views[accessType] > 0)) {
                    return false;
                }

                return true;
            }),
        [data, filter.users, filter.tabs],
    );

    return filteredClientsActivity.length ? (
        <>
            <Table
                className="data-list-striped data-list-overflow-auto profile-view-history-table"
                createSecurityCustomArgs={() => ({ companyReferenceName })}
                dataItems={filteredClientsActivity}
                columns={columns}
            />
            <ActionBlocker feature={requiredFeature} noTooltip>
                {blocked => blocked && !!numberOfHiddenItems && (
                    <BlockedFeatureContent
                        inline
                        text={<><SubscribeLink /> to see {numberOfHiddenItems} more profile view(s).</>}
                    />
                )}
            </ActionBlocker>
        </>
    ) : (
        <DashboardNoSelectedFilters />
    );
};
