import { useEffect, useState } from "react";
import classNames from "classnames";
import { ClickOutside } from "../common";
import IconSVG from "../../styles/svg-icons";
import { EmptyPlaceholder } from "../common";

interface Item {
    key: string;
    text: string;
}

interface Props {
    items: Item[];
    selectedItem?: string;
    onChange: (key: string) => void;
    dropdownClass?: string;
    disabled?: boolean;
    placeholder?: string;
    lookup?: boolean;
    selectSearchIfNotFound?: boolean;
    error?: boolean;
    clearable?: boolean;
}

export function Select({
    items,
    selectedItem,
    onChange,
    dropdownClass,
    disabled = false,
    placeholder = "Select",
    lookup = false,
    selectSearchIfNotFound = true,
    error = false,
    clearable = false,
}: Props) {
    const selected = items.find((i) => i.key === selectedItem);
    const [expanded, setExpanded] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        setSearchValue(selected?.text || "");
    }, [selected?.text]);

    if (!items) {
        return null;
    }

    const handleChange = (item: Item) => {
        setExpanded(false);
        setSearchValue(item.text);
        onChange(item.key);
    };

    const handleClear = (e: React.MouseEvent) => {
        e.stopPropagation();
        setExpanded(false);
        setSearchValue("");
        onChange("");
    };

    const onSearchValueChange = (value: string) => {
        setSearchValue(value);

        if (selectSearchIfNotFound) {
            onChange(value);
        }
    };

    const filteredItems =
        lookup && searchValue !== selected?.text
            ? items.filter(
                  (item) =>
                      item.text
                          ?.toLowerCase()
                          .indexOf(searchValue.toLowerCase()) !== -1
              )
            : items;

    const options = filteredItems.map((item, index) => {
        return (
            <div
                className={classNames("component-dropdown-list-item", {
                    selected: searchValue === item.text,
                })}
                key={index}
                onClick={() => handleChange(item)}
            >
                {item.text}
            </div>
        );
    });

    return (
        <ClickOutside
            className={classNames("component-dropdown", dropdownClass)}
            onClick={() => setExpanded(false)}
        >
            <div
                className={classNames("component-dropdown-toggle", {
                    "is-disabled": disabled,
                    "component-dropdown-toggle-selected": expanded,
                    error,
                })}
                onClick={() => (disabled ? undefined : setExpanded(!expanded))}
            >
                {lookup ? (
                    <input
                        value={searchValue}
                        onChange={(e) => onSearchValueChange(e.target.value)}
                        readOnly={!expanded}
                        autoComplete="turnoff"
                        placeholder={placeholder}
                    />
                ) : (
                    <>
                        {selected?.text ? (
                            <span>{selected?.text}</span>
                        ) : (
                            <span className="placeholder">{placeholder}</span>
                        )}
                    </>

                )}
                {clearable && selected && (
                    <span
                        onClick={handleClear}
                        className="btn-link flex-none btn-clear"
                    >
                        <IconSVG name="close" width="16" height="16" />
                    </span>
                )}
                <IconSVG
                    name="corner-arrow"
                    width="16"
                    height="16"
                    className={classNames("icon-component-dropdown", {
                        "is-open": expanded,
                    })}
                />
            </div>
            {expanded && (
                <div className="component-dropdown-list">
                    {filteredItems.length ? (
                        options
                    ) : (
                        <EmptyPlaceholder textView text="No items found" />
                    )}
                </div>
            )}
        </ClickOutside>
    );
}
