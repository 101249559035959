import moment from "moment";
import dateTimeUtils from "../../../utils/dateTime.utils";
import { constants } from "../../../constants";

interface Props {
    goodUntilUtc: Date;
}

export function GoodUntilTime({ goodUntilUtc }: Props) {
    const goodUntil = moment(goodUntilUtc).add(30, 'minute')
    return <>{dateTimeUtils.utcToLocalString(goodUntil, constants.timeFormat)}</>
}