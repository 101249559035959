import React from 'react';
import cn from 'classnames';
import { RadioButton } from './RadioButton';

export function RadioButtonGroup({ className = '', options, value, disabled = false, onChange, name = '' }) {
    const handleChange = value => !disabled && onChange && onChange(value);

    return (
        <div className={cn("radio-group", className)}>
            {options.map(option => (
                <RadioButton
                    name={name}
                    key={option.value}
                    label={option.label}
                    value={option.value}
                    checked={option.value === value}
                    onChange={() => handleChange(option.value)}
                    disabled={disabled}
                />
            ))}
        </div>
    )
}

RadioButtonGroup.defaultProps = {
    options: [],
    value: '',
    onChange: () => false,
}
