import { useState } from 'react';
import moment, { Moment } from 'moment';
import { ClickOutside } from '../../ClickOutside';
import { RadioButtonGroup } from '../../../controls';
import TimePicker from 'rc-time-picker';
import { dateTimeFilterConstants } from './constants';
import { constants } from '../../../../constants';
import classNames from 'classnames';
import { DateTimeValue } from '../../../../types/filters/DateTimeValue';
import { TimeOption } from '../../../../types/filters/TimeOption';

interface TimeInputProps {
    date?: Date;
    timeOption?: TimeOption;
    label?: string;
    onChange: (value: DateTimeValue) => void;
}

const OptionsList = [
    { label: "Any time", value: TimeOption.AnyTime },
    { label: "Select time", value: TimeOption.CustomTime },
];

export function TimeInput({
    onChange,
    date,
    timeOption,
    label,
}: TimeInputProps) {
    const selectedDate = date ? moment(date) : moment();

    const [isOpen, setIsOpen] = useState(false);

    const customTimeMode = timeOption === TimeOption.CustomTime;

    const inputValue = customTimeMode
        ? moment(selectedDate).format(constants.timeFormat)
        : dateTimeFilterConstants.anyTimeTitle;

    const handleModeChange = (timeOption: TimeOption) => {
        onChange({
            date: timeOption === TimeOption.CustomTime
                ? selectedDate.startOf('day').toDate()
                : selectedDate.toDate(),
            timeOption,
        });
    };

    const handleTimeChange = (date: Moment) => {
        onChange({
            date: date.toDate(),
            timeOption: TimeOption.CustomTime,
        });
    };

    return (
        <div className="form-item">
            <label className="form-label">{label}</label>
            <ClickOutside
                className={classNames("time-picker-outside", {
                    "is-open": isOpen && customTimeMode,
                })}
                onClick={() => setIsOpen(false)}
            >
                <input
                    className="form-control"
                    onClick={() => setIsOpen(true)}
                    readOnly
                    type="text"
                    value={inputValue}
                    placeholder="Placeholder"
                />
                {isOpen && (
                    <div className="time-picker-wrap">
                        <div>
                            <RadioButtonGroup
                                options={OptionsList}
                                value={timeOption || TimeOption.AnyTime}
                                onChange={handleModeChange}
                                disabled={false}
                            />
                        </div>
                        {customTimeMode && (
                            <div
                                className="control-time-picker"
                                id="pop-cngt-t"
                            >
                                <TimePicker
                                    popupClassName="time-picker-filter-popup"
                                    //@ts-ignore
                                    getPopupContainer={() =>
                                        document.getElementById("pop-cngt-t")
                                    }
                                    showSecond={false}
                                    minuteStep={15}
                                    use12Hours
                                    format={constants.timeFormat}
                                    focusOnOpen={true}
                                    value={selectedDate}
                                    onChange={handleTimeChange}
                                    open
                                />
                            </div>
                        )}
                    </div>
                )}
            </ClickOutside>
        </div>
    );
}
