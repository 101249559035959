import React, { useMemo } from 'react';
import { keys, size } from 'lodash';
import classNames from 'classnames';
import { ExcludeListItemType } from './ExcludeListItemType';
import { ExcludeListItem } from './ExcludeListItem';

interface Props {
    items: ExcludeListItemType
    exclude: boolean;
    editMode: boolean;
    title: string;
    onToggleAll: (ids: number[]) => void;
    onItemClick: (key: number) => void;
    columnTitle: string;
    disabled?: boolean;
}

export const ExcludeListSection: React.FC<Props> = (
    {
        items,
        exclude,
        editMode,
        title,
        onToggleAll,
        columnTitle,
        onItemClick,
        disabled
    }
) => {
    const itemsEmpty = !size(items);
    const renderList = useMemo(() => keys(items)
        .sort((a, b) => items[Number(a)].localeCompare(items[Number(b)]))
        .map(key => (
            <ExcludeListItem
                key={key}
                onClick={() => onItemClick(Number(key))}
                editMode={editMode}
                exclude={exclude}
                disabled={disabled}
            >
                {items[Number(key)]}
            </ExcludeListItem>
        )), [items, onItemClick, editMode, disabled, exclude]);

    const renderTitle = () => {
        return (
            <div className="manage-dealers-column-title">
                <h2>{title}</h2>
                {
                    editMode && !!size(items) && (
                        <button
                            className="btn-link flex-item-right"
                            onClick={() => onToggleAll(keys(onToggleAll).map(key => Number(key)))}
                            disabled={disabled}
                        >
                            {exclude ? 'Include All' : 'Exclude All'}
                        </button>
                    )
                }
            </div>
        )
    };

    const renderContent = () => {
        return (
            <>
                {
                    (editMode || itemsEmpty) &&
                    <h5 className={classNames('text-regular manage-dealers-column-subtitle', { 'text-warm-grey': itemsEmpty })}>
                        {columnTitle}
                    </h5>
                }
                <ul className="manage-dealers-column-content">
                    {renderList}
                </ul>
            </>
        )
    };

    return (
        <div className="manage-dealers-column">
            {renderTitle()}
            {renderContent()}
        </div>
    )
};
