import React from 'react';
import classNames from 'classnames';
import { CompanySlim } from '../../types/company/CompanySlim';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { companyDetailsActions } from '../../actions/company-details.actions';
import { constants } from '../../constants';

interface CompanyDetailsButtonProps {
    rowKey: number;
    title: string;
    company: CompanySlim;
    isBrokerDealer?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

export const CompanyDetailsButton: React.FC<CompanyDetailsButtonProps> = ({ company, title, isBrokerDealer = false, rowKey, disabled = false, onClick }) => {
    const dispatch = useDispatch();
    const isPanelVisible: boolean = useSelector((state: AppState) => state.companyDetails.isPanelVisible);
    const currentCompanyId: number = useSelector((state: AppState) => state.companyDetails.currentCompanyId);
    const currentRowKey: number = useSelector((state: AppState) => state.companyDetails.rowKey);

    if (!company) {
        return null;
    }

    const isActive = isPanelVisible && currentCompanyId === company.id && currentRowKey === rowKey;

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if(disabled) {
            return;
        }
        if (typeof onClick === 'function') {
            onClick()
        }
        if (currentCompanyId === company.id && currentRowKey === rowKey) {
            dispatch(companyDetailsActions.hide());
        } else {
            dispatch(companyDetailsActions.show(company, isBrokerDealer, rowKey));
        }
    };

    return (
        <>
            {title
                ?
                <button
                    className = {classNames('pseudo-link text-ellipsis', { 'is-active': isActive })}
                    onClick = {handleClick}
                >{title}</button>
                :
                constants.emptyPlaceholder
            }
        </>
    )
};
