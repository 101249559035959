import * as React from 'react';
import classNames from 'classnames';
import { ClickOutside } from '../common';
import { isIE } from '../../utils/isIE.utils';
import { Checkbox } from './Checkbox';

export interface MultiSelectDataItem {
    text: string;
    value: string | number;
    disabled?: boolean;
    selected?: boolean;
}

interface Props {
    name?: string
    required?: boolean
    className?: string
    placeholder?: string
    disabled?: boolean
    sourceItems: MultiSelectDataItem[]
    onItemSelect: (item: MultiSelectDataItem, selected: boolean) => void
    generateSelectedText?: (selectedItems: MultiSelectDataItem[]) => React.ReactNode
    showAddButton: boolean,
    onResetAll?: (e: React.MouseEvent) => void ,
    onSelectAll?: (e: React.MouseEvent) => void,
    showResetButton?: boolean,
    showSelectAllButton?: boolean,
    selectButtonText?:  string,
    resetButtonText?: string,
}

export function MultiSelect(
    {
        name,
        required = false,
        placeholder = '',
        disabled = false,
        sourceItems,
        onItemSelect,
        showAddButton = true,
        generateSelectedText,
        className = '',
        onResetAll,
        onSelectAll,
        showResetButton = false,
        showSelectAllButton = false,
        selectButtonText = 'Select All',
        resetButtonText = 'Reset'
    }: Props) {
    const [expanded, setExpanded] = React.useState(false);
    const selectedItems = sourceItems.filter(i => i.selected);

    const getText = (): React.ReactNode => {
        if (selectedItems.length) {
            return generateSelectedText == null
                ? selectedItems.map(s => s.text).join(', ')
                : generateSelectedText(selectedItems);
        }

        return <span className="placeholder">{placeholder}</span>;
    };

    const renderItem = (item: MultiSelectDataItem) => {
        return (
            <li key={item.value}>
                <Checkbox
                    onChange={e => onItemSelect(item, e.target.checked)}
                    checked={!!item.selected}
                    disabled={!!item.disabled}
                    label={item.text}
                />
            </li>
        );
    };

    const multiselectCssClasses = classNames(className, {
        'form-control form-select multiselect': true,
        show: expanded,
        disabled: disabled,
        selected: sourceItems.filter(i => i.selected).length > 0,
        multiselected: sourceItems.filter(i => i.selected).length > 1
    });

    const renderResetSelectButtons = () => {
        if (showSelectAllButton) {
            return (
                <li className="reset-select">
                    <button className="btn btn-link" onClick={selectedItems.length > 0 ? onResetAll : onSelectAll}>
                        {selectedItems.length > 0 ? resetButtonText : selectButtonText}
                    </button>
                </li>
            );
        }

        if (showResetButton) {
            return (
                <li className="reset-select">
                    <button disabled={!selectedItems.length} className="btn btn-link" onClick={onResetAll}>
                        {resetButtonText}
                    </button>
                </li>
            );
        }
    }

    return (
        <div>
            <input
                type="text"
                name={name}
                required={required}
                value={selectedItems.length || ''}
                onChange={() => true}
                className="invisible"
            />
            <div
                className={multiselectCssClasses}
                onClick={disabled ? undefined : () => setExpanded(true)}
            >
                {getText()}
            </div>
            {
                expanded &&
                <ClickOutside
                    className="multiselect-list"
                    onClick={() => isIE() ? setTimeout(() => setExpanded(false), 0) : setExpanded(false)}
                >
                    <ul>
                        {renderResetSelectButtons()}
                        {sourceItems.map(i => renderItem(i))}
                    </ul>
                    {
                        showAddButton && (
                            <div className="button-wrapper">
                                <button className="btn btn-main btn-sm" onClick={() => setExpanded(false)}>Add</button>
                            </div>)
                    }
                </ClickOutside>
            }
        </div>
    );
}
