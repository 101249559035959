import React from 'react';
import classNames from 'classnames';
import { FormError } from './FormError';

export function FormControlWrapper({ children, row, error }) {
    return (
        <div className={classNames('form-control-wrapper', { 'flex-row': row })}>
            {children}
            <FormError message={error} />
        </div>
    );
}
