import * as React from 'react';
import cn from 'classnames';

interface Props {
    ref?: React.RefObject<HTMLDivElement>;
    expanded: boolean;
    children: React.ReactNode;
}

export const CollapsibleListItemContent = React.forwardRef(({ expanded, children }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    return (
        <div ref={ref} className={cn("component-collapsible-list-item-content", { expanded })}>
            {!!expanded && children}
        </div>
    );
});
