import React from 'react';
import classNames from 'classnames';
import { SORT } from '../../../constants';
import IconSVG from '../../../styles/svg-icons';

export function SecurityListHeaderColumn({
    children,
    className = '',
    sorted,
    onClick,
    onMouseOver,
    onMouseLeave,
    sortDirection = SORT.ASC
}) {
    const handleOnMouseOver = (e) => {
        if (onMouseOver) {
            onMouseOver(e)
        }
    };
    const handleOnMouseLeave = (e) => {
        if (onMouseLeave) {
            onMouseLeave(e)
        }
    };
    const handleOnClick = (e) => {
        if (onClick) {
            onClick(e)
        }
    };
    return (
        <div
            className={classNames('data-list-cell', className, {
                'sort-column': sorted !== undefined,
                'sorted': sorted,
                'desc': sortDirection === SORT.DESC,
            })}
            onClick={handleOnClick}
            onMouseOver={handleOnMouseOver}
            onMouseLeave={handleOnMouseLeave}
        >
            {children}
            {sorted !== undefined && <IconSVG name="corner-arrow" width="16" height="16" />}
        </div>
    );
}

SecurityListHeaderColumn.defaultProps = {
    onClick: null,
    onMouseOver: null,
    onMouseLeave: null,
    sorted: undefined,
    children: null
}

