import React from 'react';
import classNames from "classnames";
import IconSVG from '../../styles/svg-icons';

export function Chip({ text, selected, disabled, onClick, onDelete }) {
    const handleClick = e => {
        e.stopPropagation();
        if (!disabled && onClick) {
            onClick(e);
        }
    }

    const handleDelete = e => {
        e.stopPropagation();
        if (!disabled && onDelete) {
            onDelete(e);
        }
    }

    return (
        <div className="chip">
            <div
                className={classNames('chip-content', { selected, disabled })}
                onClick={handleClick}
            >
                <div className="chip-text">{text}</div>
                <button type="button" className="chip-button" onClick={handleDelete}>
                    <IconSVG name="close" width={16} height={16} />
                </button>
            </div>
        </div>
    );
}
