import * as React from 'react';
import MaskedInput from "react-text-mask";
import { createNumberMask } from 'text-mask-addons';
import { formatUtils, numericUtils } from "../../utils";

const decimalDelimiter = ".";

export interface NumberMaskOptions {
    prefix: string;
    suffix: string;
    includeThousandsSeparator: boolean;
    thousandsSeparatorSymbol: string;
    allowDecimal: boolean;
    decimalSymbol: string;
    decimalLimit: number;
    requireDecimal: boolean;
    allowNegative: boolean;
    allowLeadingZeroes: boolean;
    integerLimit: number | null;
}

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    integerLimit?: number | null;
    decimalLimit: number; 
    onValueChange?: (value?: string) => void;
}

export function DecimalMaskedInput({ integerLimit, decimalLimit, placeholder, value, onValueChange, ...rest }: Props) {
    const mask = createNumberMask({
        prefix: '',
        allowDecimal: true,
        includeThousandsSeparator: false,
        integerLimit,
        decimalLimit
    });

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { selectionStart, selectionEnd, value } = e.currentTarget;

        if (typeof selectionStart === "number" && typeof selectionEnd === "number") {
            const decimalDelimiterIndex = value.indexOf(decimalDelimiter);
            const integerPart = value.slice(0, decimalDelimiterIndex);
            const decimalPart = value.slice(decimalDelimiterIndex, value.length);

            const formatValue = (intValue: string) => formatUtils.formatDecimal(Number(intValue + decimalPart))

            const changeSelect = (pointerPosition: number) => e.currentTarget.setSelectionRange(pointerPosition, pointerPosition);

            switch (e.key) {
                case "Backspace":
                    if (!Number(value)) {
                        return onValueChange?.("");
                    }
                    const backspaceSelectionIndex = selectionEnd - 1;
                    if (backspaceSelectionIndex === decimalDelimiterIndex) {
                        e.preventDefault();
                        changeSelect(backspaceSelectionIndex);
                    } else if (!backspaceSelectionIndex && integerPart.length === 1) {
                        e.preventDefault();
                        onValueChange?.(formatValue("0"));
                    }
                    break;
                case decimalDelimiter:
                    e.preventDefault();
                    if (!selectionStart && !value) {
                        e.currentTarget.value = "0.00";
                        onValueChange?.(formatValue("0"));
                        changeSelect(2);
                    } else {
                        changeSelect(decimalDelimiterIndex + 1);
                    }

                    break;
                case "Delete":
                case "Del":
                    if (!Number(value)) {
                        return onValueChange?.("");
                    }
                    if (selectionStart === decimalDelimiterIndex) {
                        e.preventDefault();
                        changeSelect(selectionStart + 1);
                    }
                    break;
                default:
                    const selection = selectionEnd - selectionStart;
                    const selectAll = Boolean(selection) && selection === value.length;
                    const integerPartSelected = Boolean(selection) && selection === integerPart.length
                    const stopEvent = !selectionStart && !integerPartSelected && !selectAll && e.key === "0" && +value;
                    if (stopEvent) {
                        e.preventDefault();
                        return;
                    }

                    if (numericUtils.isNumber(e.key)) {
                        if (selectionStart === 1 && !+integerPart) {
                            e.preventDefault();
                            onValueChange?.(formatValue(e.key));
                        }
                        if (
                            selectionStart > decimalDelimiterIndex &&
                            e.key === value[selectionStart] &&
                            (e.key === value[selectionStart + 1] ||
                                value[selectionStart + 1] === undefined)
                        ) {
                            changeSelect(selectionStart + 1);
                        }
                    }
                    break;
            }
        }
    };

    return <MaskedInput
        {...rest}
        mask={mask}
        value={numericUtils.isNumber(value) ? formatUtils.formatDecimal(value, decimalLimit) : ''}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
    />
}
