import * as React from 'react';
import classNames from 'classnames';

interface Props {
    children: React.ReactNode;
    className: string;
    expanded: boolean;
    value?: any;
}

export function FilterDropDown({children, className, expanded, value }: Props) {
    const filterDropDownRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (expanded && filterDropDownRef != null && filterDropDownRef.current) {
            const bounding = filterDropDownRef.current.getBoundingClientRect();

            if (bounding.left < 0) {
                // Left side is out of viewoprt
                filterDropDownRef.current.style.right = "auto";
                filterDropDownRef.current.style.left = "0px";
                delete filterDropDownRef.current.dataset.turnRight;
            }

            if (bounding.right > (window.innerWidth || document.documentElement.clientWidth)) {
                // Right side is out of viewport
                filterDropDownRef.current.style.right = "0px";
                filterDropDownRef.current.style.left = "auto";
                filterDropDownRef.current.dataset.turnRight = "";
            }
        }
    }, [expanded, className, value]);

    return (
        <div className={
            classNames("control-filter-content", className)}
            ref={filterDropDownRef}
            onClick={(e) => e.stopPropagation()}
        >
            {children}
        </div>
    );
}
