import cn from 'classnames';
import { BaseFieldProps, InputField } from './InputField';
import { FieldHelperProps, FieldInputProps, FieldMetaProps, FormikContextType } from 'formik';
import { constants } from '../../../constants';
import { Select } from '../../controls/Select';

interface SelectInputProps<T> extends BaseFieldProps {
    withEmptyValue?: boolean;
    placeholder?: string;
    values: T[];
    optionLabelProp: keyof T;
    optionKeyProp: keyof T;
    className?: string;
    onSelect?: (
        formik: FormikContextType<any>,
        field: FieldInputProps<any>,
        meta: FieldMetaProps<any>,
        helpers: FieldHelperProps<any>
    ) => void;
}

export const SelectField = <T,>({
    withEmptyValue = false,
    placeholder,
    values,
    optionLabelProp,
    optionKeyProp,
    className,
    disabled,
    onSelect,
    ...props
}: SelectInputProps<T>) => {
    const getListItems = () => {
        const items = values.map((value: T) => {
            const key = value[optionKeyProp] as unknown as string;
            const text = value[optionLabelProp] as unknown as string;

            return { key, text };
        });

        if (withEmptyValue) {
            items.unshift({
                key: '',
                text: constants.emptyPlaceholder,
            });
        }

        return items;
    };

    return (
        <InputField
            {...props}
            renderElement={(formik, field, meta, helpers) => (
                <div className="data-item-col">
                    <Select
                        dropdownClass={cn("form-control", className, {
                            "is-invalid": meta.touched && meta.error,
                            disabled
                        })}
                        items={getListItems()}
                        onChange={(value) => {
                            helpers.setValue(value || undefined);

                            if (onSelect) {
                                onSelect(formik, field, meta, helpers);
                            }
                        }}
                        selectedItem={field.value || ''}
                        placeholder={placeholder}
                        disabled={disabled}
                    />

                    {meta.touched && meta.error && (
                        <div className="form-error">{meta.error}</div>
                    )}
                </div>
            )}
        />
    );
};
