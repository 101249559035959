import React, { Component } from 'react';
import classNames from 'classnames';
import { constants } from '../../constants';
import { ClickOutside } from '../common';
import IconSVG from '../../styles/svg-icons';

export default class AutoCompleteInput extends Component {
    state = {
        expanded: false,
        inputFocus: false
    };

    render = () => {
        const {
            name,
            sourceItems,
            required = false,
            maxLength = 256,
            emptyMessage = 'No items found',
            className = 'form-control form-control-search',
            value,
            placeholder = '',
            disabled = false,
            isInvalid = false,
        } = this.props;

        if (!sourceItems) {
            return null;
        }

        const {
            filteredItems = sourceItems,
            expanded = false,
            inputFocus = false
        } = this.state;

        return (
            <div className="form-control-autocomplete">
                <div className={classNames('control-search-wrapper', { 'control-search-focus': inputFocus, 'is-invalid': isInvalid, disabled })}>
                    <div className="form-control-search-btn">
                        <i className="icon icon-search" />
                    </div>
                    <input
                        className={classNames(className, { 'is-invalid': isInvalid })}
                        ref={node => {
                            this._input = node
                        }}
                        type="text"
                        name={name}
                        required={required}
                        pattern={this.props.pattern || constants.nonWhitespace.source}
                        value={value}
                        maxLength={maxLength}
                        onChange={this.handleInputChange}
                        onClick={this.handleInputClick}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        autoComplete="off"
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                    {
                        !!value &&
                        <button className="btn-close" onClick={this.handleClear}>
                            <IconSVG name="close" width={16} height={16} />
                        </button>
                    }
                </div>
                {
                    expanded &&
                    value.trim() !== '' &&
                    (emptyMessage || !!filteredItems.length) &&
                    <ClickOutside onClick={this.handleClickOutside}>
                        <ul className="control-autocomplete-list">
                            {
                                filteredItems.length
                                    ? filteredItems.map(i => <li key={i} onClick={() => this.handleItemSelected(i)}>{i}</li>)
                                    : <li className="text-warm-grey">{emptyMessage}</li>
                            }
                        </ul>
                    </ClickOutside>
                }
            </div>
        );
    };

    handleBlur = () => this.setState({ inputFocus: false });

    handleFocus = () => this.setState({ inputFocus: true });

    handleInputClick = e => {
        if (!this.state.expanded) {
            this.setState({
                filteredItems: this.getFilteredItems(e.target.value),
                expanded: true
            });
        }

        this.setState({ inputFocus: true });
    };

    handleInputChange = e => {
        const value = e.target.value;
        const { onChange } = this.props;

        this.setState({
            filteredItems: this.getFilteredItems(value),
            expanded: true
        });
        onChange(value);
    };

    handleItemSelected = item => {
        const { onChange, onSelected } = this.props;
        this.setState({ expanded: false });
        onChange(item);

        if (typeof onSelected === 'function') {
            onSelected(item);
        }

        this.focus();
    };

    handleClickOutside = () => {
        if (this.state.expanded) {
            this.setState({ expanded: false });
        }
    };

    handleClear = () => {
        this.setState({
            filteredItems: [],
            expanded: false,
            inputFocus: false
        });
        this.props.onChange('');
        this.focus();
    };

    focus = () => {
        if (this._input) {
            this._input.focus();
        }
    };

    getFilteredItems = value => {
        const { sourceItems } = this.props;
        const valueLowerCase = value.trim().toLowerCase();
        return sourceItems.filter(i => i.toLowerCase().startsWith(valueLowerCase));
    }
}
