import { useState, useEffect } from 'react';
import { Transfer } from '../../transfer/Transfer';
import { TabList, TabItem } from '../../../bidding/common/tab-list';
import { TransferOption } from '../../transfer/types';
import { UserConfigColumn } from '../../../../types/user-config/UserConfigColumn';
import { isEqual } from 'lodash';
import { Popup, PopupBody, PopupFooter } from '../../../controls';
import { OnHoverTooltip } from '../../OnHoverTooltip';
import { FilterButton } from '../../../filters';
import IconSVG from '../../../../styles/svg-icons';
import { PageConfigType } from '../../../../types/page-config/PageConfigType';
import { apiUtils, isRequestSuccess, isRequesting } from '../../../../utils';
import { usePageConfig } from '../usePageConfig';
import { bwicColumnsLabels } from '../../../../constants/bwicColumnsLabels';
import { ColumnConfigKey } from '../../../../types/page-config/column-customizer/ColumnConfigKey';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../../../access/ActionBlocker';

interface ColumnCustomizerTab {
    [key: string]: PageConfigType
}

interface CustomizeColumnsDialogProps {
    tabs: ColumnCustomizerTab;
    defaultTab: PageConfigType;
    onClose: () => void;
    columnLabels: { [key in ColumnConfigKey]: string };
}

interface TabState {
    options: TransferOption[];
    selected: TransferOption[];
}

const CustomizeColumnsDialog = ({
    tabs,
    defaultTab,
    onClose,
    columnLabels
}: CustomizeColumnsDialogProps) => {
    const [activeTab, setActiveTab] = useState(defaultTab);
    const { config, defaultConfig, saveColumns, requestState, updateRequestState } = usePageConfig(activeTab);
    const isUpdating = isRequesting(updateRequestState);
    const columns = config?.columns;
    const defaultColumns = defaultConfig?.columns;
    const tabsValues = Object.values(tabs);
    const tabsKeys = Object.keys(tabs);

    const disabled = !isRequestSuccess(requestState) || isUpdating;

    const transferOptionFromColumn = (column: UserConfigColumn) => ({
        key: column.name,
        title: columnLabels[column.name as ColumnConfigKey],
        permanent: column.required,
        fixed: column.fixed,
        order: column.order,
        available: column.available
    } as TransferOption);


    const deriveTabStateFromConfig = (columnConfig?: UserConfigColumn[]) => {
        if (!columnConfig) {
            return {
                options: [],
                selected: []
            }
        }
        const options = columnConfig.map(transferOptionFromColumn);

        const selected = columnConfig
            .filter(({ displayed }) => displayed)
            .map(transferOptionFromColumn);

        return {
            options,
            selected,
        } as TabState;
    };

    const getDefaultTabState = (columnsConfig?: UserConfigColumn[]) => {
        return apiUtils.normalize(
            tabsValues,
            (tab) => tab,
            () => deriveTabStateFromConfig(columnsConfig)
        )
    }

    const [tabState, setTabState] = useState<Record<PageConfigType, TabState>>(getDefaultTabState(columns));
    const [tabDefaultState, setTabDefaultState] = useState<Record<PageConfigType, TabState>>(getDefaultTabState(defaultColumns));

    useEffect(() => {
        setTabState((prevState) => ({
            ...prevState,
            [activeTab]: deriveTabStateFromConfig(columns),
        }));
    }, [columns]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setTabDefaultState((prevState) => ({
            ...prevState,
            [activeTab]: deriveTabStateFromConfig(defaultColumns),
        }));
    }, [defaultColumns]); // eslint-disable-line react-hooks/exhaustive-deps

    const getUserConfig = (options: TransferOption[], selected: TransferOption[]) => {
        const selectedKeys = selected.map(({ key }) => key);

        const selectedColumns = selected.map((option, index) => (
            {
                name: option.key,
                order: index + 1, // order on backend started from 1
                available: option.available,
                required: option.permanent === true,
                displayed: true,
                fixed: option.fixed,
            } as UserConfigColumn
        ))

        return options
            .filter(option => !selectedKeys.includes(option.key))
            .map(option => ({
                name: option.key,
                order: 1,
                required: option.permanent === true,
                displayed: false,
                available: option.available,
                fixed: option.fixed,
            }))
            .concat(selectedColumns);
    };

    const handleSave = async () => {
        const columnsConfigs = getUserConfig(tabState[activeTab].options, tabState[activeTab].selected);
        const newPageConfig = config
            ? { ...config, columns: columnsConfigs }
            : { columns: columnsConfigs, page: activeTab, filters: [] };
        await saveColumns(newPageConfig);
        onClose();
    };

    const handleTransferChange = (value: TransferOption[]) => {
        setTabState((prevState) => ({
            ...prevState,
            [activeTab]: {
                ...prevState[activeTab],
                selected: value,
            }
        }));
    };

    const handleReset = () => setTabState((prevState) => ({
        ...prevState,
        [activeTab]: deriveTabStateFromConfig(defaultColumns),
    }));

    const checkIsConfigDefault = () => {
        const values = tabState[activeTab].selected.map((v, order) => ({ ...v, order }));
        const defaultValues = tabDefaultState[activeTab].selected.map((v, order) => ({ ...v, order }));
        return isEqual(values, defaultValues);
    };

    const handleClose = () => {
        if (!isUpdating) {
            onClose();
        }
    }

    return (
        <Popup
            title="Customize Columns"
            onClose={handleClose}
            modalClass="customize-columns-popup"
            renderInBody
        >
            <PopupBody>
                <p className="customize-columns-info">
                    There are default columns in the "Selected Columns" list, which you can't remove. You may adjust the table view by adding or removing additional columns from the lists. Additionally, you may change the order of the columns by dragging and dropping them in the "Selected Columns" list.
                </p>
                <TabList>
                    {tabsKeys.length > 1 && tabsKeys.map(tk => (
                        <TabItem
                            title={tk}
                            disabled={isUpdating}
                            active={activeTab === tabs[tk]}
                            onClick={() => setActiveTab(tabs[tk])}
                        />
                    ))}
                </TabList>
                <Transfer
                    leftColumnTitle="Available Columns"
                    rightColumnTitle="Selected Columns"
                    options={tabState ? tabState[activeTab].options : []}
                    selected={tabState ? tabState[activeTab].selected : []}
                    onChange={handleTransferChange}
                    onReset={handleReset}
                    disabled={disabled}
                    rightActionDisabled={checkIsConfigDefault()}
                />
            </PopupBody>
            <PopupFooter>
                <button
                    type="button"
                    className="btn btn-ghost"
                    onClick={onClose}
                    disabled={disabled}
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="btn btn-main"
                    onClick={handleSave}
                    disabled={disabled}
                >
                    Save
                </button>
            </PopupFooter>
        </Popup>
    );
};

interface ColumnCustomizerProps {
    disabled: boolean;
    tabs: ColumnCustomizerTab;
    defaultTab?: PageConfigType;
    requiredFeature?: SubscriptionFeature;
    columnLabels?: { [key in ColumnConfigKey]: string  };
}

export const BwicColumnCustomizer = ({ tabs, disabled, defaultTab, requiredFeature, columnLabels = bwicColumnsLabels }: ColumnCustomizerProps) => {
    const [showColumnsDialog, setShowColumnsDialog] = useState(false);
    return (
        <div className="column-customizer-btn">
            <ActionBlocker className="margin-l-0" feature={requiredFeature}>
                {blocked =>
                    <OnHoverTooltip overlay={!blocked && "Customize Columns"}>
                        <FilterButton
                            className="btn-link margin-l-0"
                            onClick={() => setShowColumnsDialog(true)}
                            disabled={disabled || blocked}
                        >
                            <IconSVG name="grid-swap" width={16} height={16} />
                            Columns
                        </FilterButton>
                    </OnHoverTooltip>
                }
            </ActionBlocker>
            {showColumnsDialog && defaultTab && (
                <CustomizeColumnsDialog columnLabels={columnLabels} tabs={tabs} defaultTab={defaultTab} onClose={() => setShowColumnsDialog(false)} />
            )}
        </div>
    );
}
