import * as React from 'react';
import classNames from 'classnames';
import { formatUtils } from '../../../utils';

interface Props {
    currentLevel: number
    updatedLevel: number
    currentLevelVisible?: boolean
    updatedLevelVisible?: boolean
    tooltip?: boolean
}

export function BidLevelDelta({ currentLevel, updatedLevel, currentLevelVisible = false, updatedLevelVisible = true, tooltip = false }: Props) {
    const level = currentLevelVisible ? currentLevel : updatedLevel;
    const renderLevel = () => <>{formatUtils.formatBid(level)}</>
    const renderDelta = () => {
        if (currentLevel > 0 && currentLevel !== updatedLevel) {
            const delta = updatedLevel - currentLevel;
            const sign = delta > 0 ? '+' : '';
            const currentClasses = {
                "bid-level-delta": true,
                "bid-level-delta-positive": delta > 0 && !tooltip,
                "bid-level-delta-negative": delta < 0 && !tooltip,
                "bid-level-delta-tooltip": tooltip
            }
            return <span className={classNames(currentClasses)}><span className="text-ellipsis">{sign}{formatUtils.formatBid(delta)}</span></span>;
        }

        return null;
    }

    return <>{(currentLevelVisible || updatedLevelVisible) && renderLevel()} {renderDelta()}</>
}
