import React from 'react';
import classNames from 'classnames';

export function SecurityListHeader({ children, className = '', onClick = null }) {
    return (
        <div className={classNames('data-list-header flex-row', className)} onClick={onClick}>
            {children}
        </div>
    );
}

