import React from 'react';
import { FilterRadio, FilterRadioProps } from './FilterRadio';

interface FilterBooleanProps extends Omit<FilterRadioProps<boolean | null>, 'options'> {
    optionLabels?: string[];
}

export function FilterBoolean({
    optionLabels = ['Yes', 'No'],
    ...props
}: FilterBooleanProps) {
    // To limit options
    const [positive, negative] = optionLabels;

    return (
        <FilterRadio
            {...props}
            options={[positive, negative].map((text, index) => ({
                text,
                value: !index,
                visible: true,
                disabled: false,
            }))}
        />
    );
}
