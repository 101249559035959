import { useMemo } from 'react';
import { DownloadedPresentationsChart } from './DownloadedPresentationsChart';
import { DashboardNoSelectedFilters } from '../../../dashboard/DashboardNoSelectedFilters';
import { OnHoverTooltip } from '../..';
import IconSVG from '../../../../styles/svg-icons';
import { ChartRequiredFeature } from '../../../access/ChartRequiredFeature';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { ManagerGeneralAnalytics } from '../../../../types/analytics/ManagerGeneralAnalytics';

interface DownloadedPresentationsWidgetProps {
    data: ManagerGeneralAnalytics;
    requiredFeature?: SubscriptionFeature;
}

export const DownloadedPresentationsWidget = ({ data, requiredFeature }: DownloadedPresentationsWidgetProps) => {
    const noData = useMemo(() => data.downloadedPresentations.every(x => !x.numberOfAccess), [data]);

    return (
        <div className="analytics">
            <ChartRequiredFeature
                feature={requiredFeature}
                chartName="Downloaded Presentations"
                blockedClassName="restricted-placeholder-downloaded-presentations"
            >
                <div className="analytics-heading analytics-heading-downloaded-presentations">
                    <h2>Downloaded Presentations</h2>
                    <OnHoverTooltip
                        overlay={
                            <>
                                <p>Downloaded from: Issuance Monitor, Manager Profile, Arranger Pipeline.</p>
                                <p>Shows information for last 10 weeks only.</p>
                            </>
                        }
                        overlayClassName="downloaded-presentations-tooltip"
                        placement="bottomRight"
                    >
                        <IconSVG name="info" width={16} height={16} />
                    </OnHoverTooltip>
                    <span>Weekly Data</span>
                </div>
                {noData
                    ? <DashboardNoSelectedFilters text="No data available" />
                    : <DownloadedPresentationsChart data={data.downloadedPresentations} />
                }
            </ChartRequiredFeature>
        </div>
    );
};
