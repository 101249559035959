import React from 'react';

interface AccordionItemBodyProps {
    children: React.ReactNode;
    visible: boolean;
}

export function AccordionItemBody({ children, visible }: AccordionItemBodyProps) {

    if (!visible) {
        return null;
    }

    return (
        <div className="accordion-cnt">
            {children}
        </div>
    )
}

AccordionItemBody.defaultProps = {
    visible: false
}
