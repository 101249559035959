import React from 'react';
import classNames from 'classnames';
import IconSVG from '../../../styles/svg-icons';

interface AccordionItemHeaderProps {
    children: React.ReactNode;
    onItemHeaderClick: (e: React.FormEvent<HTMLElement>) => void;
    isShowCollapsedIcon?: boolean;
    isSelected: boolean;
    showAdditionalIcon?: boolean;
}

export function AccordionItemHeader({ children, onItemHeaderClick, isShowCollapsedIcon, isSelected, showAdditionalIcon = true }: AccordionItemHeaderProps) {

    const handleClick = (e: React.FormEvent<HTMLElement>) => {
        onItemHeaderClick && onItemHeaderClick(e);
    };

    const renderIconKptPlatform = () => (
        <div className="kpt-platform-wrapper">
            <div className="kpt-platform">
                <IconSVG name="on-platform" width={16} height={16} />
            </div>
        </div>
    );

    return (
        <div className="collapsable-item-header" onClick={handleClick}>
            {isShowCollapsedIcon &&
            <>
                <IconSVG name="icon-expand" className={classNames({ 'collapse': isSelected })} width={16} height={16} />
                {showAdditionalIcon && renderIconKptPlatform()}
            </>
            }
            {children}
        </div>

    )
}

AccordionItemHeader.defaultProps = {
    isShowCollapsedIcon: true,
    isSelected: false,
};
