import { SyntheticEvent, useState } from 'react';
import { SearchInput } from '../common';
import { DropDownList, DropDownListItem } from './DropDownList';


interface SelectSearchFieldProps {
    label: string;
    disabled?: boolean;
    markRequired: boolean;
    options: DropDownListItem[];
    placeholder?: string;
    className?: string;
    onChange: (value: number | string | null) => void;
}

export function SelectSearch({ disabled = false, options, placeholder, onChange, className }: SelectSearchFieldProps) {
    const [searchTerm, setSearchTerm] = useState('');
    const filteredOptions = options
        .filter(({ freezed, text }) => freezed || text.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));


    const handleSearchTermChange = (value: string) => {
        onChange(null);
        setSearchTerm(value);
    };

    const handleClearSearch = () => setSearchTerm('');

    return (
        <>
            <DropDownList
                className={className}
                disabled={disabled}
                items={filteredOptions}
                placeholder={placeholder}
                onChange={(item) => {
                    onChange(item.value)
                    handleClearSearch();
                }}
                render={
                    (items, freezedItems) => (
                        <>
                            <SearchInput
                                placeholder="Start typing..."
                                value={searchTerm}
                                onClick={(e: SyntheticEvent) => e.stopPropagation()}
                                onChange={handleSearchTermChange}
                                onClear={handleClearSearch}
                            />
                            {items.length || freezedItems.length ? (
                                <>
                                    {items}
                                    {freezedItems}
                                </>
                            ) : (
                                <div className="empty-placeholder" onClick={e => e.stopPropagation()}>
                                    No search results found.
                                </div>
                            )}
                        </>
                    )
                }
            />
        </>
    );
}

