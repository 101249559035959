import React from 'react';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import { InfiniteScrollLoader } from '../../common';

export function SecurityListContent({
    children,
    className = '',
    infiniteScrollEnabled = false,
    onClick = null,
    onNextPageRequest,
    isNextPageRequesting,
    sticky = false
}) {

    if (infiniteScrollEnabled && onNextPageRequest != null) {
        return (
            <div
                className={classNames('data-list-content', className)}
                onClick={onClick}
            >
                <InfiniteScroll
                    className={classNames('infinite-scroll-container', {'infinite-scroll-container-sticky': sticky})}
                    pageStart={0}
                    initialLoad={false}
                    loadMore={onNextPageRequest}
                    hasMore={true}
                    loader={<InfiniteScrollLoader key={0} isNextPageRequesting={isNextPageRequesting !== null ? isNextPageRequesting : true}/>}
                    useWindow={false}
                >
                    {children}
                </InfiniteScroll>
            </div>
        );
    }

    return (
        <div
            className={classNames('data-list-content data-list-content-scrollable', className)}
            onClick={onClick}
        >
            {children}
        </div>
    );
}










