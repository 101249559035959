import React from 'react';
import cn from 'classnames';
import IconSVG from '../../styles/svg-icons';
import { DocumentBodyPortal } from '../common/DocumentBodyPortal';

interface Props {
    title?: React.ReactNode;
    renderTitle?: React.ReactNode;
    subTitle?: React.ReactNode;
    onClose?: (e: MouseEvent) => void;
    modalClass?: string;
    disabled?: boolean;
    renderInBody?: boolean;
    children: React.ReactNode;
}

const Popup: React.FC<Props> = (
    { children, title, renderTitle, subTitle, onClose, modalClass, disabled, renderInBody }
) => {
    const content = (
        <div className={cn('modal', modalClass)} onClick={e => e.stopPropagation()}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header" onClick={e => e.stopPropagation()}>
                    {title && <h1>{title}</h1>}
                    {renderTitle && <>{renderTitle}</>}
                    {subTitle && <h4>{subTitle}</h4>}
                    <button
                        className="btn-close"
                        disabled={disabled}
                        onClick={(e: any) => disabled ? undefined : onClose && onClose(e)}
                    >
                        <IconSVG name="close" width={16} height={16} />
                    </button>
                </div>
                {children}
            </div>
        </div>
    );

    if (renderInBody) {
        return <DocumentBodyPortal>{content}</DocumentBodyPortal>
    }

    return content;
}

Popup.defaultProps = {
    title: '',
    renderTitle: null,
    subTitle: null,
    onClose: () => { },
    modalClass: '',
    disabled: false,
    renderInBody: false,
}

export default Popup;
