/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { Component } from 'react';

interface MarkdownViewerState {
    value: string;
}

class Iterator<T> {
    private _items: ArrayLike<T>;
    private _index: number;

    constructor(items: ArrayLike<T>) {
        this._items = items;
        this._index = -1;
    }

    get = (offset: number = 0) => this._items[this._index + offset];

    next = () => this.skip(1);

    skip(offset: number) {
        this._index += offset;
        return this._index < this._items.length;
    }

    isBeforeStarting = () => this._index === -1;
    hasMoreItems = () => this._index < this._items.length && this._items.length > 0;
    hasItemAtIndex(index: number) {
        const calculatedIndex = this._index + index;
        return 0 < calculatedIndex && calculatedIndex < this._items.length;
    }
}

class GrammarNode {
    render(): React.ReactNode {
        return <></>;
    }
}

class LiteralGrammarNode extends GrammarNode {
    isLiteral = true;
    value: string;

    constructor(value: string) {
        super();
        this.value = value;
    }

    render() {
        return this.value;
    }
}

class ParrentGrammarNode extends GrammarNode {
    protected _children: GrammarNode[];
    protected _tagName: string;

    constructor(children: GrammarNode[], tagName: string) {
        super();
        this._children = children;
        this._tagName = tagName;
    }

    render() {
        let childElements = this._children.map((item, index) => <GreammarRenderer key={index} node={item} />);
        return React.createElement(this._tagName, null, childElements);
    }
}

class ListGrammarNode extends ParrentGrammarNode {
    terminateTree: boolean;

    constructor(children: GrammarNode[], ordered: boolean, terminateTree: boolean) {
        super(children, ordered ? 'ol' : 'ul');
        this.terminateTree = terminateTree;
    }
}

interface GreammarRendererState {
    node: GrammarNode;
}

class GreammarRenderer extends Component<GreammarRendererState> {
    render = () => this.props.node.render();
}

class Grammar {
    parse(
        iterator: Iterator<string>,
        previousGrammarNode: GrammarNode | null | undefined,
        supportedGrammars: Grammar[],
    ): GrammarNode | null {
        return null;
    }

    public static parseOne(
        iterator: Iterator<string>,
        previousGrammarNode: GrammarNode | null | undefined,
        supportedGrammars: Grammar[],
    ): GrammarNode | null {
        for (var grammar of supportedGrammars) {
            let node = grammar.parse(iterator, previousGrammarNode, supportedGrammars);

            if (node != null) {
                return node;
            }
        }

        return null;
    }

    protected parseNewLine(iterator: Iterator<string>, offset: number = 0): { success: boolean; length: number } {
        if (
            (iterator.get(offset) === '\n' && iterator.get(offset + 1) === '\r') ||
            (iterator.get(offset) === '\r' && iterator.get(offset + 1) === '\n')
        ) {
            return { success: true, length: 2 };
        }

        if (iterator.isBeforeStarting() || iterator.get(offset) === '\n' || iterator.get(offset) === '\r') {
            return { success: true, length: 1 };
        }

        return { success: false, length: 0 };
    }
}

class CommonGrammar extends Grammar {
    parse(
        iterator: Iterator<string>,
        previousGrammarNode: GrammarNode | null | undefined,
        supportedGrammars: Grammar[],
    ): GrammarNode | null {
        if (!this.parseStart(iterator)) {
            return null;
        }

        const children = [] as GrammarNode[];

        while (iterator.hasMoreItems() && !this.parseEnd(iterator)) {
            let previousGrammarNode = children[children.length - 1];
            let child = Grammar.parseOne(iterator, previousGrammarNode, supportedGrammars);

            if (child) {
                children[children.length] = child;
            }
        }

        return this.create(children);
    }

    protected parseStart(iterator: Iterator<string>) {
        return false;
    }

    protected parseEnd(iterator: Iterator<string>) {
        return false;
    }

    protected create(children: GrammarNode[]): GrammarNode | null {
        return null;
    }
}

enum ListStyle {
    number,
    bullet,
}

class List extends Grammar {
    parse(
        iterator: Iterator<string>,
        previousGrammarNode: GrammarNode | null | undefined,
        supportedGrammars: Grammar[],
    ): GrammarNode | null {
        let nextListItem = this.recogniseListItem(iterator);

        if (!nextListItem) {
            return null;
        }

        const style = nextListItem.style;
        const level = nextListItem.level;

        const listItems = [] as ParrentGrammarNode[];
        let terminateTree = false;

        do {
            iterator.skip(nextListItem.contentOffset);
            nextListItem = null;

            const children = [] as GrammarNode[];

            terminateTree = this.parseEnd(iterator);

            while (iterator.hasMoreItems() && !terminateTree) {
                nextListItem = this.recogniseListItem(iterator);

                if (nextListItem && nextListItem.level <= level) {
                    break;
                }

                const previousChild = children[children.length - 1];
                const child = Grammar.parseOne(iterator, previousChild, supportedGrammars);

                if (child) {
                    children[children.length] = child;
                }

                terminateTree =
                    (child as ListGrammarNode)?.terminateTree || !iterator.hasMoreItems() || this.parseEnd(iterator);
            }

            listItems[listItems.length] = new ParrentGrammarNode(children, 'li');
        } while (nextListItem && nextListItem.style === style && nextListItem.level === level);

        return new ListGrammarNode(listItems, style === ListStyle.number, terminateTree);
    }

    protected recogniseListItem(
        iterator: Iterator<string>,
    ): { level: number; style: ListStyle | null; contentOffset: number } | null {
        let index = 0;

        let isLineStart =
            (iterator.get(0) === '\r' && iterator.get(1) === '\n') ||
            (iterator.get(0) === '\n' && iterator.get(1) === '\r');

        if (isLineStart) {
            index += 2;
        }

        isLineStart = iterator.get() === '\r' || iterator.get() === '\n';

        if (isLineStart || iterator.isBeforeStarting()) {
            index++;
        } else {
            return null;
        }

        let subsequantSpaces = 0;
        let level = 0;
        let keepLevelTravers = true;

        do {
            let currentSymbol = iterator.get(index);

            if (currentSymbol === ' ') {
                subsequantSpaces++;
                index++;
            } else if (currentSymbol === '\t') {
                level += Math.floor(subsequantSpaces / 2) + 1;
                subsequantSpaces = 0;
                index++;
            } else {
                level += Math.floor(subsequantSpaces / 2);
                subsequantSpaces = 0;
                keepLevelTravers = false;
            }
        } while (keepLevelTravers);

        let result = null;

        if (iterator.get(index) === '*' && iterator.get(index + 1) === ' ') {
            result = {
                level: level,
                style: ListStyle.bullet,
                contentOffset: index + 1,
            };
        } else {
            let startsWithNumbers = false;

            while (/\d/.test(iterator.get(index))) {
                startsWithNumbers = true;
                index++;
            }

            if (startsWithNumbers && iterator.get(index) === '.' && iterator.get(index + 1) === ' ') {
                result = {
                    level: level,
                    style: ListStyle.number,
                    contentOffset: index + 1,
                };
            }
        }

        return result;
    }

    protected parseEnd(iterator: Iterator<string>) {
        const firstNewLine = this.parseNewLine(iterator, 0);

        if (!firstNewLine.success) {
            return false;
        }

        let offset = firstNewLine.length;

        while (iterator.get(offset) === ' ' || iterator.get(offset) === '\t') {
            offset++;
        }

        const secondNewLine = this.parseNewLine(iterator, offset);

        if (secondNewLine.success) {
            iterator.skip(offset - 1);
        }

        return secondNewLine.success;
    }

    protected create(children: GrammarNode[]): GrammarNode | null {
        return null;
    }
}

class BoldText extends CommonGrammar {
    protected parseStart(iterator: Iterator<string>) {
        const isStart = iterator.get(0) === '*' && iterator.get(1) === '*';

        if (isStart) {
            iterator.skip(2);
        }

        return isStart;
    }

    protected parseEnd(iterator: Iterator<string>) {
        const isEnd = iterator.get(0) === '*' && iterator.get(1) === '*';

        if (isEnd) {
            iterator.skip(2);
        }

        return isEnd;
    }

    protected create = (children: GrammarNode[]): GrammarNode | null => new ParrentGrammarNode(children, 'b');
}

class StrikethroughText extends CommonGrammar {
    protected parseStart(iterator: Iterator<string>) {
        const isStart = iterator.get(0) === '~' && iterator.get(1) === '~';

        if (isStart) {
            iterator.skip(2);
        }

        return isStart;
    }

    protected parseEnd(iterator: Iterator<string>) {
        const isEnd = iterator.get(0) === '~' && iterator.get(1) === '~';

        if (isEnd) {
            iterator.skip(2);
        }

        return isEnd;
    }

    protected create = (children: GrammarNode[]): GrammarNode | null => new ParrentGrammarNode(children, 's');
}

class AsteriskItalicText extends CommonGrammar {
    protected parseStart(iterator: Iterator<string>) {
        const isStart = iterator.get(0) === '*' && iterator.get(1) !== '*';

        if (isStart) {
            iterator.next();
        }

        return isStart;
    }

    protected parseEnd(iterator: Iterator<string>) {
        const isEnd = iterator.get(0) === '*' && (iterator.get(1) !== '*' || iterator.get(2) === '*');

        if (isEnd) {
            iterator.next();
        }

        return isEnd;
    }

    protected create = (children: GrammarNode[]): GrammarNode | null => new ParrentGrammarNode(children, 'em');
}

class UnderlineItalicText extends CommonGrammar {
    protected parseStart(iterator: Iterator<string>) {
        const isStart = iterator.get(0) === '_' && iterator.get(1) !== '_';

        if (isStart) {
            iterator.next();
        }

        return isStart;
    }

    protected parseEnd(iterator: Iterator<string>) {
        const isEnd = iterator.get(0) === '_' && (iterator.get(1) !== '_' || iterator.get(2) === '_');

        if (isEnd) {
            iterator.next();
        }

        return isEnd;
    }

    protected create = (children: GrammarNode[]): GrammarNode | null => new ParrentGrammarNode(children, 'em');
}

class Heading extends CommonGrammar {
    private _level: number;

    constructor(level: number) {
        super();
        this._level = level;
    }

    protected parseStart(iterator: Iterator<string>) {
        const newLine = this.parseNewLine(iterator);
        if (!newLine.success) {
            return false;
        }

        let offset = newLine.length;
        while (/\s/.test(iterator.get(offset))) {
            offset++;
        }

        let hashSymbolsCount = 0;
        while (iterator.get(offset) === '#') {
            offset++;
            hashSymbolsCount++;
        }

        let isStart = hashSymbolsCount === this._level;

        if (isStart) {
            iterator.skip(offset);
        }

        return isStart;
    }

    protected parseEnd(iterator: Iterator<string>) {
        return this.parseNewLine(iterator).success;
    }

    protected create = (children: GrammarNode[]): GrammarNode | null =>
        new ParrentGrammarNode(children, `h${this._level}`);
}

class Paragraph extends CommonGrammar {
    protected parseStart(iterator: Iterator<string>) {
        const newLine = this.parseNewLine(iterator);

        if (newLine.success) {
            iterator.skip(newLine.length);
        }

        return newLine.success;
    }

    protected parseEnd(iterator: Iterator<string>) {
        return this.parseNewLine(iterator).success;
    }

    protected create = (children: GrammarNode[]): GrammarNode | null => new ParrentGrammarNode(children, 'p');
}

class Literal extends Grammar {
    parse(iterator: Iterator<string>, previousGrammarNode: GrammarNode | null | undefined) {
        const previousLiteralNode = previousGrammarNode as LiteralGrammarNode;
        let literalNode = null;

        if (previousLiteralNode?.isLiteral) {
            previousLiteralNode.value += iterator.get();
        } else {
            literalNode = new LiteralGrammarNode(iterator.get());
        }

        iterator.next();

        return literalNode;
    }
}

export default class MarkdownViewer extends Component<MarkdownViewerState> {
    render() {
        const iterator = new Iterator<string>(this.props.value ?? '');

        const supportedGrammars = [
            new List(),
            new BoldText(),
            new StrikethroughText(),
            new AsteriskItalicText(),
            new UnderlineItalicText(),
            new Heading(1),
            new Heading(2),
            new Heading(3),
            new Heading(4),
            new Heading(5),
            new Heading(6),
            new Paragraph(),
            new Literal(),
        ];

        const nodes = [] as GrammarNode[];

        while (iterator.hasMoreItems()) {
            let previousNode = nodes[nodes.length - 1];
            let grammar = Grammar.parseOne(iterator, previousNode, supportedGrammars);

            if (grammar != null) {
                nodes[nodes.length] = grammar;
            }
        }

        return (
            <div className="markdown-viewer">
                {nodes.map((item, index) => (
                    <GreammarRenderer key={index} node={item} />
                ))}
            </div>
        );
    }
}
