import classNames from 'classnames';
import React from 'react';
import IconSVG from '../../../styles/svg-icons';

interface TransferItemProps {
    title: string;
    key: string;
    mode?: 'source' | 'destination';
    draggable?: boolean;
    permanent?: boolean;
    disabled?: boolean;
    fixed?: boolean;
    onClick: (key: string) => void;
    [key: string]: any;
}

export const TransferItem = ({
    title,
    id,
    mode = 'source',
    draggable = true,
    permanent = false,
    disabled = false,
    fixed = false,
    onClick,
    className,
    ...otherProps
}: TransferItemProps) => {

    return (
        <div
            className={classNames('customize-columns-item data-list-row flex-row', { fixed }, className)}
            draggable={draggable && !disabled}
            {...otherProps}
        >
            <div className="customize-columns-left">
                { draggable &&
                    <IconSVG name="drag-dots" width="16" height="16" />
                }
                <span className="text-sm">{title}</span>
            </div>
            {fixed && <span className="text-sm fixed-label">fixed</span>}
            { !permanent &&
                <button
                    disabled={disabled}
                    className={
                        classNames(
                            "btn btn-ghost btn-sm btn-arrow",
                            { 'btn-arrow-mirror': mode === 'destination', disabled }
                        )
                    }
                    onClick={() => onClick(id)}
                >
                    <IconSVG
                        name="icon-expand"
                        width="16"
                        height="16"
                    />
                </button>
            }
        </div>
    );
};
