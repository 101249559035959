import React from 'react';
import classNames from 'classnames';

export function CollapsibleListItem({ children, expanded }) {
    return (
        <div className={classNames('component-collapsible-list-item', { expanded })}>
            {children}
        </div>
    );
}

