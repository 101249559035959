import React, { useState } from 'react';
import { mainConfig, Plot } from '../plotlyConfig';
import arraystat from 'arraystat';
import { chartUtils, jsonUtils, numericUtils, stringUtils } from "../../../../utils";
import { constants as appConstants } from '../../../../constants';
import { Checkbox } from '../../../controls/Checkbox';
import { InfoTooltip } from "../../InfoTooltip";

const LegendsIds = {
    pxTalk: 1,
    color:  2,
    noColor: 3,
    kTalk: 4,
};

const LegendButtons = [
    { name: 'Px Talk', id: LegendsIds.pxTalk },
    { name: 'K-Talk', id: LegendsIds.kTalk },
    { name: 'Color', id: LegendsIds.color },
    { name: 'DNT/No Color', id: LegendsIds.noColor }
];

export function Chart({
    historyList = [],
    range,
    constants = {},
    divId,
    onHover,
    showHoverInfo,
    defaultXrange,
    evalPriceTalkVisible = false,
}) {
    const { getYRange, getXRange } = chartUtils;
    const {
        fillBoxColor,
        boxMarkerColor,
        scatterMarkerColor,
        kTalkMarkerColor,
        colorLineColor,
        layoutXAxisLineColor,
        margin,
        tickColor,
        tickFont,
        pxTalkLimit,
        minXRange,
        data0LineWidth,
        markerSize,
        hoverlabel,
        xAxisMinDatesCount,
        minBottomMargin,
        nTicks
    } = constants;
    const [selectedLegendButton, setSelectedLegendButton] = useState([LegendsIds.pxTalk, LegendsIds.color, LegendsIds.kTalk]);
    const {
        x,
        y,
        lineX,
        lineY,
        markersX,
        markersY,
        sortedByDate,
        datesList,
        kTalkX,
        kTalkY,
    } = chartUtils.getChartData(historyList, appConstants.dashboardDateFormat, pxTalkLimit);
    const allDates = Object.keys(datesList);
    const chartMargin = { ...margin };
    const legendButtons = LegendButtons.filter(({ id }) => evalPriceTalkVisible ? true : id !== LegendsIds.kTalk);

    if (allDates.length <= xAxisMinDatesCount) {
        chartMargin.b = minBottomMargin;
    }

    const data0 = {
        x: allDates,
        y: allDates.map(() => -1),
        hoverinfo: 'none',
        name: 'Default',
        mode: 'lines',
        line: {
            color: layoutXAxisLineColor,
            width: data0LineWidth
        }
    };

    const yMaxValue = Math.max(...lineY.concat(markersY).concat(y.map(yItem => yItem.value)));

    const getMinYPointValue = (itemValue) => {
        let minYPointValue = 0.01;
        if (yMaxValue > 5 && yMaxValue <= 20) {
            minYPointValue = 0.5
        } else if (yMaxValue > 20 && yMaxValue <= 50) {
            minYPointValue = 1
        } else if (yMaxValue > 50 && yMaxValue <= 200) {
            minYPointValue = 3
        } else if (yMaxValue > 200 && yMaxValue <= 600) {
            minYPointValue = 6
        } else if (yMaxValue > 600) {
            minYPointValue = 9
        }
        if (itemValue > minYPointValue || itemValue < 0) {
            return itemValue;
        }
        return minYPointValue
    };

    const getPxTalkPointInfo = (index) => {
        const yValue = y[index];
        const currentDate = x[index];
        const dateValue = sortedByDate[currentDate];

        if (!dateValue) {
            return [];
        }

        const { pxTalk, colorValue } = dateValue;
        const pxTalkStat = arraystat(pxTalk.map((px) => px.normalizedLevel));

        if (pxTalkStat && !!yValue.value) {
            return [
                { name: 'Company', value: stringUtils.shorten(yValue.companyName, 35) },
                { name: 'Date', value: currentDate },
                { name: 'Color', value: colorValue || '-' },
                { name: 'Px Talk', value: yValue.value },
                { name: 'Q1', value: numericUtils.round(pxTalkStat.q3) || '' },
                { name: 'Med', value: numericUtils.round(pxTalkStat.median) || '' },
                { name: 'Q3', value: numericUtils.round(pxTalkStat.q1) || '' }
            ];
        }
    };

    const getChartBoxText = () =>
        showHoverInfo
            ? y.map((item, index) => getPxTalkPointInfo(index).map(textItem => `${textItem.name}: ${textItem.value}`).join('<br />'))
            : y.map((item, index) => JSON.stringify({ index, type: LegendsIds.pxTalk }));

    const chartBox = {
        y: y.map((item) => getMinYPointValue(item.value)),
        x,
        type: 'box',
        name: 'Px Talk',
        marker: {
            color: boxMarkerColor
        },
        fillcolor: fillBoxColor,
        hoverinfo: showHoverInfo ? 'text' : 'none',
        text: getChartBoxText(),
        boxpoints: 'all',
        showscale: false,
        jitter: 0.3,
    };

    const getColorPointInfo = (index) => {
        const lineYValue = lineY[index];
        return [{ name: 'Date', value: lineX[index] }, { name: 'Color', value: lineYValue }];
    };

    const getChartColorText = () =>
        showHoverInfo
            ? lineY.map((item, index) => getColorPointInfo(index).map(textItem => `${textItem.name}: ${textItem.value}`).join('<br />'))
            : lineY.map((item, index) => JSON.stringify({ index, type: LegendsIds.color }));

    const chartColor = {
        x: lineX,
        y: lineY.map(value => getMinYPointValue(value)),
        text: getChartColorText(),
        hoverinfo: showHoverInfo ? 'text' : 'none',
        name: 'Color',
        mode: 'lines+markers',
        line: { color: colorLineColor },
        marker: { size: markerSize },
    };

    const getNoColorPointInfo = (index) => {
        const markersYValue = markersY[index];
        return [{ name: 'Date', value: markersX[index] }, { name: 'Median Px Talk', value: markersYValue }];
    };

    const getChartDntText = () =>
        showHoverInfo
            ? markersY.map((item, index) => getNoColorPointInfo(index).map(textItem => `${textItem.name}: ${textItem.value}`).join('<br />'))
            : markersY.map((item, index) => JSON.stringify({ index, type: LegendsIds.noColor }));

    const chartDnt = {
        x: markersX,
        y: markersY.map(value => getMinYPointValue(value)),
        name: 'DNT/No Color',
        type: 'scatter',
        mode: 'markers',
        hoverinfo: showHoverInfo ? 'text' : 'none',
        text: getChartDntText(),
        marker: { color: scatterMarkerColor, size: markerSize },
    };

    const getKTalkPointInfo = (index) => {
        return [{ name: 'Date', value: kTalkX[index] }, { name: 'K-Talk', value: kTalkY[index] }];
    };

    const getKTalkText = () =>
        showHoverInfo
            ? kTalkY.map((_, index) => getKTalkPointInfo(index).map(textItem => `${textItem.name}: ${textItem.value}`).join('<br />'))
            : kTalkY.map((_, index) => JSON.stringify({ index, type: LegendsIds.kTalk }));

    const chartKTalk = {
        x: kTalkX,
        y: kTalkY,
        name: 'K-Talk',
        type: 'scatter',
        mode: 'markers',
        hoverinfo: showHoverInfo ? 'text' : 'none',
        text: getKTalkText(),
        marker: { color: kTalkMarkerColor },
    };

    const onClickLegendButton = (status, position) => {
        if (selectedLegendButton.length > 1 && selectedLegendButton.indexOf(position) > -1) {
            selectedLegendButton.splice(selectedLegendButton.indexOf(position), 1)
        } else if (selectedLegendButton.indexOf(position) === -1) {
            selectedLegendButton.push(position)
        }
        setSelectedLegendButton([...selectedLegendButton])
    };

    const getLegendButtonIcon = (btnId) => {
        switch (btnId) {
            case LegendsIds.pxTalk:
                return (<span className="chart-item chart-item-px" />);
            case LegendsIds.color:
                return (<span className="chart-item chart-item-color" />);
            case LegendsIds.noColor:
                return (<span className="chart-item chart-item-dnt" />);
            case LegendsIds.kTalk:
                return (<span className="chart-item chart-item-ktalk" />);
            default:
                return null;
        }
    };

    const renderLegendItem = (item) => {
        if (item.id === LegendsIds.noColor) {
            return (
                <Checkbox
                    key={`control_key_${item.id}`}
                    checked={selectedLegendButton.indexOf(item.id) > -1}
                    onChange={(e) => onClickLegendButton(e.target.checked, item.id)}
                >
                    <>
                        {getLegendButtonIcon(item.id)}
                        <span className="control-label-text">{item.name}</span>
                    </>
                </Checkbox>
            );
        }

        if (item.id === LegendsIds.kTalk) {
            return (
                <span className="chart-box eval-box animated" key={`control_key_${item.id}`}>
                    {getLegendButtonIcon(item.id)}
                    <span className="control-label-text">{item.name}</span>
                </span>
            );
        }

        return (
            <span className="chart-box" key={`control_key_${item.id}`}>
                {getLegendButtonIcon(item.id)}
                <span className="control-label-text">{item.name}</span>
            </span>
        );
    };

    const renderLegendButtons = () => {
        return (
            <div className="chart-legend flex-row">
                {legendButtons.map(renderLegendItem)}
                <InfoTooltip text="Average Price Talk values for BWICs in which the security did not trade and/or no color was distributed." />
            </div>
        )
    };

    const getCharts = () => {
        const allCharts = {
            [LegendsIds.pxTalk]: chartBox,
            [LegendsIds.color]: chartColor,
            [LegendsIds.noColor]: chartDnt,
            [LegendsIds.kTalk]: chartKTalk,
        };

        const charts = legendButtons.map((btn) => {
            if (selectedLegendButton.indexOf(btn.id) > -1) {
                return allCharts[btn.id];
            }

            return { data0 };
        });

        charts.unshift(data0);

        return charts;
    };

    const charts = getCharts();

    const getOnHoverPointContent = ({ index, type }) => {
        switch (type) {
            case LegendsIds.pxTalk:
                return getPxTalkPointInfo(index);
            case LegendsIds.color:
                return getColorPointInfo(index);
            case LegendsIds.noColor:
                return getNoColorPointInfo(index);
            case LegendsIds.kTalk:
                return getKTalkPointInfo(index);
            default:
                return null;
        }
    };

    const handleOnHover = (data = {}) => {
        chartUtils.setCursor('pointer')(data);
        const { points } = data;
        let onHoverData = null;
        if (points && points.length === 1 && points[0].text && jsonUtils.tryParse(points[0].text)) {
            onHoverData = getOnHoverPointContent({ ...jsonUtils.tryParse(points[0].text) });
        } else {
            onHoverData = null;
        }
        if (onHover && typeof onHover === 'function') {
            onHover(onHoverData)
        }
    };

    const handleUnHover = (e) => {
        chartUtils.setCursor('crosshair')(e);
        if (onHover && typeof onHover === 'function') {
            onHover(null)
        }
    };

    const renderChart = () => (
        <Plot
            onInitialized={chartUtils.setCursor('crosshair')}
            onHover={handleOnHover}
            onUnhover={handleUnHover}
            divId={divId}
            data={charts}
            debug={true}
            layout={{
                xaxis: {
                    showgrid: false,
                    range: defaultXrange ? undefined: getXRange(allDates, minXRange, range),
                    rangemode: 'tozero',
                    tickcolor: tickColor,
                    tickfont: tickFont,
                    linecolor: layoutXAxisLineColor,
                    linewidth: 0.1,
                    tickangle: 0,
                    nticks: nTicks,
                    anchor: 'y',
                },
                yaxis: {
                    zeroline: false,
                    rangemode: 'tozero',
                    fixedrange: true,
                    tickcolor: tickColor,
                    tickfont: tickFont,
                    range: getYRange(charts)
                },
                hoverlabel,
                boxmode: 'group',
                showlegend: false,
                legend: { orientation: 'h' },
                hovermode: 'closest',
                margin: chartMargin
            }}
            config={mainConfig}
        />);

    return (
        <>
            {renderChart()}
            {renderLegendButtons()}
        </>
    )
}

Chart.defaultProps = {
    divId: 'px-color-dnt-chart',
    showHoverInfo: false,
};
