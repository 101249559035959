import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { Preloader } from '../';
import IconSVG from '../../../styles/svg-icons'
import { bidOnBehalfService, bwicService } from '../../../services';
import { inventoryService } from '../../../services/inventory.service';
import { contactsService, documentsService } from '../../../services';
import { errorActions } from '../../../actions';

type TemplateType =
    'SecuritiesXlsxTemplate' |
    'SecuritiesInventoryXlsxTemplate' |
    'ContactsXlsxTemplate' |
    'ManageSecuritiesXlsxTemplate' |
    'UploadBobTemplate';

interface Props {
    disabled?: boolean;
    templateType: TemplateType
    args?: string;
}

export const DownloadTemplateButton: FC<Props> = ({ disabled, templateType, args }) => {
    const [requesting, getTemplate] = useDownloadTemplate(templateType, args);

    return (
        <Preloader small={true} fullScreen={false} inProgress={requesting} text="Downloading…">
            <button
                disabled={disabled}
                className="btn-link download-template"
                type="button"
                onClick={getTemplate}
            >
                <IconSVG name="downloadTemplate" width={16} height={16} />Download Template
            </button>
        </Preloader>
    )
};

DownloadTemplateButton.defaultProps = {
    disabled: false
};

const getTemplateService = (templateType: TemplateType) => {
    switch (templateType) {
        case 'SecuritiesXlsxTemplate':
            return bwicService.getSecuritiesXlsxTemplate;
        case 'SecuritiesInventoryXlsxTemplate':
            return inventoryService.getSecuritiesXlsxTemplate;
        case 'ContactsXlsxTemplate':
            return contactsService.getXlsxTemplate;
        case 'ManageSecuritiesXlsxTemplate':
            return documentsService.downloadSecurities;
        case 'UploadBobTemplate':
            return bidOnBehalfService.downloadTemplate;
        default:
            return null;
    }
};


const useDownloadTemplate = (templateType: TemplateType, args?: string): [boolean, () => void] => {
    const dispatch = useDispatch();
    const [requesting, setRequesting] = useState(false);

    const getTemplate = async () => {
        const service = getTemplateService(templateType);
        if (!service) {
            return;
        }
        try {
            setRequesting(true);
            const file: { name: string, blob: Blob } = await service(args);
            saveAs(file.blob, file.name)
        } catch (e) {
            dispatch(errorActions.unexpectedError(e))
        } finally {
            setRequesting(false)
        }
    };

    return [requesting, getTemplate]
};
