import * as React from 'react';
import classNames from 'classnames';
import IconSVG from '../../styles/svg-icons';

interface BottomPanelProps {
    children: React.ReactNode;
    className?: string;
}

export function BottomPanel({ children, className }: BottomPanelProps) {
    return (
        <div className={classNames('banner fixed-banner', className)}>
            {children}
        </div>
    );
}

interface CloseButtonProps {
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

export function BottomPanelCloseButton({ onClick }: CloseButtonProps) {
    return (
        <button className="btn-link" onClick={onClick}>
            <IconSVG name="close" width={16} height={16} />
            Close
        </button>
    );
}

