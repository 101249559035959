import * as React from 'react';
import moment from 'moment';
import { BidConflict } from '../../../types/bidding/BidConflict';
import { BwicPositionBase } from '../../../types/bwic/BwicPositionBase';
import { moneyUtils, formatUtils } from '../../../utils';
import { constants } from '../../../constants';
import { StatusMessageSectionType } from "../../../types/state/NotificationState";
import { StatusMessageSection } from "../../status-message/StatusMessageSection";

interface Props {
    conflicts: BidConflict[]
    positions: BwicPositionBase[]
}

export function BidConflictsWarning({ conflicts, positions }: Props) {
    const renderConflictWarningRow = (conflict: BidConflict, index: number) => {
        const position = positions.find(p => p.id === conflict.positionId);

        if (position) {
            const submissionDate = conflict.modifiedDate || conflict.created;
            return (
                <div key={index} className="flex-row">
                    <div>{position.ticker}</div>
                    <div>{moneyUtils.money(conflict.size)} @ {conflict.pass ? 'PASS' : formatUtils.formatBid(conflict.level)} at {moment.utc(submissionDate).local().format(constants.timeFormat)}</div>
                </div>
            );
        }

        return null;
    };


    if (conflicts && conflicts.length) {
        const conflictItems = conflicts
            .map((c, i) => renderConflictWarningRow(c, i))
            .filter(i => i != null);

        if (conflictItems.length) {
            return (
                <StatusMessageSection type={StatusMessageSectionType.Info}>
                    <p>The following bids were submitted earlier:</p>
                    <div className="conflict-bids-list">
                        {conflictItems}
                    </div>
                    <p>Please confirm your bids.</p>
                </StatusMessageSection>
            );
        }
    }

    return null;
}
