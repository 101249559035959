import classNames from 'classnames';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { BaseFieldProps, InputField } from './InputField';

interface Props extends BaseFieldProps {
    includeThousandsSeparator?: boolean;
}

export const NumberField = ({ includeThousandsSeparator = true, ...props }: Props) => {
    return (
        <InputField {...props} renderElement={(formik, field, meta, helpers) => (
            <div className="data-item-col">
                <MaskedInput
                    {...field}
                    className={classNames('form-control')}
                    mask={createNumberMask({
                        prefix: '',
                        allowDecimal: true,
                        decimalLimit: 2,
                        includeThousandsSeparator,
                    })}
                    guide={false}
                    value={field.value || ''}
                    onChange={e => {
                        const value = e.target.value.replace(/,/g, '');
                        const valueNumber = Number(value);

                        helpers.setValue(valueNumber);
                    }}
                    placeholder={props.placeholder}
                />
                {meta.touched && meta.error && <div className="form-error">{meta.error}</div>}
            </div>
        )} />
    );
}
