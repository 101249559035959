import React, { Component } from 'react';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { dateTimeUtils } from '../../utils';
import 'rc-time-picker/assets/index.css';
import { constants } from '../../constants';


export default class TimePickerControl extends Component {
    render = () => {
        const { id = 'timePickerControl', emptyEnabled, time, className = '', disabledHours = [], disabledMinutes = [], placeholder } = this.props;

        return (
            <div className={'control-time-picker ' + className}>
                <TimePicker
                    id={id}
                    allowEmpty={emptyEnabled}
                    popupClassName={className}
                    placeholder={placeholder}
                    showHour={true}
                    showMinute={true}
                    showSecond={false}
                    minuteStep={15}
                    use12Hours
                    format="h:mm a"
                    focusOnOpen={true}
                    onChange={this.handleTimeChange}
                    onClose={this.handleClose}
                    value={time ? moment.utc(time) : null}
                    disabledHours={() => disabledHours}
                    disabledMinutes={() => disabledMinutes}
            />
            </div>
        );
    };

    handleClose = () => {
        const { emptyEnabled, time, defaultTime, onTimeChange } = this.props

        if (!time && !emptyEnabled) {
            onTimeChange(defaultTime || dateTimeUtils.timestampToUtcString('00:00 AM'));
        }
    }

    handleTimeChange = time => {
        const { onTimeChange, disabledHours } = this.props;

        if(time && Array.isArray(disabledHours) && disabledHours.indexOf(time.get('hours') + 1) > -1){
            time.set('minute', '00');
        }

        if (!time) {
            onTimeChange('');
        } else if (time.isUTC()) {
            onTimeChange(time.utc().format());
        } else {
            const timestamp = time.format(constants.timeFormat);
            onTimeChange(dateTimeUtils.timestampToUtcString(timestamp));
        }
    }
}
