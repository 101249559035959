import { keyBy, mapValues } from 'lodash';
import { Link } from 'react-router-dom';
import { constants, routes, SORTING_TYPE } from '../../../../constants';
import { TransactionAccessTypes } from '../../../../types/amr-pipeline/enums/TransactionAccessType';
import { ColumnBuilder } from '../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../../bidding/common/table/types/ColumnDefinition';
import { ProportionBar } from '../../../amrPipeline/ProportionBar';
import { TransactionViewHistoryClientsActivity } from '../../../../types/amr-pipeline/models/TransactionViewHistoryClientsActivity';
import { getDocumentCountByViewHistory } from '../../../../utils/amr-pipeline.utils';
import { DealUpdatesPopover } from '../../../amrPipeline/aggregated/DealUpdatesPopover';
import IconSVG from '../../../../styles/svg-icons';
import { OnHoverTooltip } from '../..';
import { TransactionStatus } from '../../../../types/amr-pipeline/enums/TransactionStatus';
import { ActionBlocker } from '../../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { ValueBlocker } from '../../../access/ValueBlocker';

const getTransactionViewHistoryColumnsDefinitions = (subscriptionFeature?: SubscriptionFeature): IColumnDefinition<TransactionViewHistoryClientsActivity>[] => ([
    {
        columnKey: 'lastUpdated',
        renderColumnHeaderContent: () => 'Last Updated',
        renderColumnContent: (transactionViewHistory) => {
            const {
                referenceName,
                dealReferenceName,
                dealLegalName,
                lastUpdated,
                type,
                status,
                version,
                dealUpdates,
            } = transactionViewHistory;

            return (
                <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorHistoricalData}>
                    {blocked =>
                        <DealUpdatesPopover
                            disabled={blocked}
                            referenceName={referenceName}
                            dealReferenceName={dealReferenceName}
                            dealLegalName={dealLegalName}
                            lastUpdated={lastUpdated}
                            type={type}
                            status={status}
                            version={version}
                            dealUpdates={dealUpdates}
                        />
                    }
                </ActionBlocker>
            );
        },
        sortingField: 'lastUpdated',
        className: 'data-list-cell-lg',
        sortingType: SORTING_TYPE.date,
    },
    {
        columnKey: 'dealLegalName',
        renderColumnHeaderContent: () => 'Deal Name',
        renderColumnContent: (transactionViewHistory) => {
            return (
                <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorMarketingHistory} overrideRequiredFeatures={transactionViewHistory.status === TransactionStatus.Active}>
                    { blocked =>
                        blocked
                            ? <span>{transactionViewHistory.dealLegalName}</span>
                            : <Link
                                to={routes.transactionDetailUrl(
                                    transactionViewHistory.referenceName,
                                    transactionViewHistory.dealReferenceName,
                                    'analytics',
                                )}
                            >
                                {transactionViewHistory.dealLegalName}
                            </Link>
                    }
                </ActionBlocker>
            );},
        sortingField: 'dealLegalName',
        className: 'data-list-cell-xl-xxl',
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: 'daysInTheMarket',
        renderColumnHeaderContent: () => (
            <>
                <span>Days on the Market</span>
                <OnHoverTooltip overlay="Count of days from transaction marketing start day to pricing day." placement="bottomRight">
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>
            </>
        ),
        renderColumnContent: transactionViewHistory => {
            return transactionViewHistory.daysInTheMarket || constants.emptyPlaceholder
        },
        sortingField: 'daysInTheMarket',
        sortingType: SORTING_TYPE.number,
        className: 'data-list-cell-lg',
    },
    {
        columnKey: 'dealViews',
        renderColumnHeaderContent: () => (
            <>
                <span>Total Views</span>
                <OnHoverTooltip overlay="Count of views from transaction marketing start day to pricing day." placement="bottomRight">
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>
            </>
        ),
        renderColumnContent: (transactionViewHistory) => (
            <ValueBlocker feature={subscriptionFeature}>
                {transactionViewHistory.dealViews || constants.emptyPlaceholder}
            </ValueBlocker>
        ),
        sortingField: 'dealViews',
        sortingType: SORTING_TYPE.number,
        className: 'data-list-cell-md',
    },
    {
        columnKey: 'tabsViews',
        renderColumnHeaderContent: () => 'Tabs Viewed',
        renderColumnContent: (transactionViewHistory) => {
            const viewedTabs = mapValues(keyBy(transactionViewHistory.tabsViewed, 'accessType'), 'numberOfAccess');

            return (
                <ValueBlocker feature={subscriptionFeature}>
                    <ProportionBar
                        title="view(s)"
                        items={TransactionAccessTypes.map(t => ({
                            title: t.text,
                            value: viewedTabs[t.value],
                            color: t.color
                        }))}
                    />
                </ValueBlocker>
            );
        },
        className: 'data-list-cell-lg-02',
    },
    {
        columnKey: 'docDownloaded',
        renderColumnHeaderContent: () => 'Docs Downloaded',
        renderColumnContent: (transactionViewHistory) => {
            const data = getDocumentCountByViewHistory(transactionViewHistory);

            return (
                <ValueBlocker feature={subscriptionFeature}>
                    <ProportionBar
                        items={[
                            {
                                title: 'Deal Documents',
                                value: data.dealDocumentsViews,
                                color: '#FFB400',
                            },
                            {
                                title: 'Disclosure',
                                value: data.disclosureFileViews,
                                color: '#8D40BE',
                            },
                            {
                                title: 'Manager Presentation',
                                value: data.managerPresentationViews,
                                color: '#74B58F',
                            },
                            {
                                title: 'Target Portfolio',
                                value: data.targetPortfolioViews,
                                color: '#487EB2',
                            },
                            {
                                title: 'Intex File',
                                value: data.intexFileViews,
                                color: '#5BBED8',
                            },
                        ]}
                    />
                </ValueBlocker>
            );
        },
        className: 'data-list-cell-lg-02',
        sortingType: SORTING_TYPE.string,
    },
]);

export const createTransactionViewHistoryColumns = (subscriptionFeature?: SubscriptionFeature) =>
    getTransactionViewHistoryColumnsDefinitions(subscriptionFeature).map(c => new ColumnBuilder(c));

