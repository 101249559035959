import { TransactionViewHistoryTable } from './TransactionViewHistoryTable';
import { ManagerGeneralAnalytics } from '../../../../types/analytics/ManagerGeneralAnalytics';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';

interface TransactionViewHistoryWidgetProps {
    data: ManagerGeneralAnalytics;
    requiredFeature?: SubscriptionFeature;
}

export function TransactionViewHistoryWidget({ data, requiredFeature }: TransactionViewHistoryWidgetProps) {
    return (
        <div className="analytics">
            <div className="analytics-heading">
                <h2>Transaction View History</h2>
            </div>
            <div className="">
                <TransactionViewHistoryTable data={data.transactionViewHistory} requiredFeature={requiredFeature} />
            </div>
        </div>
    );
}
