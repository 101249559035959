import React, { useEffect, useState } from "react";
import moment from "moment";
import classnames from "classnames";
import MaskedInput from "react-text-mask";
import { TimeInput } from './TimeInput';
import { dateTimeFilterConstants } from "./constants";
import { DateTimeValue } from '../../../../types/filters/DateTimeValue';
import { filterUtils } from '../../../../utils/filtering/filter.utils';
import { TimeOption } from '../../../../types/filters/TimeOption';

interface DateTimeInputProps {
    label: string;
    value?: DateTimeValue;
    minYear: number;
    errorMessage?: string;
    onChange: (value: DateTimeValue) => void;
    onError: () => void;
}

const DateFormat = "MM/DD/YYYY";
const DateMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

export function DateTimeInput({
    label,
    value,
    minYear,
    errorMessage,
    onChange,
    onError,
}: DateTimeInputProps) {
    const [timeOption, setTimeOption] = useState(value?.timeOption || TimeOption.AnyTime);
    const [inputValue, setInputValue] = useState<string>();

    const [error, setError] = useState<string>();

    useEffect(() => {
        setTimeOption(value?.timeOption || TimeOption.AnyTime);
        setInputValue(value?.date ? moment(value.date).format(DateFormat) : '');
    }, [value]);

    useEffect(() => {
        const dateMoment = moment(inputValue, DateFormat, true);
        const error = inputValue && filterUtils.validateDate(minYear, dateMoment.toDate());

        if (errorMessage) {
            onError();
        }

        setError(errorMessage || error);
    }, [errorMessage, onError, inputValue, minYear])


    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value);

        const dateMoment = moment(value, DateFormat, true);

        if (dateMoment.isValid()) {
            const newValue = {
                date: dateMoment.toDate(),
                timeOption,
            };

            onChange(newValue);
        }

        const error = filterUtils.validateDate(minYear, dateMoment.toDate());
        setError(error);

        if (error) {
            onError();
        }
    };

    const handleTimeChange = (value: DateTimeValue) => {
        setTimeOption(value.timeOption);
        setError(undefined);
        onChange(value);
    };

    return (
        <div className="custom-view-box flex-none">
            <div className="form-item">
                <label htmlFor="masked-input" className="form-label">
                    {label}
                </label>
                <MaskedInput
                    id="masked-input"
                    name="date"
                    mask={DateMask}
                    guide={true}
                    type="text"
                    value={inputValue}
                    className={classnames({
                        'form-control': true,
                        'is-invalid': error,
                    })}
                    placeholder={DateFormat}
                    onChange={handleDateChange}
                />
                {error && <div className="form-error">{error}</div>}
            </div>
            <TimeInput
                label={dateTimeFilterConstants.radioInputLabel}
                date={value?.date}
                timeOption={value?.timeOption}
                onChange={handleTimeChange}
            />
        </div>
    );
}
