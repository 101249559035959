import React, { Component } from 'react';
const imageTypes = '.cdi,.csv,.doc,.docx,.pdf,.ppt,.pptx,.xls,.xlsx'

export default class FileIcon extends Component {
    render = () => {
        const { filename, isNew = false } = this.props;

        let fileIcon;
        if (isNew) {
            fileIcon = 'add-file';
        } else {
            const supportedFileTypes = imageTypes.split(',');
            const extension = filename.substr(filename.lastIndexOf('.'));
            const supported =
                extension &&
                supportedFileTypes.find(e =>
                    e.localeCompare(extension, undefined, { sensitivity: 'accent' }) === 0);

            fileIcon = supported ? supported.replace('.', '') : 'unknown-file';
        }

        return <i className={'icon icon-xl icon-' + fileIcon} />
    }
}
