import { useState } from "react";
import cn from "classnames";
import { ClickOutside } from "../common";
import IconSVG from "../../styles/svg-icons";
import { constants } from '../../constants';
import { isNil } from 'lodash';

interface Props {
    years: number[];
    selectedYear?: number;
    onChange: (year?: number) => void;
    currentYearPrefix?: string;
    prefix?: string;
    disabled?: boolean
    showAllOption?: boolean;
}

export function YearSelector({
    years,
    selectedYear,
    onChange,
    currentYearPrefix,
    prefix,
    disabled = false,
    showAllOption = false,
}: Props) {
    const [displaying, setDisplaying] = useState(false);
    const currentYear = new Date().getFullYear();

    const handleClick = (year?: number) => {
        onChange(year);
        setDisplaying(false);
    };

    const returnPrefix = (year: number) =>
        currentYearPrefix && year === currentYear
            ? currentYearPrefix + " "
            : "";

    const renderButtonText = () => {
        if (!selectedYear) {
            return (<>{prefix} All</>);
        }

        return (
            <>
                {prefix}
                {selectedYear ? returnPrefix(selectedYear) : 'All'}
                {selectedYear}
            </>
        );
    }
    const renderButton = () => (
        <button
            type="button"
            className="btn-link"
            disabled={disabled}
            onClick={() => setDisplaying((prevState) => !prevState)}
        >
            {renderButtonText()}
            <IconSVG name="icon-expand" width={16} height={16} />
        </button>
    );

    return (
        <div className={cn("year-selector", { "show": displaying, disabled })}>
            {displaying && !disabled ? (
                <ClickOutside onClick={() => setDisplaying(false)}>
                    {renderButton()}
                    <div className="year-dropdown">
                        {showAllOption && (
                            <span
                                key={constants.allListItem}
                                onClick={() => handleClick(undefined)}
                                className={cn("year-item", {
                                    selected: isNil(selectedYear),
                                })}
                            >
                                All
                            </span>
                        )}
                        {years.map((year) => (
                            <span
                                key={year}
                                onClick={() => handleClick(year)}
                                className={cn("year-item", {
                                    selected: selectedYear === year,
                                })}
                            >
                                {returnPrefix(year)}
                                {year}
                            </span>
                        ))}
                    </div>
                </ClickOutside>
            ) : (
                renderButton()
            )}
        </div>
    );
}
