import React, { useState } from "react";
import { kebabCase, noop } from 'lodash';
import { FilterOption } from "../../../types/filters/FilterOption";
import { ClickOutside, SearchInput } from "../../common/";
import { Checkbox } from "../../controls/Checkbox";
import classNames from 'classnames';
import { EmptyPlaceholder } from "../../common";
import { FilterDropDown } from "./FilterDropDown";
import { DropDownButton } from '../../controls/DropDownButton';
import { FilterButton } from './FilterButton';
import { BlockedFeatureContext } from "../../access/BlockedFeatureContext";

interface Props<FilterType> {
    defaultExpanded?: boolean;
    options: FilterOption<FilterType>[];
    title: string | React.ReactNode;
    withSearch?: boolean;
    formatTitle?: boolean;
    multiply?: boolean;
    className?: string;
    closeOnSelect?: boolean;
    isApplied?: boolean;
    isChanged?: boolean;
    withExpandToggle?: boolean;
    disabled?: boolean;
    buttonView?: 'filter' | 'chart';
    onChangeItemSelection: (item: FilterOption<FilterType>, checked: boolean) => void;
    onClearAll?: () => void;
    onSelectAll?: (searchTerm: string) => void;
}

export function FilterSelect<FilterType>({
    isApplied = false,
    defaultExpanded = false,
    options,
    title,
    withSearch,
    formatTitle = true,
    closeOnSelect = false,
    className,
    withExpandToggle = true,
    disabled = false,
    buttonView = 'filter',
    onChangeItemSelection,
    onSelectAll,
    onClearAll,
}: Props<FilterType>) {
    const [expanded, setExpanded] = useState(defaultExpanded);
    const [searchTerm, setSearchTerm] = useState("");
    const selectedOptions = options.filter((o) => o.selected);
    const selected = !!selectedOptions.length;

    const isFeatureBlocked = React.useContext(BlockedFeatureContext);

    function handleSearchTermChange(value: string) {
        setSearchTerm(value);
    }

    function handleClear() {
        setSearchTerm('')
    }

    function handleSelectAll() {
        onSelectAll && onSelectAll(searchTerm);
    }

    function handleSelectItem(item: FilterOption<FilterType>, checked: boolean) {
        onChangeItemSelection(item, checked);

        if (closeOnSelect && !item.selected) {
            setExpanded(false);
        }
    }

    function renderTitle() {
        const numberOfSelectedOptions = selectedOptions.length;
        if (formatTitle) {
            if (numberOfSelectedOptions === 1)
                return <>
                    {title}:&nbsp;<span className="selected-data">{selectedOptions[0].text}</span>
                </>;
            else if (
                numberOfSelectedOptions > 1 &&
                numberOfSelectedOptions < options.length
            )
                return <>
                    {title}: {`${numberOfSelectedOptions} selected`}
                </>;
            else return `${title}: All`;
        }
        return title;
    }

    function getFilteredItems() {
        const searchTermLowerCase = searchTerm.trim().toLowerCase();
        return options.filter((o) =>
            o.text.toLowerCase().includes(searchTermLowerCase)
        );
    }

    function renderLookup() {
        const filteredOptions = searchTerm.length
            ? getFilteredItems()
            : options;

        if (!filteredOptions.length) return <EmptyPlaceholder textView={true} />;

        return (
            <ul className="control-filter-select-list">
                {filteredOptions.map((o) => (
                    <li key={String(o.value)}>
                        <Checkbox
                            label={o.text}
                            checked={o.selected}
                            onChange={() => handleSelectItem(o, !o.selected)}
                        />
                    </li>
                ))}
            </ul>
        );
    }

    function renderClearSelectTrigger() {
        const filteredOptions = searchTerm.length
            ? getFilteredItems()
            : options;

        if (!filteredOptions.length || (!onClearAll && !onSelectAll)) {
            return null;
        }

        return (
            <button className="btn-link" onClick={selectedOptions.length ? onClearAll : handleSelectAll}>
                {selectedOptions.length ? "Reset to default" : "Select All"}
            </button>
        );
    }

    function renderSelectedItems() {
        if (!selectedOptions.length) {
            return null;
        }

        return (
            <>
                <ul className="control-filter-select-list-selected">
                    {selectedOptions.map((i) => (
                        <li key={i.text}>
                            <Checkbox
                                label={i.text}
                                checked={i.selected}
                                onChange={() => onChangeItemSelection(i, !i.selected)}
                            />
                        </li>
                    ))}
                </ul>
                <hr />
            </>
        );
    }

    function renderDropDown() {
        return (
            <FilterDropDown
                className={classNames(
                    "control-filter-content-select",
                    { "filter-select-and-search": withSearch }
                )}
                expanded={expanded}
                value={options}
            >
                {withSearch ? (
                    <>
                        <SearchInput
                            placeholder="Start typing..."
                            value={searchTerm}
                            onChange={handleSearchTermChange}
                            onClear={handleClear}
                        />
                        {renderClearSelectTrigger()}
                        {renderSelectedItems()}
                    </>
                ) : (
                    renderClearSelectTrigger()
                )}

                {renderLookup()}
            </FilterDropDown>
        );
    }

    function renderFilterView() {
        return (
            <>
                <FilterButton
                    disabled={disabled || isFeatureBlocked}
                    title={renderTitle()}
                    expanded={expanded}
                    withExpandToogle={withExpandToggle}
                    applied={isApplied}
                    selected={selected}
                    onExpand={setExpanded}
                    onClearAll={onClearAll || noop}
                />
                {!disabled && !isFeatureBlocked && expanded && renderDropDown()}
            </>
        );
    }

    function renderChartView() {
        return (
            <DropDownButton
                expanded={expanded}
                focused={false}
                changed={selected}
                title={renderTitle()}
                className="custom-drop-down-ghost"
                onClick={setExpanded}
                disabled={disabled || isFeatureBlocked}
            >
                {renderDropDown()}
            </DropDownButton>
        );
    }

    return (
        <ClickOutside
            className={classNames("control-filter-select", `control-filter-${kebabCase(className)}`)}
            onClick={() => setExpanded(false)}
        >
            {buttonView === 'filter' && renderFilterView()}
            {buttonView === 'chart' && renderChartView()}
        </ClickOutside>
    );
}
