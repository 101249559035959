import classNames from 'classnames';
import React from 'react';

export interface TransferHeaderProps {
    title: string;
    actionTitle?: string;
    actionDisabled?: boolean;
    onActionClick?: () => void;
}

export const TransferHeader: React.FC<TransferHeaderProps> = ({
    title,
    actionTitle,
    actionDisabled,
    onActionClick = () => {},
}) => {
    const handleActionClick = () => {
        if (actionDisabled !== true) {
            onActionClick();
        }
    };

    return (
        <div className="customize-columns-head">
            <span>{title}</span>
            { !!actionTitle &&
                <button
                    disabled={actionDisabled}
                    onClick={handleActionClick}
                    className={classNames("btn-link", { disabled: actionDisabled })}
                >
                    {actionTitle}
                </button>
            }
        </div>
    );
};
