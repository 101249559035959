import React, { useState } from 'react';
import classNames from 'classnames';
import { ClickOutside } from '../common/ClickOutside';
import { arrayUtils } from '../../utils/array.utils';
import { DropDownButton } from './DropDownButton';

interface Props {
    className?: string;
    items: DropDownListItem[];
    showLoadingProgress?: boolean;
    disabled?: boolean;
    defaultSortingEnabled?: boolean;
    placeholder?: string;
    renderSelectedItemCallback?: (item: DropDownListItem) => React.ReactNode;
    renderItemContentCallback?: (item: DropDownListItem, hovered: boolean) => React.ReactNode;
    render?: (items: JSX.Element[], freezedItems: JSX.Element[]) => React.ReactNode;
    onChange?: (item: DropDownListItem, e: React.MouseEvent) => void;
    prefix?: string;
}

export interface DropDownListItem {
    value: string | number;
    text: string;
    selected?: boolean;
    groupKey?: number;
    payload?: any;
    disabled?: boolean;
    freezed?: boolean;
}

export function DropDownList({
    className = '',
    showLoadingProgress = false,
    disabled = false,
    items,
    placeholder,
    defaultSortingEnabled = true,
    render,
    renderSelectedItemCallback,
    renderItemContentCallback,
    onChange,
    prefix,
}: Props) {
    const [hoveredItem, setHoveredItem] = useState<string | number | undefined>();
    const [expanded, setExpanded] = useState(false);
    const [focused, setFocused] = useState(false);
    const selected = items.find(i => i.selected ?? false);
    const defaultItem = items.find(i => !i.value);

    let sortedItems = defaultSortingEnabled
        ? [...items]
            .filter(i => i.value)
            .sort((a, b) => (b.groupKey || 0) - (a.groupKey || 0) || a.text.localeCompare(b.text))
        : items;

    if (defaultItem) {
        sortedItems = [defaultItem, ...sortedItems];
    }

    const excludedFreezed = sortedItems.filter(item => !expanded || !item.freezed);

    const groups: Map<number | undefined, DropDownListItem[]> = arrayUtils.groupBy(excludedFreezed, (item: DropDownListItem) => item.groupKey);

    const closeDropDown = () => {
        setFocused(false);
        setExpanded(false);
    }

    const renderGroup = (groupKey: number | undefined, items: DropDownListItem[]) => {
        const key = groupKey || 0;
        return (
            <div className="section" key={key.toString()}>
                {items.map(i => renderItem(key, i))}
            </div>
        );
    }

    const renderItem = (parentKey: number, item: DropDownListItem) => {
        const handleItemClick = (e: React.MouseEvent) => {
            e.stopPropagation();
            if (!item.disabled) {
                closeDropDown();
                onChange && onChange(item, e);
            }
        }

        return (
            <div
                key={`${parentKey}-${item.value}`}
                onClick={handleItemClick}
                className={classNames('drop-down-list-item', { selected: item.selected, disabled: item.disabled, freezed: item.freezed })}
                onMouseEnter={() => setHoveredItem(item.value)}
                onMouseLeave={() => setHoveredItem(undefined)}
            >
                <div className="item-wrapper">{renderItemContentCallback ? renderItemContentCallback(item, hoveredItem === item.value) : item.text}</div>
            </div>
        );
    }

    const renderedItems = [...groups.entries()]
        .map(([key, items]) => renderGroup(key, items));

    const renderedFreezedItems = sortedItems
        .filter(el => el.freezed)
        .map((item, i) => renderItem(i, item));

    const renderContent = () => {
        return (
            <DropDownButton
                expanded={expanded}
                focused={focused}
                title={renderSelectedItemCallback != null && selected != null
                    ? renderSelectedItemCallback(selected)
                    : <span className={classNames({ "btn-placeholder": !selected })}>{selected ? `${prefix ? prefix : ''}${selected.text}` : placeholder}</span>
                }
                className={className}
                disabled={disabled}
                loading={showLoadingProgress}
                onClick={() => {
                    setExpanded(prev => !prev);
                    setFocused(prev => !prev);
                }}
            >
                <div className="drop-down-list">
                    {render?.(renderedItems, renderedFreezedItems) ?? renderedItems}
                </div>
            </DropDownButton>
        );
    }

    if (focused) {
        return (
            <ClickOutside className="outside-wrapper" onClick={closeDropDown}>
                {renderContent()}
            </ClickOutside>
        );
    }

    return renderContent();
}