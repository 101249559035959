import * as React from 'react';
import { RightSidePanel } from './RightSidePanel';
import { TabList, TabItem } from '../bidding/common/tab-list';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../access/ActionBlocker';
import { HorizontalSlider } from '../common/HorizontalSlider';

interface TabPanelItem {
    active: boolean;
    title: string;
    renderContent: () => React.ReactNode;
    onActivate: () => void;
    onDeactivate: () => void;
    feature?: SubscriptionFeature;
}

interface Props {
    title: React.ReactNode,
    className?: string,
    items: TabPanelItem[],
    onClose?: () => void
}

const tabsPerView = 5;

export function RightSideTabPanel({ title, className, items, onClose }: Props) {
    const activeItem = items.find(i => i.active);
    const activeItemIndex = items.findIndex(i => i.active);

    if (!activeItem) {
        return null;
    }

    const handleTabClick = (item: TabPanelItem) => {
        activeItem.onDeactivate();
        item.onActivate();
    }

    const handleClose = () => {
        activeItem.onDeactivate();

        if (onClose) {
            onClose();
        }
    }

    const tabs = items.map(i => {
        const renderTabItem = (disabled: boolean) => (
            <TabItem
                key={i.title}
                active={i.active}
                title={i.title}
                onClick={() => handleTabClick(i)}
                disabled={disabled}
            />
        )
        if (i.feature) {
            return (
                <ActionBlocker key={i.title} feature={i.feature}>
                    {(disabled: boolean) => renderTabItem(disabled)}
                </ActionBlocker>
            );
        }
        return renderTabItem(false);
    });

    return (
        <RightSidePanel title={title} className={className} onClose={handleClose}>
            <TabList>
                <HorizontalSlider activeItemIndex={activeItemIndex} itemsPerView={tabsPerView} disabled={items.length < tabsPerView}>
                    {tabs}
                </HorizontalSlider>
            </TabList>
            {activeItem.renderContent()}
        </RightSidePanel>
    );
}
